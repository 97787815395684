// @flow

import { responsive } from "@utils/";

const scrolledClass = "is-scrolled";

class ScrollState {
    $el: HTMLElement;
    $resetTriggers: NodeList<HTMLElement>;
    $siteNotification: HTMLElement;
    scrolledUp: boolean;
    prevScrollPos: number;
    scrollDistance: number;
    bottomScrollThreshold: number;

    constructor(el: HTMLElement) {
        this.$el = el;
        this.$resetTriggers = this.$el.querySelectorAll(
            "[data-scroll-state-reset]"
        );
        this.$siteNotification = window.document.querySelector(
            ".site-notification"
        );
        this.prevScrollPos = window.scrollY;
        this.bottomScrollThreshold = 150;
        this.scrollDistance = 0;

        // startup
        this.init();

        // events
        this.attachEvents();
    }

    init() {}

    attachEvents() {
        // scroll event
        window.addEventListener("scroll", this.handleScroll.bind(this));

        // resize event
        window.addEventListener("resize", this.handleScroll.bind(this));

        // scroll state reset click binding
        this.$resetTriggers.forEach(($el: HTMLElement) => {
            $el.addEventListener("click", this.setScrolledUp.bind(this));
        });
    }

    handleScroll() {
        this.scrolledUp = window.scrollY <= this.prevScrollPos;
        const isEndOfPage =
            // $FlowFixMe
            document.body.scrollHeight - window.scrollY <=
            this.bottomScrollThreshold + window.innerHeight;

        if (this.scrolledUp) {
            // scrolled up
            this.scrollDistance = 0;

            // only scroll up on scroll if mobile nav/sizes
            if (responsive.isBreakpoint("sm") && !isEndOfPage) {
                this.setScrolledUp();
            }

            if (window.scrollY === 0) {
                this.setScrolledUp();
            }
        } else {
            // scrolled down
            let siteNofiticationHeight = 0;
            if (this.$siteNotification) {
                siteNofiticationHeight = this.$siteNotification.clientHeight;
            }

            if (
                responsive.isBreakpoint("xs") ||
                responsive.isBreakpoint("sm")
            ) {
                // for moble sizes, only set scrolled down after scrolling past the element
                if (
                    window.scrollY >
                    this.$el.clientHeight + siteNofiticationHeight
                ) {
                    this.setScrolledDown();
                }
            } else {
                if (
                    window.scrollY >
                        this.$el.clientHeight + siteNofiticationHeight ||
                    true
                ) {
                    this.scrollDistance += window.scrollY - this.prevScrollPos;

                    if (this.scrollDistance >= 300) {
                        this.scrollDistance = 0;
                        this.setScrolledDown();
                    }
                }
            }
        }

        this.prevScrollPos = window.scrollY;
    }

    setScrolledUp() {
        this.$el.classList.remove(scrolledClass);
        this.scrollDistance = 0;
    }

    setScrolledDown() {
        this.$el.classList.add(scrolledClass);
    }
}

export default ScrollState;
