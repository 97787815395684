// @flow

import React, { Component } from "react";
import { CSSTransition } from "react-transition-group";
import { get } from "../api/helpers";
import createUniqueID from "@utils/createUniqueID";
import { isMobileBreakpoint } from "@utils/responsive";

type PropsType = {
    tenantApi: string,
    hostUrl: string,
    tenantGuid: any,
    market: Object,
    activeMarket: number,
    setActiveMarket: Function,
    setDragBarFn: Function,
};

type StateType = {
    loaded: boolean,
    dataModel: Object,
};

class PrecinctMapDetailCard extends Component<PropsType, StateType> {
    uniqueId = createUniqueID();
    scrollContainer = React.createRef<HTMLDivElement>();
    contentRef = React.createRef<HTMLDivElement>();
    isFullHeight = false;
    state = {
        loaded: false,
        dataModel: {},
    };

    //
    // Lifecycle Methods
    //

    componentDidMount() {
        if (this.props.tenantGuid && this.props.tenantGuid !== "market") {
            this.fetchTenantDetails(this.props.tenantGuid);
        }
    }

    componentDidUpdate(prevProps: any, prevState: any) {
        if (
            this.props.tenantGuid &&
            this.props.tenantGuid !== "market" &&
            this.props.tenantGuid !== prevProps.tenantGuid
        ) {
            this.fetchTenantDetails(this.props.tenantGuid);
        } else if (
            (this.props.market !== prevProps.market &&
                this.props.tenantGuid === "market") ||
            (this.props.tenantGuid === "market" &&
                this.props.market &&
                this.props.tenantGuid !== prevProps.tenantGuid)
        ) {
            this.fetchMarketDetails();
        }
    }

    handleNextRelatedClick() {
        const relateTilesScroller = document.querySelector(
            ".map-detail-card .related-content__tiles--related > .col-xs-12"
        );
        const tile = relateTilesScroller.querySelector(".tile");

        if (relateTilesScroller && tile) {
            const tileWidth = tile.clientWidth;
            const offset = relateTilesScroller.scrollLeft === 0 ? 30 : 0;

            relateTilesScroller.scrollTo({
                top: 0,
                left: relateTilesScroller.scrollLeft + tileWidth + offset,
                behavior: "smooth",
            });
        }
    }

    handleNextWhatsOnClick() {
        const relateTilesScroller = document.querySelector(
            ".map-detail-card .related-content__tiles--whatson > .col-xs-12"
        );
        const tile = relateTilesScroller.querySelector(".tile");

        if (relateTilesScroller && tile) {
            const tileWidth = tile.clientWidth;
            const offset = relateTilesScroller.scrollLeft === 0 ? 30 : 0;

            relateTilesScroller.scrollTo({
                top: 0,
                left: relateTilesScroller.scrollLeft + tileWidth + offset,
                behavior: "smooth",
            });
        }
    }

    fetchTenantDetails(tenantGuid: string): any {
        this.setState({
            loaded: false,
            dataModel: {},
        });

        get(`${this.props.tenantApi}?nodeGuid=${tenantGuid}`)
            .then((json: Object) => {
                this.setState(
                    {
                        loaded: true,
                        dataModel: json,
                    },
                    () => {
                        if (window.lazyLoad) {
                            window.lazyLoad.update();
                            window.lazyLoad.loadAll();
                        }
                    }
                );
            })
            .catch((e: any) => {
                console.error(e);
            });
    }

    fetchMarketDetails(): any {
        this.setState({
            loaded: true,
            dataModel: this.props.market,
        });
    }

    render(): any {
        return (
            <CSSTransition
                key={this.uniqueId}
                in={this.props.tenantGuid ? true : false}
                timeout={1000}
                classNames={isMobileBreakpoint() ? "slideInUp" : "slideInLeft"}
                mountOnEnter
                unmountOnExit
            >
                <div
                    className={`precinct-map__detail-view map-detail-card`}
                    ref={this.contentRef}
                    onScroll={(e: any) => {
                        const el = this.contentRef.current;
                        if (el && el.scrollTop > 20 && !this.isFullHeight) {
                            this.isFullHeight = true;
                            this.props.setDragBarFn("full");
                        } else if (
                            el &&
                            el.scrollTop < 10 &&
                            this.isFullHeight
                        ) {
                            this.isFullHeight = false;
                            this.props.setDragBarFn("");
                        }
                    }}
                >
                    {this.props.tenantGuid && !this.state.loaded && (
                        <div className="map-detail-card__loading"></div>
                    )}
                    {this.state.loaded && (
                        <div className="map-detail-card__inner">
                            <div className="map-detail-card__image-wrapper">
                                <img
                                    src={`${this.props.hostUrl +
                                        this.state.dataModel.image}?width=1024`}
                                    alt={`${this.state.dataModel.title}`}
                                    className="map-detail-card__image"
                                />
                                {this.state.dataModel.logo && (
                                    <img
                                        src={`${this.props.hostUrl +
                                            this.state.dataModel
                                                .logo}?width=200`}
                                        alt={`Logo ${this.state.dataModel.title}`}
                                        className="map-detail-card__logo"
                                    />
                                )}
                            </div>
                            <div className="map-detail-card__header">
                                <div className="map-detail-card__type">
                                    {this.state.dataModel.type.name}
                                </div>

                                <h1 className="map-detail-card__title">
                                    {this.state.dataModel.title}
                                </h1>
                                <div className="map-detail-card__short-description">
                                    {this.state.dataModel.shortDescription}
                                </div>
                                <div className="row">
                                    <div className="col-xs-12 col-sm-6">
                                        <a
                                            href={
                                                typeof this.state.dataModel
                                                    .link === "string"
                                                    ? this.state.dataModel.link
                                                    : this.state.dataModel.link
                                                          .Url
                                            }
                                            className="map-detail-card__cta cta-details cta cta--small"
                                        >
                                            View Details
                                        </a>
                                    </div>
                                    <div className="col-xs-12 col-sm-6">
                                        <a
                                            href={`https://www.google.com/maps/dir/?api=1&destination=${this.state.dataModel.lat},${this.state.dataModel.long}`}
                                            className={`map-detail-card__cta cta-directions cta cta--small cta--secondary${
                                                window.theme === "TheRocks"
                                                    ? "-black cta--black"
                                                    : ""
                                            }`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            Get Directions
                                        </a>
                                    </div>
                                </div>
                            </div>
                            {this.props.tenantGuid !== "market" && (
                                <hr className="map-detail-card__divider" />
                            )}

                            <div className="map-detail-card__infos">
                                {this.props.tenantGuid !== "market" && (
                                    <div className="row">
                                        <div className="col-xs-12 col-sm-6">
                                            <div className="map-detail-card__info-block map-detail-card__info-block--phone">
                                                <span className="label">
                                                    Phone
                                                </span>
                                                <div>
                                                    <a
                                                        href={`tel:${this.state.dataModel.phoneNumber}`}
                                                        className="link--silent"
                                                    >
                                                        {
                                                            this.state.dataModel
                                                                .phoneNumber
                                                        }
                                                    </a>
                                                </div>
                                            </div>

                                            <div className="map-detail-card__info-block map-detail-card__info-block--address">
                                                <span className="label">
                                                    Address
                                                </span>
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: this.state
                                                            .dataModel.address,
                                                    }}
                                                ></div>
                                            </div>
                                        </div>

                                        <div className="col-xs-12 col-sm-6">
                                            <div className="map-detail-card__info-block map-detail-card__info-block--hours">
                                                <span className="label">
                                                    Opening Hours
                                                </span>
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: this.state
                                                            .dataModel
                                                            .openingHours,
                                                    }}
                                                ></div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {this.props.tenantGuid === "market" && (
                                    <div className="row">
                                        <div className="col-xs-12">
                                            <div className="map-detail-card__info-block map-detail-card__info-block--market-opening">
                                                <span className="label">
                                                    Market days and location
                                                </span>
                                            </div>

                                            {this.state.dataModel.times &&
                                                this.state.dataModel.times.map(
                                                    (
                                                        timeObject: any,
                                                        index: number
                                                    ): any => (
                                                        <div
                                                            key={index}
                                                            className="map-detail-card__info-block map-detail-card__info-block--market-toggle"
                                                        >
                                                            <input
                                                                type="radio"
                                                                name="market-toggle"
                                                                id={`market-toggle-${index +
                                                                    1}`}
                                                                checked={
                                                                    this.props
                                                                        .activeMarket ===
                                                                    index + 1
                                                                }
                                                                onChange={() => {
                                                                    this.props.setActiveMarket(
                                                                        index +
                                                                            1
                                                                    );
                                                                }}
                                                            />
                                                            <label
                                                                htmlFor={`market-toggle-${index +
                                                                    1}`}
                                                            >
                                                                <span className="title">
                                                                    {
                                                                        timeObject.title
                                                                    }
                                                                </span>
                                                                <div className="time">
                                                                    {
                                                                        timeObject.time
                                                                    }
                                                                </div>
                                                                <div className="address">
                                                                    {
                                                                        timeObject.address
                                                                    }
                                                                </div>
                                                            </label>
                                                        </div>
                                                    )
                                                )}
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="map-detail-card__related always-xs">
                                {/* <div
                                    onClick={() => {
                                        if (this.scrollContainer.current) {
                                            // $FlowFixMe
                                            this.scrollContainer.current.scrollBy(
                                                {
                                                    top: 0,
                                                    left: 200,
                                                    behavior: "smooth",
                                                }
                                            );
                                        }
                                    }}
                                    className="map-detail-card__scroll-btn"
                                ></div> */}
                                {this.state.dataModel
                                    .availableEventsAndOffers &&
                                    this.state.dataModel
                                        .availableEventsAndOffers.Tiles && (
                                        <div className="related-content">
                                            <div className="container-fluid--bleed-right">
                                                <h2 className="related-content__heading heading-1">
                                                    {
                                                        this.state.dataModel
                                                            .availableEventsAndOffers
                                                            .Title
                                                    }
                                                </h2>

                                                <div className="related-content__tiles related-content__tiles--whatson row">
                                                    <div
                                                        ref={
                                                            this.scrollContainer
                                                        }
                                                        className="col-xs-12"
                                                        dangerouslySetInnerHTML={{
                                                            __html: this.state
                                                                .dataModel
                                                                .availableEventsAndOffers
                                                                .Tiles,
                                                        }}
                                                    ></div>
                                                    <button
                                                        type="button"
                                                        className="related-content__next-btn"
                                                        onClick={
                                                            this
                                                                .handleNextWhatsOnClick
                                                        }
                                                    >
                                                        <i className="icon icon-arrow-right-bold"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                {this.state.dataModel.relatedTilesHtml !=
                                    null && (
                                    <div className="related-content">
                                        <div className="container-fluid--bleed-right">
                                            <h2 className="related-content__heading heading-1">
                                                You might also like…
                                            </h2>

                                            <div className="related-content__tiles related-content__tiles--related row">
                                                <div
                                                    ref={this.scrollContainer}
                                                    className="col-xs-12"
                                                    dangerouslySetInnerHTML={{
                                                        __html: this.state
                                                            .dataModel
                                                            .relatedTilesHtml,
                                                    }}
                                                ></div>

                                                <button
                                                    type="button"
                                                    className="related-content__next-btn"
                                                    onClick={
                                                        this
                                                            .handleNextRelatedClick
                                                    }
                                                >
                                                    <i className="icon icon-arrow-right-bold"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </CSSTransition>
        );
    }
}

export default PrecinctMapDetailCard;
