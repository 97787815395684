// @flow

import * as React from "react";
import { ReactForm, ReactFormField } from "@adrenalin/react-form";
import _ from "lodash";
// import useAsyncState from "../hooks/useAsyncState";
import useMergableState from "../hooks/useMergableState";
import { getTenants, getEventsAndOffers } from "../api/tenantLanding";
import TextField from "../components/formfields/TextField";
import Tabs from "./Tabs";

const { useState, useEffect, useRef } = React;

type PropsType = {
    searchTenantsApiUrl: string,
    searchEventsAndOffersApiUrl: string,
    formStepsUrl: string,
    confirmDeleteUrl: string,
};

const TenantLandingForm = (props: PropsType): React.Node => {
    const [inputValues, setInputValues] = useMergableState({
        tenantName: "",
        tenantGuid: "",
        nodeGuid: "",
        tenantEmail: "",
    });
    const [tenants, setTenants] = useState([]);
    const [eventsAndOffers, setEventsAndOffers] = useState({
        events: [],
        offers: [],
    });
    const [type, setType] = useState("Event");
    const [action, setAction] = useState("create");
    // const [state, actions] = useAsyncState();

    const API = {
        searchTenants: (query: string): Promise<any> => {
            return getTenants(props.searchTenantsApiUrl, query);
        },
        searchEventsAndOffers: (query: string): Promise<any> => {
            return getEventsAndOffers(
                props.searchEventsAndOffersApiUrl.trim(),
                query
            );
        },
    };

    const searchTenants = (searchValue: string) => {
        // actions.request();
        API.searchTenants(searchValue)
            .then((response: any) => {
                setTenants(
                    response.Tenants.map((result: any): any => ({
                        key: result.NodeGuid,
                        text: result.Title,
                    }))
                );
                // actions.success();
            })
            .catch((error: any) => {
                // actions.fail("Something went wrong");
            });
    };

    const searchEventsAndOffers = (searchValue: string) => {
        // actions.request();
        API.searchEventsAndOffers(searchValue)
            .then((response: any) => {
                setEventsAndOffers({
                    events: response.Events.map((result: any): any => ({
                        key: result.NodeGuid,
                        text: result.Title,
                    })),
                    offers: response.Offers.map((result: any): any => ({
                        key: result.NodeGuid,
                        text: result.Title,
                    })),
                });
                // actions.success();
            })
            .catch((error: any) => {
                // actions.fail("Something went wrong");
            });
    };

    const debouncedSearchTenants = useRef(_.debounce(searchTenants, 250))
        .current;

    const debouncedSearchEventsAndOffers = useRef(
        _.debounce(searchEventsAndOffers, 250)
    ).current;

    useEffect(() => {
        if (
            inputValues.tenantName.length >= 3 &&
            debouncedSearchTenants &&
            inputValues.tenantGuid.length === 0
        ) {
            debouncedSearchTenants(inputValues.tenantName);
        } else if (inputValues.tenantName.length === 0) {
            setTenants([]);
        }
    }, [inputValues.tenantName]);

    return (
        <div className="tenant-landing__form-wrapper row">
            <ReactForm
                disableSubmitUntilValid={false}
                onSuccess={() => {
                    if (action === "delete") {
                        window.open(
                            props.confirmDeleteUrl +
                                `?TenantEmail=${inputValues.tenantEmail}&FormName=${type}&TenantGuid=${inputValues.tenantGuid}` +
                                (inputValues.nodeGuid
                                    ? `&NodeGuid=${inputValues.nodeGuid}`
                                    : ""),
                            "_self"
                        );
                        return;
                    }
                    window.open(
                        props.formStepsUrl +
                            `?TenantEmail=${inputValues.tenantEmail}&FormName=${type}&TenantGuid=${inputValues.tenantGuid}` +
                            (inputValues.nodeGuid
                                ? `&NodeGuid=${inputValues.nodeGuid}`
                                : ""),
                        "_self"
                    );
                }}
                className="tenant-landing__form col-xs-10 col-xs-offset-1 col-lg-7 col-lg-offset-2"
                validators={[
                    {
                        ID: "alwaysFalse",
                        validate: (): boolean => {
                            return false;
                        },
                        error: "Please select a name from drop down.",
                    },
                ]}
            >
                <fieldset className="react-form__section">
                    <div className="heading-3">Get Started</div>
                    <p>
                        Events and offers will appear on the What's On and
                        Offers pages, as well as your own tenant page. We'll aim
                        to publish your content within two working days. Enter
                        your details below to start.
                    </p>
                </fieldset>
                <fieldset className="react-form__section">
                    <legend className="react-form__section-label">
                        Tenant details
                    </legend>
                    <p>
                        Your email address will only be used to verify
                        information.
                    </p>
                    <ReactFormField
                        type="text"
                        name={`name`}
                        autoComplete="off"
                        label="Tenant Name"
                        fieldComponent={TextField}
                        value={inputValues.tenantName}
                        required={true}
                        errorMessages={{
                            required: "Please select a name from drop down.",
                        }}
                        validators={
                            inputValues.tenantGuid ? [] : ["alwaysFalse"]
                        }
                        onChange={(event: any) => {
                            setInputValues({
                                tenantName: event.target.value,
                                tenantGuid: "",
                            });
                        }}
                        suggestions={tenants}
                        autoSuggestion
                        onSuggestion={(suggestion: any): any => {
                            setInputValues({
                                tenantName: suggestion.text,
                                tenantGuid: suggestion.key,
                            });
                            debouncedSearchEventsAndOffers(suggestion.key);
                            setTenants([]);
                        }}
                    />

                    <ReactFormField
                        type="email"
                        name={`email`}
                        required={true}
                        validators={["email"]}
                        fieldComponent={TextField}
                        value={inputValues.tenantEmail}
                        onChange={(event: any) => {
                            setInputValues({
                                tenantEmail: event.target.value,
                            });
                        }}
                        errorMessages={{
                            required: "Please enter your email address.",
                        }}
                        label="Email"
                    />
                </fieldset>
                <fieldset className="react-form__section">
                    <legend className="react-form__section-label">
                        What would you like to work on today?
                    </legend>
                    <p>Events or Offers?</p>
                    <Tabs
                        label="Events or Offers?"
                        options={[
                            { key: "Event", text: "EVENTS" },
                            { key: "Offer", text: "OFFERS" },
                        ]}
                        onChange={setType}
                    />
                    <p>
                        Are you creating a new one, or editing/deleting an
                        existing one?
                    </p>
                    <Tabs
                        label="Are you creating a new one, or editing/deleting an existing one?"
                        options={[
                            { key: "create", text: "ADD NEW" },
                            { key: "update", text: "EDIT EXISTING" },
                            { key: "delete", text: "DELETE EXISTING" },
                        ]}
                        onChange={setAction}
                    />
                    <br />
                    {action !== "create" &&
                        (type === "Event" ? (
                            <ReactFormField
                                type="select"
                                name="event"
                                label="Select existing event"
                                required={true}
                                errorMessages={{
                                    required: "Please select existing event.",
                                }}
                                onChange={(event: any) => {
                                    setInputValues({
                                        nodeGuid: event.target.value,
                                    });
                                }}
                            >
                                <option value=""></option>
                                {eventsAndOffers.events.length > 0 &&
                                    eventsAndOffers.events.map(
                                        (event: any): React.Node => (
                                            <option
                                                value={event.key}
                                                key={event.key}
                                            >
                                                {event.text}
                                            </option>
                                        )
                                    )}
                            </ReactFormField>
                        ) : (
                            <ReactFormField
                                type="select"
                                name="offer"
                                label="Select existing offer"
                                required={true}
                                errorMessages={{
                                    required: "Please select existing offer.",
                                }}
                                onChange={(event: any) => {
                                    setInputValues({
                                        nodeGuid: event.target.value,
                                    });
                                }}
                            >
                                <option value=""></option>
                                {eventsAndOffers.offers.length > 0 &&
                                    eventsAndOffers.offers.map(
                                        (offer: any): React.Node => (
                                            <option
                                                value={offer.key}
                                                key={offer.key}
                                            >
                                                {offer.text}
                                            </option>
                                        )
                                    )}
                            </ReactFormField>
                        ))}
                </fieldset>
                <button
                    type="submit"
                    className="react-form__button cta cta--inline cta--big"
                >
                    Next
                </button>
            </ReactForm>
        </div>
    );
};

export default TenantLandingForm;
