// @flow

const wrapEl = (el: HTMLElement, wrapper: HTMLElement) => {
    const parent = el.parentNode;
    if (parent) {
        parent.insertBefore(wrapper, el);
        wrapper.appendChild(el);
    }
};

export default wrapEl;
