// @flow

class ButtonLink {
    //$el: HTMLElement;
    //href: ?string;
    //target: ?string;

    constructor(/*el: HTMLElement*/) {
        // this.$el = el;
        // this.href = this.$el.getAttribute("href");
        // this.target = this.$el.getAttribute("target");

        if (document.body) {
            document.body.addEventListener("click", (event: any) => {
                if (event.target.hasAttribute("data-button-link")) {
                    event.stopPropagation();
                    event.preventDefault();

                    const href = event.target.getAttribute("href");
                    const target = event.target.getAttribute("target");

                    if (href) {
                        if (target === "_blank") {
                            window.open(href);
                        } else {
                            document.location.href = href;
                        }
                    }
                }
            });
        }

        // if (this.$el) {
        //     this.$el.setAttribute("role", "link");

        //     this.$el.addEventListener("click", (event: any) => {
        //         event.stopPropagation();
        //         event.preventDefault();
        //         if (this.href) {
        //             if (this.target === "_blank") {
        //                 window.open(this.href);
        //             } else {
        //                 document.location.href = this.href;
        //             }
        //         }
        //     });
        // }
    }
}

export default ButtonLink;
