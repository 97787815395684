// @flow

import * as React from "react";
import useAsyncState from "../hooks/useAsyncState";
import pubSub from "../utils/pubsub";
import _ from "lodash";
import { getAutoSuggestions } from "../api/search";

const { useState, useEffect, useRef } = React;

type PropsType = {
    standalone?: boolean,
    searchTerm?: string,
    apiEndpoint: string,
    targetPage: string,
};

const decodeHTML = (html: string): string => {
    var txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
};

const QuickSearchResult = (props: {
    query: string,
    text: string,
    url: string,
}): React.Node => {
    const decodedText = props.text.indexOf("&")
        ? decodeHTML(props.text)
        : props.text;
    const indexOfMatch = props.query
        ? decodedText.toLowerCase().indexOf(props.query.toLowerCase())
        : -1;

    let before = decodedText;
    let inside = "";
    let after = "";

    if (indexOfMatch !== -1) {
        before = decodedText.substr(0, indexOfMatch);
        inside = decodedText.substr(indexOfMatch, props.query.length);
        after = decodedText.substr(indexOfMatch + props.query.length);
    }

    return (
        <li
            key={props.url}
            className={`live-search__result-item live-search__result-item${
                props.url ? "--is-result" : "--is-state"
            }`}
        >
            {props.url && (
                <a
                    href={
                        props.url
                            ? `${props.url}?searchTerm=${props.query}`
                            : `/search-page.html?q=${props.query}`
                    }
                    className={`live-search__result`}
                >
                    <span>
                        {before}
                        <strong>{inside}</strong>
                        {after}
                    </span>
                </a>
            )}
            {!props.url && <span>{props.text}</span>}
        </li>
    );
};

QuickSearchResult.defaultProps = {
    query: "",
    url: "",
    text: "",
};

const LiveSearch = (props: PropsType): React.Node => {
    const [results, setResults] = useState([]);
    const [state, actions] = useAsyncState();
    const [inputValue, setInputValue] = useState(props.searchTerm || "");

    const API = {
        search: (query: string): Promise<any> => {
            return getAutoSuggestions(props.apiEndpoint, query);
        },
    };

    const search = (searchValue: string) => {
        actions.request();
        API.search(searchValue)
            .then((response: any) => {
                setResults(response.Results);
                actions.success();
            })
            .catch((error: any) => {
                actions.fail("Something went wrong");
            });
    };

    const debouncedSearch = useRef(_.debounce(search, 250)).current;

    useEffect(() => {
        if (
            inputValue.length >= 3 &&
            debouncedSearch &&
            inputValue !== props.searchTerm
        ) {
            debouncedSearch(inputValue);
            if (props.standalone) pubSub.publish(`show-content-overlay`);
        } else if (inputValue.length === 0) {
            setResults([]);
            if (props.standalone) pubSub.publish(`hide-content-overlay`);
        }
    }, [inputValue]);

    const handleChange = (event: SyntheticEvent<HTMLInputElement>) => {
        setInputValue(event.currentTarget.value);
    };

    const handleClear = () => {
        setInputValue("");
    };

    //blur event for safari bug.
    //when scrolling, it hides the keyboard, thus preventing the bug from appearing.
    //Timeout is so the scrollListener doesn't activate from the event of the keyboard popping up when the input has focus
    let lightboxContainer: any = null;
    const blurInput = () => {
        window.document.activeElement.blur();
        if (lightboxContainer) {
            lightboxContainer.removeEventListener("scroll", blurInput);
        }
    };

    const registerScrollListener = () => {
        setTimeout(() => {
            lightboxContainer = window.document.querySelector(
                ".lightbox--live-search-container"
            );
            if (lightboxContainer) {
                lightboxContainer.addEventListener("scroll", blurInput);
            }
        }, 500);
    };

    var stateLabel = "";

    if (!state.loading && results.length > 0) {
        stateLabel = "Suggestions";
    } else if (state.loading) {
        stateLabel = "Loading…";
    }

    return (
        <div
            className={
                props.standalone
                    ? "live-search--standalone"
                    : "live-search--inline"
            }
        >
            <h2 className="live-search__heading heading-4">
                What are you looking for?
            </h2>
            <form
                action={props.targetPage}
                method="GET"
                className="live-search__form"
            >
                <button
                    type="button"
                    onClick={blurInput}
                    className="live-search__button live-search__button--search"
                    aria-label="Serch"
                >
                    <i className="icon icon-search"></i>
                </button>
                <input
                    autoComplete="off"
                    className="live-search__input"
                    name="searchTerm"
                    placeholder="Search"
                    type="text"
                    value={inputValue}
                    aria-label="Search term"
                    onChange={handleChange}
                    onFocus={registerScrollListener}
                />
                <button
                    className={`live-search__button live-search__button--clear ${
                        inputValue === "" ? "live-search__button--hidden" : ""
                    }`}
                    type="button"
                    onClick={handleClear}
                    aria-label="Clear search field"
                >
                    <i className="icon icon-clear"></i>
                </button>
            </form>
            <ul className="live-search__results">
                <QuickSearchResult text={stateLabel} />
                {inputValue &&
                    !state.loading &&
                    results.map((result: Object): React.Node => (
                        <QuickSearchResult
                            key={result.Url}
                            text={result.Title}
                            url={result.Url}
                            query={inputValue}
                        />
                    ))}
            </ul>
        </div>
    );
};

export default LiveSearch;
