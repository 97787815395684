// @flow

import { cookie } from "@utils/";

import { detect } from "detect-browser";
import Config from "../constants/Config";

class OutdatedBrowser {
    constructor() {
        this.$el = document.createElement("div");
        this.init();
    }

    init() {
        const browser = detect();
        if (
            !cookie.get(`outdated-browser`) &&
            browser &&
            Config.outdatedBrowser[browser.name]
        ) {
            const majorVerion = parseInt(browser.version.split(".")[0]);
            console.log(
                `Browser: ${browser.name}, Major: ${majorVerion}, Version: ${browser.version}`
            );
            if (majorVerion <= Config.outdatedBrowser[browser.name]) {
                this.renderModal();
            }
        }
    }
    renderModal() {
        const html = `
        <div class="outdated-browser__inner">
            <div class="eyebrow eyebrow--wide">
                Unsupported browser
            </div>

            <h1 class="outdated-browser__title heading-3">
                Please upgrade to a modern browser
            </h1>

            <div class="outdated-browser__description rich-text">
                Your browser will have problems displaying this site. For a better experience please upgrade to one of the
                following browsers: 
                <a href="https://www.google.com/intl/en_au/chrome/" target="_blank">Chrome</a>,
                <a href="https://www.microsoft.com/en-us/edge" target="_blank">Edge</a>,
                <a href="https://www.mozilla.org/en-US/exp/firefox/new/" target="_blank">Firefox</a> or 
                <a href="https://www.apple.com/au/safari/" target="_blank">Safari</a> (Mac only).
            </div>

            <a href="#" class="cta cta--secondary cta--black cta--inline">
                Continue anyway
            </a>
        </div>
    `;
        this.$el.classList.add("outdated-browser");
        this.$el.innerHTML = html;
        this.$cta = this.$el.querySelector(".cta");
        this.$cta.addEventListener("click", () => {
            this.closeNotification();
        });

        document.body.appendChild(this.$el);
    }
    closeNotification() {
        cookie.set(`outdated-browser`, true, 1);
        this.$el.style.display = "none";
    }
}

export default OutdatedBrowser;
