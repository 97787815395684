import getStyle from "./getStyle";

export const isMobileBreakpoint = () => {
    //create test element
    const testMobileDiv = document.createElement("div");
    if (process.env.SCOPE_ATTRIBUTE) {
        testMobileDiv.setAttribute(process.env.SCOPE_ATTRIBUTE, "");
    }
    testMobileDiv.classList.add("hide-sm");

    // append test element to body
    document.body.appendChild(testMobileDiv);

    // get display style value
    const computedDisplay = getStyle(testMobileDiv, "display");

    // remove test element
    testMobileDiv.remove();

    if (computedDisplay === "none") {
        return true;
    } else {
        return false;
    }
};

export const isBreakpoint = size => {
    //create test element
    const testMobileDiv = document.createElement("div");
    if (process.env.SCOPE_ATTRIBUTE) {
        testMobileDiv.setAttribute(process.env.SCOPE_ATTRIBUTE, "");
    }
    testMobileDiv.classList.add(`hide-${size}`);

    // append test element to body
    document.body.appendChild(testMobileDiv);

    // get display style value
    const computedDisplay = getStyle(testMobileDiv, "display");

    // remove test element
    testMobileDiv.remove();

    if (computedDisplay === "none") {
        return true;
    } else {
        return false;
    }
};

export const getBreakpoint = (breakpoints = ["xl", "lg", "md", "sm", "xs"]) => {
    let currentBreakpoint;
    breakpoints.forEach(breakpoint => {
        if (isBreakpoint(breakpoint)) {
            currentBreakpoint = breakpoint;
        }
    });
    return currentBreakpoint;
};

window.responsive = {
    getBreakpoint: getBreakpoint,
    isBreakpoint: isBreakpoint,
    isMobileBreakpoint: isMobileBreakpoint,
};

export default {
    isMobileBreakpoint,
    isBreakpoint,
};
