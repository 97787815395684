// @flow

import * as React from "react";
import { ReactForm, ReactFormField } from "@adrenalin/react-form";
import newsletterApi from "../api/newsletter";
import { cookie } from "@utils/";
import useMergableState from "../hooks/useMergableState";
import ReCAPTCHA from "react-google-recaptcha";
const { useState } = React;

type PropsType = {
    campaignId: string,
    successMessage: string,
    errorMessage: string,
    listIdSource: string,
    recaptchaInvisibleSiteKey: string,
    apiUrl: string,
};

const NewsletterForm = (props: PropsType): React.Node => {
    const defaultInputValues = {};
    let submitFormData = {};
    const [formVisibility, setFormVisibility] = useState(true);
    const [hasError, setHasError] = useState(false);
    const [inputValues, setInputValues] = useMergableState(defaultInputValues);

    const recaptchaRef = React.createRef();

    const randId =
        "form-" +
        Math.random()
            .toString(36)
            .substr(-4);

    const handleFormValidated = (formData: Object) => {
        // on validated form submit set validated form data and trigger captcha check
        submitFormData = formData;
        recaptchaRef.current.execute();
    };

    const handleRecaptchaSubmit = (captchaResponse: string) => {
        // reset captcha
        recaptchaRef.current.reset();

        // on captcha check change, if valida captcha and form data post the form
        if (captchaResponse && submitFormData) {
            submitToApi(captchaResponse);
        }
    };

    const submitToApi = (captchaResponse: string) => {
        console.log(recaptchaRef.current);

        newsletterApi
            .postSubscribe(
                submitFormData[`${randId}-email`],
                submitFormData[`${randId}-name`],
                props.campaignId,
                props.segment,
                props.listIdSource,
                props.apiUrl,
                captchaResponse
            )
            .then((response: any): any => {
                setHasError(false);
                setFormVisibility(false);
                cookie.set(`newsletter-toaster`, true, 365);
            })
            .catch((error: any) => {
                setHasError(true);
                setFormVisibility(false);
            });
    };

    return (
        <div className="react-form__form-wrapper">
            {formVisibility ? (
                <ReactForm
                    disableSubmitUntilValid={false}
                    onSuccess={handleFormValidated}
                    className="react-form__form"
                >
                    <ReactFormField
                        type="text"
                        name={`${randId}-name`}
                        required={true}
                        validators={["name"]}
                        errorMessages={{
                            required: "Please enter your first name.",
                        }}
                        label="First Name"
                        className="react-form__input"
                    />

                    <ReactFormField
                        type="email"
                        name={`${randId}-email`}
                        required={true}
                        validators={["email"]}
                        errorMessages={{
                            required: "Please enter your email address.",
                        }}
                        label="Email"
                        className="react-form__input"
                    />

                    <ReCAPTCHA
                        ref={recaptchaRef}
                        sitekey={props.recaptchaInvisibleSiteKey}
                        size="invisible"
                        onChange={handleRecaptchaSubmit}
                    />

                    <button type="submit" className="react-form__button">
                        Subscribe
                    </button>
                </ReactForm>
            ) : (
                <div className="react-form__success-message">
                    {!hasError && (
                        <i className="icon icon-checkmark-circle"></i>
                    )}

                    <span
                        className="rich-text"
                        dangerouslySetInnerHTML={{
                            __html: hasError
                                ? props.errorMessage
                                : props.successMessage,
                        }}
                    ></span>
                </div>
            )}
        </div>
    );
};

export default NewsletterForm;
