// @flow

import * as React from "react";

type PropsType = {
    label?: string,
    children: React.Node,
    required?: boolean,
    info?: string,
    tooltip?: string,
};

const RadioGroup = (props: PropsType): React.Node => {
    return (
        <fieldset className="radio-group" title={props.tooltip || ""}>
            {props.label ? (
                <legend
                    className="radio-group__label react-form__label-title"
                    dangerouslySetInnerHTML={{ __html: props.label }}
                ></legend>
            ) : null}
            {props.info ? (
                <span
                    className={`react-form__field__label__info-text react-form__label-description`}
                    dangerouslySetInnerHTML={{ __html: props.info }}
                ></span>
            ) : null}
            <div className="radio-group__contents">{props.children}</div>
        </fieldset>
    );
};

export default RadioGroup;
