// @flow

const formatter = window.Intl
    ? new Intl.NumberFormat("en-AU", {
          style: "currency",
          currency: "AUD",
      }).format
    : (money: number): any => {
          return `$${money.toFixed(2)}`;
      };

export default formatter;
