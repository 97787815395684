// @flow

// import _ from "lodash";

class InfoFlatHeader {
    $el: HTMLElement;
    $card: any;
    $flatHeader: any;
    offset: number;

    constructor(el: HTMLElement) {
        this.$el = el;
        this.offset = parseInt(this.$el.getAttribute("data-offset")) || 0;
        this.$card = this.$el.querySelector(".info-flat-header__col-card");
        this.$flatHeader = this.$el.querySelector(
            ".info-flat-header__content-container"
        );

        this.init();
    }

    init() {
        this.animLoop();
    }

    animLoop() {
        if (this.$flatHeader && this.$card && this.$card.style) {
            const flatHeaderHeight = this.$flatHeader.clientHeight;
            const viewportWidth = Math.max(window.innerWidth || 0);
            const offset =
                viewportWidth >= 768 ? flatHeaderHeight * -1 + this.offset : 0;
            this.$card.style.marginTop = `${offset}px`;
        }
        requestAnimationFrame(() => {
            this.animLoop();
        });
    }
}

export default InfoFlatHeader;
