// @flow

import { get } from "./helpers";

export const getAutoSuggestions = async (
    apiEndpoint: string,
    query: string
): Promise<any> => {
    const url = apiEndpoint + `?searchTerm=${query}`;
    return get(url);
};

export default {
    getAutoSuggestions,
};
