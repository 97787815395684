// @flow

const toggleActiveSelector = "data-toggle-active";
const toggleActiveClass = "is-active";

class ToggleActive {
    $mobileMenuOpenTriggers: NodeList<HTMLElement>;

    constructor() {
        this.$mobileMenuOpenTriggers = document.querySelectorAll(
            `[${toggleActiveSelector}]`
        );

        this.init();
    }

    init() {
        this.$mobileMenuOpenTriggers.forEach(($element: HTMLElement): any => {
            $element.addEventListener("click", function(e: Event) {
                const selectorToToggle = $element.getAttribute(
                    toggleActiveSelector
                );
                if (selectorToToggle) {
                    const $selectorElement = document.querySelector(
                        selectorToToggle
                    );
                    if (selectorToToggle && $selectorElement) {
                        if (
                            $selectorElement.classList.contains(
                                toggleActiveClass
                            )
                        ) {
                            $selectorElement.classList.remove(
                                toggleActiveClass
                            );
                        } else {
                            $selectorElement.classList.add(toggleActiveClass);
                        }
                        e.preventDefault();
                    }
                }
            });
        });
    }
}

export default ToggleActive;
