// @flow

// import _ from "lodash";

class AnimatedHeight {
    $el: HTMLElement;
    $innerWrapper: HTMLElement;

    constructor(el: HTMLElement) {
        this.$el = el;

        this.init();
    }

    init() {
        // wrap contents in inner div
        // this.$innerWrapper = document.createElement("div");
        // this.$innerWrapper.innerHTML = this.$el.innerHTML;
        // this.$el.innerHTML = "";
        // this.$el.appendChild(this.$innerWrapper);

        // // kick off animation frame loop
        // if (this.$el && this.$innerWrapper) {
        //     this.animLoop();
        // }

        this.animLoop();
    }

    animLoop() {
        const fixedHeight = this.$el.getAttribute("data-animate-height");
        const $children = Array.from(this.$el.children);
        let totalChildHeight = 0;
        $children.forEach(($element: any) => {
            totalChildHeight = totalChildHeight + $element.offsetHeight;
        });

        //const outerHeight = _.get(this, "$el.clientHeight", 0);

        const innerHeight = !isNaN(parseInt(fixedHeight))
            ? parseInt(fixedHeight)
            : totalChildHeight;

        const finalHeight = !isNaN(parseInt(innerHeight))
            ? parseInt(innerHeight)
            : "auto";

        this.$el.style.height = `${finalHeight}px`;

        requestAnimationFrame(() => {
            this.animLoop();
        });
    }
}

export default AnimatedHeight;
