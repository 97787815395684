//@flow

import { isMobileBreakpoint } from "@utils/responsive";

class Parallax {
    $el: HTMLElement;
    $items: NodeList<HTMLElement>;
    parallaxAmount: number;

    constructor(el: HTMLElement) {
        this.$el = el;
        this.$items = this.$el.querySelectorAll("[data-parallax-item]");
        this.parallaxAmount = Number(this.$el.getAttribute("data-parallax"));

        // startup
        this.init();

        // events
        this.attachEvents();
    }

    init() {
        this.prepareItems();
        this.handleScroll();
    }

    attachEvents() {
        window.addEventListener("resize", this.handleScroll.bind(this));
        window.addEventListener("scroll", this.handleScroll.bind(this));
    }

    prepareItems() {
        this.$items.forEach(($item: HTMLElement) => {
            //$item.style.transition = "all 300ms";
        });
    }

    handleScroll(event: any) {
        if (isMobileBreakpoint()) {
            this.$items.forEach(($item: HTMLElement) => {
                $item.style.transform = `none`;
            });
            return;
        }

        const elBoundry = this.$el.getBoundingClientRect();
        const centerYPosition = Math.round(
            window.pageYOffset +
                elBoundry.top +
                Math.min(elBoundry.height, window.innerHeight) / 2
        );
        const viewportTop = window.pageYOffset;
        const viewportBottom = window.pageYOffset + window.innerHeight;

        let scrollFactor = 0;

        if (centerYPosition > viewportTop && centerYPosition < viewportBottom) {
            const relPositionFromCenter =
                centerYPosition - viewportTop - window.innerHeight / 2;
            scrollFactor = relPositionFromCenter / (window.innerHeight / 2);
        } else if (centerYPosition <= viewportTop) {
            scrollFactor = -1;
        } else if (centerYPosition >= viewportBottom) {
            scrollFactor = 1;
        }

        this.$items.forEach(($item: HTMLElement) => {
            const itemCurrentAmount =
                (this.parallaxAmount / 2) *
                scrollFactor *
                (Number($item.getAttribute("data-parallax-item")) - 0);

            $item.style.transform = `translate(0, calc(${itemCurrentAmount}px))`;
        });
    }
}

export default Parallax;
