//@flow

import { getBreakpoint } from "@utils/responsive";

class FlexibleGrid {
    $el: HTMLElement;
    $items: NodeList<HTMLElement>;
    colsXS: string;
    colsSM: string;
    colsMD: string;
    colsLG: string;
    colsXL: string;
    previousVPWidth: number;

    constructor(el: HTMLElement) {
        this.$el = el;
        this.$items = this.$el.querySelectorAll("[flexible-grid-item]");
        this.colsXS = this.$el.getAttribute("data-flexible-grid-xs") || "1";
        this.colsSM =
            this.$el.getAttribute("data-flexible-grid-sm") || this.colsXS;
        this.colsMD =
            this.$el.getAttribute("data-flexible-grid-md") || this.colsSM;
        this.colsLG =
            this.$el.getAttribute("data-flexible-grid-lg") || this.colsMD;
        this.colsXL =
            this.$el.getAttribute("data-flexible-grid-xl") || this.colsLG;
        this.previousVPWidth = 0;

        // startup
        this.init();

        // events
        this.attachEvents();
    }

    init() {
        this.doLayout();
    }

    attachEvents() {
        window.addEventListener("resize", (): any => this.doLayout());
    }

    getColumnsforBreakpoint(): string {
        switch (getBreakpoint()) {
            case "xs":
                return this.colsXS;
            case "sm":
                return this.colsSM;
            case "md":
                return this.colsMD;
            case "lg":
                return this.colsLG;
            case "xl":
                return this.colsXL;
            default:
                return this.colsXS;
        }
    }

    doLayout() {
        const newVPWidth = Math.max(window.innerWidth || 0);
        if (newVPWidth !== this.previousVPWidth) {
            this.previousVPWidth = newVPWidth;
            const numberOfCols = Number(this.getColumnsforBreakpoint());
            this.$el.innerHTML = "";

            for (let i = 0; i < numberOfCols; i++) {
                const column = document.createElement("div");
                column.classList.add(`col-xs-${12 / numberOfCols}`);

                // loop over items
                this.$items.forEach(($item: any, index: number) => {
                    if (index % numberOfCols === i) {
                        // append item to column
                        column.appendChild($item);
                    }
                });

                // append column to container
                this.$el.appendChild(column);
            }
        }
    }
}

export default FlexibleGrid;
