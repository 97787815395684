import React from "react";

const parentClassname = "react-form";

const Field = props => {
    const componentClassname = parentClassname
        ? `${parentClassname}__field`
        : "field";
    const componentWrapperClassname = parentClassname
        ? `${parentClassname}__element`
        : "element";

    return (
        <div
            className={`${componentWrapperClassname} ${componentWrapperClassname}--hidden`}
        >
            <div
                className={`${componentClassname} 
				${componentClassname}--hidden
				${props.classes.inheritedClasses}
			`}
            >
                {/*input element*/}
                {React.cloneElement(props.inputElement, {
                    type: "hidden",
                })}
            </div>
        </div>
    );
};

export default Field;
