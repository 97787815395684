//@flow
import Carousel from "@modules/Carousel";
class Lightbox {
    $el: HTMLElement;
    $inner: HTMLElement;
    $container: HTMLElement;
    $contents: HTMLElement;
    $closeButton: HTMLElement;
    additionalClass: ?string;
    isCarousel: boolean;
    isVideo: boolean;
    carousel: any;
    carouselStartAt: number;
    onClose: (index?: number) => void;
    onOpen: (index?: number) => void;
    constructor(props: any) {
        this.$contents = props.contents;
        this.isCarousel = props.carousel;
        this.isVideo = props.isVideo;
        this.carouselStartAt = props.carouselStartAt;
        this.onClose = props.onClose;
        this.onOpen = props.onOpen;
        this.additionalClass = props.additionalClass;
        // startup
        this.init();
        // events
        this.attachEvents();
    }
    init() {
        // create lighbox element
        this.$el = document.createElement("div");
        this.$el.classList.add("lightbox");
        if (this.additionalClass) {
            this.$el.classList.add(this.additionalClass || "");
        }
        this.$el.setAttribute("aria-modal", "true");
        // create contents container element
        this.$inner = document.createElement("div");
        this.$inner.classList.add("lightbox__contents");
        // create carousel container element
        this.$container = document.createElement("div");
        this.$container.classList.add("lightbox__container");
        // create close button
        this.$closeButton = document.createElement("button");
        this.$closeButton.setAttribute("type", "button");
        this.$closeButton.setAttribute("aria-label", "Close Lightbox");
        this.$closeButton.classList.add("lightbox__close");
        // add elements to lightbox
        this.$container.appendChild(this.$contents);
        this.$inner.appendChild(this.$container);
        this.$el.prepend(this.$closeButton);
        this.$el.appendChild(this.$inner);
        // append lightbox with contents to body
        const $body = document.querySelector("body");
        if ($body) {
            $body.classList.add("scroll-lock");
            $body.appendChild(this.$el);
        }
        if (this.isCarousel || this.isVideo) {
            // add container classes
            this.$container.classList.add("container-fluid");
            this.$container.classList.add("container-fluid--large");
            this.$container.classList.add("lightbox__container");
            //this.$container.classList.add("container-fluid--bleed-right");
        }
        // if carousel then init lightbox carousel
        if (this.isCarousel) {
            // create lightbox carousel
            this.carousel = new Carousel({
                element: this.$contents,
                startAt: this.carouselStartAt,
            });
        }
        if (this.onOpen) {
            this.onOpen();
        }
    }
    attachEvents() {
        //for button in acknowledgement lightbox
        const acknowledgementCloseButton = document.querySelector(
            ".acknowledgement-of-country__button"
        );
        this.$closeButton.addEventListener("click", (event: any): any => {
            this.closeLightbox();
        });
        if (acknowledgementCloseButton) {
            acknowledgementCloseButton.addEventListener(
                "click",
                (event: any): any => {
                    this.closeLightbox();
                }
            );
        }
        document.addEventListener("click", (event: MouseEvent) => {
            const target = event.target;
            if (
                target.matches(
                    ".cta.cta--secondary.acknowledgement-of-country__button"
                )
            ) {
                this.closeLightbox();
            }
        });
    }
    closeLightbox() {
        const $body = document.querySelector("body");
        if ($body) {
            $body.classList.remove("scroll-lock");
        }
        if (this.isCarousel) {
            this.onClose(this.carousel.GlideCarousel.index);
        }
        this.$el.remove();
    }
}

export default Lightbox;
