//@flow

import Lightbox from "@modules/Lightbox";
import LoadYoutubeIframeAPI from "@utils/LoadYoutubeIframeAPI";

class YoutubePlayer {
    $el: HTMLElement;
    $playToggleBtn: HTMLElement;
    $playerEl: HTMLElement;
    $posterEl: HTMLElement;
    youtubeId: string;
    posterImage: string;
    inLightbox: boolean;
    playInLightbox: boolean;
    autoplay: boolean;
    player: any;
    loop: boolean;
    mute: boolean;
    isiOS: boolean;

    constructor(props: any) {
        this.$el = props.element;
        this.$playToggleBtn = this.$el.parentNode
            ? this.$el.parentNode.querySelector(
                  "[data-inline-youtube-play-toggle]"
              )
            : false;
        this.youtubeId = props.id;
        this.posterImage =
            props.poster ||
            this.$el.style.backgroundImage ||
            this.$el.getAttribute("data-bg");
        this.inLightbox = props.inLightbox ? true : false;
        this.playInLightbox = props.playInLightbox ? true : false;
        this.autoplay = props.autoplay ? true : false;
        this.loop = props.loop;
        this.mute = props.mute;
        this.controls = props.controls;
        this.isiOS =
            !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);

        LoadYoutubeIframeAPI().then((): any => {
            // startup
            this.init();

            // events
            this.attachEvents();
        });
    }

    init() {
        if (!this.youtubeId.match(/[a-zA-Z0-9_-]+/g)) {
            this.$el.remove();
        }
        // set parent class
        this.$el.classList.add("youtube-player");

        // create child for player
        this.$playerEl = document.createElement("div");
        this.$playerEl.classList.add("youtube-player__iframe");
        this.$playerEl.setAttribute("tabindex", "-1");
        this.$el.appendChild(this.$playerEl);

        // create poster for player
        if (this.posterImage) {
            this.$posterEl = document.createElement("div");
            this.$posterEl.classList.add("youtube-player__poster");
            this.$posterEl.style.backgroundImage = this.posterImage;
            this.$el.appendChild(this.$posterEl);
        }

        // init youtube player
        if (!this.playInLightbox) {
            this.player = new window.YT.Player(this.$playerEl, {
                videoId: this.youtubeId,
                playerVars: {
                    rel: 0,
                    controls: this.controls,
                    showinfo: 0,
                    autohide: 1,
                    wmode: "opaque",
                },
                events: {
                    onReady: () => {
                        if (this.autoplay) {
                            // autoplay if launched in ligthbox on the specific slide
                            this.startPlaying();
                            if (!this.inLightbox) this.player.mute();
                            if (this.isiOS) {
                                //this.$el.classList.add("has-started");
                            }
                        }
                    },
                    onStateChange: (e: any) => {
                        if (e.data === 1) {
                            // playing
                            this.$el.classList.add("has-started");
                            this.$el.classList.remove("is-paused");
                        }
                        if (e.data === 2) {
                            // paused
                            this.$el.classList.add("is-paused");
                        }

                        if (
                            this.loop &&
                            e.data === window.YT.PlayerState.ENDED
                        ) {
                            this.player.playVideo();
                        }
                    },
                },
            });
        }

        // Control events

        // Play/Pause Toggle btn
        if (this.$playToggleBtn) {
            this.$playToggleBtn.addEventListener(
                "click",
                this.togglePlayPause.bind(this)
            );
        }
    }

    attachEvents() {
        if (this.$posterEl) {
            this.$posterEl.addEventListener("click", () => {
                if (this.playInLightbox && !this.inLightbox) {
                    this.setupLightbox();
                } else if (this.inLightbox) {
                    this.startPlaying();
                }
            });
        }
    }

    setupLightbox() {
        // setup new youtube player for lightbox
        this.$lightboxPlayerEl = document.createElement("div");
        new YoutubePlayer({
            element: this.$lightboxPlayerEl,
            id: this.youtubeId,
            inLightbox: true,
            autoplay: 1,
            loop: false,
            mute: false,
            controls: true,
        });

        // create the ligthtbox
        this.carouselLightbox = new Lightbox({
            contents: this.$lightboxPlayerEl,
            carousel: false,
            isVideo: true,
        });
    }

    togglePlayPause() {
        if (
            this.player &&
            this.player.getPlayerState &&
            this.player.getPlayerState() === 1
        ) {
            this.pausePlaying();
        } else {
            this.startPlaying();
        }
    }

    startPlaying() {
        if (this.player && this.player.playVideo) this.player.playVideo();
    }

    pausePlaying() {
        if (this.player && this.player.pauseVideo) this.player.pauseVideo();
    }
}

export default YoutubePlayer;
