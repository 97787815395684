// @flow

import { get, postUpload } from "./helpers";

export const postFormData = (
    formSubmissionApi: string,
    formData: Object
): Promise<any> => {
    return postUpload(formSubmissionApi, formData, {});
};

export const getFormStructure = (
    formStructureApi: string,
    formName: string,
    isPageType: boolean = false,
    tenantGuid: string = "",
    nodeGuid: string = ""
): Promise<any> => {
    if (isPageType) {
        return get(
            `${formStructureApi}?formName=${formName}&tenantGuid=${tenantGuid}&nodeGuid=${nodeGuid}`
        );
    }
    return get(`${formStructureApi}?formName=${formName}`);
};

export default {
    postFormData,
    getFormStructure,
};
