// @flow
const ratio = ((): any => {
    function getRatio(ratio: number, el: any): number {
        const width =
            el && typeof el !== "undefined"
                ? el.offsetWidth
                : window.innerWidth;
        return Math.floor(width / ratio);
    }

    return {
        getRatio,
    };
})();

export default ratio;
