import React from "react";
import ReCAPTCHA from "react-google-recaptcha";

const parentClassname = "react-form";

const RecaptchaField = props => {
    const componentClassname = parentClassname
        ? `${parentClassname}__field`
        : "field";

    const hasErrors =
        !props.hideErrors &&
        props.errors &&
        props.errors.length &&
        props.showErrors;

    return (
        <div
            className={`${componentClassname}
				${componentClassname}--${props.type}
				${props.classes.inheritedClasses}
				${props.classes.isDisabled}
				${props.classes.isIncomplete}
				${props.classes.isFocused}
				${props.classes.hasValue}
				${props.classes.isValid}
                ${props.classes.isInvalid}
                ${props.required ? "" : "hidden"}
			`}
        >
            {/*input element*/}
            {React.cloneElement(props.inputElement, {
                type: "hidden",
            })}
            <ReCAPTCHA
                className={`${parentClassname}__element ${parentClassname}__element--recaptcha ${
                    props.required ? "" : "hidden"
                }`}
                sitekey={props.recaptchaSiteKey}
                size={props.size ? props.size : "normal"}
                onChange={value => {
                    if (props.onChange) {
                        props.onChange({
                            target: {
                                value: value,
                                name: props.name,
                            },
                        });
                    }
                }}
            />
            {/*errors*/}
            {hasErrors ? (
                <div className={`${componentClassname}__errors`}>
                    <div
                        className={`${componentClassname}__errors__item`}
                        id={props.accessibilityIds.error}
                    >
                        Error: {props.errors[0]}
                    </div>
                </div>
            ) : null}

            {/*info*/}
            {props.info ? (
                <div
                    className={`${componentClassname}__info`}
                    id={props.accessibilityIds.description}
                >
                    {props.info}
                </div>
            ) : null}
        </div>
    );
};

export default RecaptchaField;
