import React from "react";
const prettyBytes = require("pretty-bytes");

const parentClassname = "react-form";

const FileUpload = props => {
    const componentClassname = parentClassname
        ? `${parentClassname}__field`
        : "field";

    const labelPlaceholderClassname = props.labelplaceholder
        ? `${componentClassname}__label--labelplaceholder`
        : "";

    const hasErrors =
        !props.hideErrors &&
        props.errors &&
        props.errors.length &&
        props.errors[0] !== "Please enter a value for this field.";

    return (
        <div
            className={`${componentClassname}
				${componentClassname}--${props.type}
				${props.classes.inheritedClasses}
				${props.classes.isDisabled}
				${props.classes.isIncomplete}
				${props.classes.isFocused}
				${props.classes.hasValue}
				${props.classes.isValid}
                ${props.classes.isInvalid}
                ${props.checked ? "is-checked" : ""}
                ${hasErrors ? "is-invalid" : ""}
			`}
        >
            {/*label*/}
            {props.label ? (
                <div
                    className={`${componentClassname}__label  ${labelPlaceholderClassname} react-form__label-title`}
                >
                    <div
                        className={`${componentClassname}__label__inner`}
                        id={props.accessibilityIds.label}
                    >
                        <span
                            dangerouslySetInnerHTML={{
                                __html: props.label,
                            }}
                        ></span>
                        {props.required ? null : !props.hideOptional ? (
                            <span
                                className={`${componentClassname}__label__optional-label`}
                            ></span>
                        ) : null}
                    </div>
                </div>
            ) : null}
            {/*info*/}
            {props.info ? (
                <div
                    className={`${componentClassname}__info react-form__label-description`}
                    id={props.accessibilityIds.description}
                    dangerouslySetInnerHTML={{
                        __html: props.info,
                    }}
                ></div>
            ) : null}
            <div
                className={`${componentClassname}__input-container ${
                    props.icon ? "has-icon" : ""
                }`}
            >
                {/*input element*/}
                {React.cloneElement(props.inputElement, {
                    "aria-labelledby": props.label
                        ? props.accessibilityIds.label
                        : null,
                    "aria-describedby": props.info
                        ? props.accessibilityIds.description
                        : null,
                })}
            </div>
            <label htmlFor={props.accessibilityIds.input}>
                <span
                    href="#"
                    className={`${componentClassname}__browse-button cta cta--small cta--inline`}
                >
                    Browse
                </span>
                <span className={`${componentClassname}__filename`}>
                    {props.file.name || props.placeholder || "Upload your file"}
                    {props.file.size && (
                        <span className={`${componentClassname}__filesize`}>
                            ({prettyBytes(props.file.size)})
                        </span>
                    )}
                </span>
            </label>
            {/*errors*/}
            {hasErrors ? (
                <div className={`${componentClassname}__errors`}>
                    <div
                        className={`${componentClassname}__errors__item`}
                        id={props.accessibilityIds.error}
                    >
                        Error: {props.errors[0]}
                    </div>
                </div>
            ) : null}
        </div>
    );
};

export default FileUpload;
