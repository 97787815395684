import React from "react";
import DatePicker from "@modules/DatePicker";
import useClickOutside from "../../hooks/useClickOutside";
import { createPortal } from "react-dom";
const { useState, useRef } = React;

const parentClassname = "react-form";

const DatePickerField = props => {
    const [active, setActive] = useState(false);
    const [date, setDate] = useState(null);
    const datepickerRef = useRef();

    const componentClassname = parentClassname
        ? `${parentClassname}__field`
        : "field";

    const hasErrors =
        !props.hideErrors &&
        props.errors &&
        props.errors.length &&
        props.showErrors;

    const handleDatepickerSelect = newDate => {
        setActive(false);
        setDate(newDate);

        if (props.onChange) {
            // handle "DD/MM/YYYY" formating to create timestamp (required for validation)
            const dateParts = newDate.split("/");
            const dateObject = new Date(
                +dateParts[2],
                dateParts[1] - 1,
                +dateParts[0]
            );

            props.onChange({
                target: {
                    value: dateObject.getTime(),
                    name: props.name,
                },
            });

            // trigger blur to revalidate and show errors on change
            props.onBlur({
                target: {
                    name: props.name,
                },
            });
        }
    };

    const handleClearInput = e => {
        e.preventDefault();
        e.stopPropagation();

        //setDate(null);
        handleDatepickerSelect("");
    };

    const handleClickOutside = e => {
        if (active) {
            setActive(false);
        }
    };

    useClickOutside(datepickerRef, handleClickOutside);

    const handleInputClick = e => {
        e.preventDefault();
        e.stopPropagation();

        setActive(!active);
    };

    return (
        <div
            className={`${componentClassname} react-form__date-picker
				${componentClassname}--${props.type}
				${props.classes.inheritedClasses} 
				${props.classes.isDisabled} 
				${props.classes.isIncomplete} 
				${props.classes.isFocused} 
				${props.classes.hasValue}
				${props.classes.isValid}
                ${props.classes.isInvalid}
                ${props.checked ? "is-checked" : ""}
                ${props.placeholder ? "has-placeholder" : ""}
			`}
        >
            <label htmlFor={props.accessibilityIds.input}>
                {/*label*/}
                {props.label ? (
                    <div className={`${componentClassname}__label `}>
                        <div
                            className={`${componentClassname}__label__inner`}
                            id={props.accessibilityIds.label}
                        >
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: props.label,
                                }}
                            ></div>
                            {props.required ? null : !props.hideOptional ? (
                                <span
                                    className={`${componentClassname}__label__optional-label`}
                                ></span>
                            ) : null}
                        </div>
                    </div>
                ) : null}

                {/*info*/}
                {props.info ? (
                    <div
                        className={`${componentClassname}__info react-form__label-description`}
                        id={props.accessibilityIds.description}
                        dangerouslySetInnerHTML={{
                            __html: props.info,
                        }}
                    ></div>
                ) : null}

                <div
                    onClick={handleInputClick}
                    className={`${componentClassname}__input-container ${
                        props.icon ? "has-icon" : ""
                    }`}
                >
                    {/*input element*/}
                    {React.cloneElement(props.inputElement, {
                        "aria-labelledby": props.label
                            ? props.accessibilityIds.label
                            : null,
                        "aria-describedby": props.info
                            ? props.accessibilityIds.description
                            : null,
                        value: date || "",
                        disabled: true,
                        placeholder: props.placeholder,
                    })}
                    {props.classes.hasValue && (
                        <button
                            type="button"
                            className="react-form__date-picker__icon react-form__date-picker__icon--clear"
                            onClick={handleClearInput}
                            aria-label="Clear Datepicker"
                        >
                            <i className="icon icon-clear" />
                        </button>
                    )}

                    <button
                        type="button"
                        className="react-form__date-picker__icon react-form__date-picker__icon--calendar"
                        onClick={handleInputClick}
                        aria-label="Open Datepicker"
                    >
                        <i className="icon icon-calendar" />
                    </button>
                </div>
            </label>

            {/*errors*/}
            {hasErrors ? (
                <div className={`${componentClassname}__errors`}>
                    <div
                        className={`${componentClassname}__errors__item`}
                        id={props.accessibilityIds.error}
                    >
                        Error: {props.errors[0]}
                    </div>
                </div>
            ) : null}

            {/*info*/}
            {props.info ? (
                <div
                    className={`${componentClassname}__info`}
                    id={props.accessibilityIds.description}
                    dangerouslySetInnerHTML={{
                        __html: props.info,
                    }}
                ></div>
            ) : null}

            {/* Datepicker Modal/Module */}
            {createPortal(
                <div
                    className={`form-builder__datepicker-modal form-builder__datepicker-root ${
                        active ? "is-active" : ""
                    }`}
                >
                    <div
                        className={`form-builder__datepicker form-builder__datepicker--${props.name}`}
                        ref={datepickerRef}
                    >
                        <DatePicker
                            disableDays={props.disableDays}
                            disablePastDays={true}
                            disableDaysAfterDays={props.disableDaysAfterDays}
                            datepickerId={props.name}
                            debug={false}
                            onSelect={handleDatepickerSelect}
                        />
                    </div>
                </div>,
                document.querySelector("body")
            )}
        </div>
    );
};

export default DatePickerField;
