// @flow

import * as React from "react";
import {
    ReactForm,
    ReactFormGroup,
    ReactFormField,
} from "@adrenalin/react-form";

import DatePicker from "../components/formfields/DatePicker";
import TimePicker from "../components/formfields/TimePicker";
import AccordionGroup from "../components/molecules/AccordionGroup";

type PropsType = {};

const TenantLandingForm = (props: PropsType): React.Node => {
    return (
        <div className="form-builder">
            <ReactForm
                onSuccess={(fields: any): any => {
                    console.log("success", fields);
                }}
                onUpdate={(fields: any): any => {
                    console.log(fields);
                }}
                onError={(fields: any): any => {
                    console.log("error", fields);
                }}
                // validators={[]}
            >
                {/* Date picker */}
                <h2>Date Picker</h2>
                <ReactFormField
                    fieldComponent={DatePicker}
                    disableDays={["2021-08-25", "2021-08-27"]}
                    type="text"
                    name="date"
                    label="Date of filming"
                    placeholder="dd/mm/yyyy"
                    required={true}
                />

                {/* Time pickers */}
                <h2>Time Pickers</h2>
                <div className="row">
                    <div className="col-xs-6">
                        <ReactFormField
                            fieldComponent={TimePicker}
                            type="text"
                            name="start_time"
                            label="Start time*"
                            required={true}
                        />
                    </div>
                    <div className="col-xs-6">
                        <ReactFormField
                            fieldComponent={TimePicker}
                            type="text"
                            name="end_time"
                            label="End time*"
                            required={true}
                            validators={[">field:start_time"]}
                        />
                    </div>
                </div>

                {/* Accordion */}
                <h2>Accordion Checkboxes</h2>
                <ReactFormGroup
                    name="checkboxGroupTest"
                    label="Select your drinks"
                    info="Please select between to 2 to 4 drinks."
                    minCheckboxes="2"
                    maxCheckboxes="4"
                >
                    <AccordionGroup>
                        <AccordionGroup.Section>
                            <AccordionGroup.Title>
                                Darling Harbour Precinct
                            </AccordionGroup.Title>
                            <AccordionGroup.Content>
                                <div className="row">
                                    <ReactFormField
                                        className="col-xs-6"
                                        type="checkbox"
                                        name="coke"
                                        label={"Coke"}
                                    />

                                    <ReactFormField
                                        className="col-xs-6"
                                        type="checkbox"
                                        name="sprite"
                                        label={"Sprite"}
                                    />

                                    <ReactFormField
                                        className="col-xs-6"
                                        type="checkbox"
                                        name="fanta"
                                        label={"Fanta"}
                                    />

                                    <ReactFormField
                                        className="col-xs-6"
                                        type="checkbox"
                                        name="solo"
                                        label={"Solo"}
                                    />

                                    <ReactFormField
                                        className="col-xs-6"
                                        type="checkbox"
                                        name="pepsi"
                                        label={"Pepsi"}
                                    />
                                </div>
                            </AccordionGroup.Content>
                        </AccordionGroup.Section>

                        <AccordionGroup.Section>
                            <AccordionGroup.Title>
                                The Rocks Precinct
                            </AccordionGroup.Title>
                            <AccordionGroup.Content>
                                <div className="row">
                                    <ReactFormField
                                        className="col-xs-6"
                                        type="checkbox"
                                        name="coke_1"
                                        label={"Coke"}
                                    />

                                    <ReactFormField
                                        className="col-xs-6"
                                        type="checkbox"
                                        name="sprite_1"
                                        label={"Sprite"}
                                    />

                                    <ReactFormField
                                        className="col-xs-6"
                                        type="checkbox"
                                        name="fanta_1"
                                        label={"Fanta"}
                                    />

                                    <ReactFormField
                                        className="col-xs-6"
                                        type="checkbox"
                                        name="solo_1"
                                        label={"Solo"}
                                    />

                                    <ReactFormField
                                        className="col-xs-6"
                                        type="checkbox"
                                        name="pepsi_1"
                                        label={"Pepsi"}
                                    />
                                </div>
                            </AccordionGroup.Content>
                        </AccordionGroup.Section>

                        <AccordionGroup.Section>
                            <AccordionGroup.Title>
                                Pyrmont Precint
                            </AccordionGroup.Title>
                            <AccordionGroup.Content>
                                <div className="row">
                                    <ReactFormField
                                        className="col-xs-6"
                                        type="checkbox"
                                        name="coke_2"
                                        label={"Coke"}
                                    />

                                    <ReactFormField
                                        className="col-xs-6"
                                        type="checkbox"
                                        name="sprite_3"
                                        label={"Sprite"}
                                    />

                                    <ReactFormField
                                        className="col-xs-6"
                                        type="checkbox"
                                        name="fanta_4"
                                        label={"Fanta"}
                                    />

                                    <ReactFormField
                                        className="col-xs-6"
                                        type="checkbox"
                                        name="solo_5"
                                        label={"Solo"}
                                    />

                                    <ReactFormField
                                        className="col-xs-6"
                                        type="checkbox"
                                        name="pepsi_6"
                                        label={"Pepsi"}
                                    />
                                </div>
                            </AccordionGroup.Content>
                        </AccordionGroup.Section>
                    </AccordionGroup>
                </ReactFormGroup>

                <button
                    type="submit"
                    className="react-form__button cta cta--inline cta--big"
                >
                    Submit
                </button>
            </ReactForm>

            <br />
            <br />
        </div>
    );
};

export default TenantLandingForm;
