//@flow

class VideoPlayer {
    $el: HTMLElement;
    $video: HTMLVideoElement;
    $playToggleBtn: HTMLElement;

    constructor(props: any) {
        this.$el = props;
        this.$video = this.$el ? this.$el.querySelector("video") : false;
        this.$playToggleBtn = this.$el
            ? this.$el.querySelector("[data-inline-video-play-toggle]")
            : false;

        // startup
        this.init();

        // events
        this.attachEvents();
    }

    init() {}

    attachEvents() {
        // Video Events
        if (this.$video) {
            // play
            this.$video.addEventListener(
                "play",
                this.handleVideoPlay.bind(this)
            );

            // pause
            this.$video.addEventListener(
                "pause",
                this.handleVideoPause.bind(this)
            );
        }

        // Play/Pause Toggle btn
        if (this.$playToggleBtn) {
            // click
            this.$playToggleBtn.addEventListener(
                "click",
                this.togglePlayPause.bind(this)
            );
        }
    }

    handleVideoPlay() {
        this.$el.classList.add("has-started");
        this.$el.classList.remove("is-paused");
    }

    handleVideoPause() {
        this.$el.classList.add("is-paused");
    }

    togglePlayPause() {
        if (this.$video) {
            if (!this.$video.paused) {
                this.pausePlaying();
            } else {
                this.startPlaying();
            }
        }
    }

    startPlaying() {
        if (this.$video) this.$video.play();
    }

    pausePlaying() {
        if (this.$video) this.$video.pause();
    }
}

export default VideoPlayer;
