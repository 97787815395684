// @flow

import React from "react";
import _ from "lodash";
import { Accordion } from "semantic-ui-react";
import AnimateHeight from "../atoms/AnimateHeight";

const { useState } = React;

const AccordionContext = React.createContext();

const AccordionGroup = (props: any): any => {
    const itemsFromProps = [];
    props.children.forEach((child: any, index: number): any => {
        itemsFromProps.push(index === 0 ? false : false);
    });

    const [activeIndex, setActiveIndex] = useState({});
    const [items, setItems] = useState(itemsFromProps);

    const handleClick = (newItemIndex: number) => {
        const newItems = _.cloneDeep(items);
        newItems[newItemIndex] = newItems[newItemIndex] ? false : true;

        setItems(newItems);
        setActiveIndex(newItemIndex);
    };

    return (
        <Accordion className="accordion-group accordion">
            {props.children.map((child: any, index: any): any => {
                return (
                    <AccordionContext.Provider
                        key={index}
                        value={{
                            handleSectionClick: handleClick,
                            items: items,
                            index: index,
                            activeIndex: activeIndex,
                        }}
                    >
                        {child}
                    </AccordionContext.Provider>
                );
            })}
        </Accordion>
    );
};
export default AccordionGroup;

class AccordionGroupSection extends React.Component {
    static contextType = AccordionContext;

    render(): any {
        return (
            <div
                className="accordion-group__section accordion__section"
                key={this.context.index}
            >
                {this.props.children}
            </div>
        );
    }
}
AccordionGroup.Section = AccordionGroupSection;

class AccordionGroupTitle extends React.Component {
    static contextType = AccordionContext;

    render(): any {
        return (
            <Accordion.Title
                className="accordion__section__label heading"
                index={this.context.index}
                active={this.context.items[this.context.index]}
                onClick={(): any =>
                    this.context.handleSectionClick(this.context.index)
                }
            >
                {this.props.children}
                <i
                    aria-hidden="true"
                    className="accordion__section__label__indicator"
                ></i>
            </Accordion.Title>
        );
    }
}
AccordionGroup.Title = AccordionGroupTitle;

class AccordionGroupContent extends React.Component {
    static contextType = AccordionContext;

    render(): any {
        return (
            <AnimateHeight
                className="accordion__section__content"
                duration={500}
                hideOverflow
                height={this.context.items[this.context.index] ? null : 0}
            >
                <Accordion.Content
                    className="accordion__section__content__pad"
                    index={this.context.index}
                    active={this.context.items[this.context.index]}
                >
                    {this.props.children}
                </Accordion.Content>
            </AnimateHeight>
        );
    }
}
AccordionGroup.Content = AccordionGroupContent;
