// @flow

import _ from "lodash";

// returns attributes as props from an element with data attributes
const getPropsFromElement = (selector: string, $el: any): any => {
    const attributePrefix = selector.substring(1, selector.length - 1) + "-";
    let props = {};

    if ($el) {
        for (let attrIdx = 0; attrIdx < $el.attributes.length; attrIdx++) {
            const attribute = $el.attributes[attrIdx];
            if (attribute.name.indexOf(attributePrefix) === 0) {
                props[
                    _.camelCase(attribute.name.replace(attributePrefix, ""))
                ] = attribute.value;
            }
        }
        return props;
    } else {
        console.error("No html element was provided.");
    }
};

export default getPropsFromElement;
