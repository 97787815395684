// @flow

import { pubsub } from "@utils";
import Config from "@Config";

export const initMapApi = new Promise((resolve: any, reject: any) => {
    if (window.googleMapsLoaded) {
        resolve();
    }

    // timeout reject
    let requestTimeout = setTimeout(() => {
        reject("Google Maps API timed out.");
    }, Config.fetchTimeout);

    // loaded resolve
    window.googleMapsLoaded = () => {
        clearTimeout(requestTimeout);
        window.googleMapsLoaded = true;
        pubsub.publish("googleMapsAPILoaded");
        resolve();
    };

    // async script include
    let gMapsJs = document.createElement("script");
    gMapsJs.type = "text/javascript";
    gMapsJs.async = true;
    gMapsJs.src = `https://maps.googleapis.com/maps/api/js?key=${Config.googleMapsKey}&libraries=places,geometry&callback=window.googleMapsLoaded`;
    if (document && document.body) document.body.appendChild(gMapsJs);
});
