// @flow

import React, { Component } from "react";
import { createPortal } from "react-dom";
import { CSSTransition } from "react-transition-group";
import { createUniqueID } from "@utils";

type PropsType = {
    className?: string,
    active?: boolean,
    fixed?: boolean,
    absolute?: boolean,
    solid?: boolean,
    delay?: number,
    transparent?: boolean,
    dark?: boolean,
};

const bodySelector = document.querySelector("body");

class Loader extends Component<PropsType> {
    uniqueId = createUniqueID();

    render(): any {
        const activeClass = this.props.active ? "is-active" : "";
        const fixedClass = this.props.fixed ? "loader-cover--fixed" : "";
        const absoluteClass = this.props.absolute
            ? "loader-cover--absolute"
            : "";
        const solidClass = this.props.solid ? "loader-cover--solid" : "";
        const transparentClass = this.props.transparent
            ? "loader-cover--transparent"
            : "";
        const darkClass = this.props.dark ? "loader-cover--dark" : "";
        const delay = this.props.delay || 0;

        const loaderMarkup = (
            <CSSTransition
                key={this.uniqueId}
                in={this.props.active}
                timeout={1000}
                classNames="fade"
                mountOnEnter
                unmountOnExit
            >
                <div
                    key={this.uniqueId}
                    className={`loader-cover ${fixedClass} ${absoluteClass} ${solidClass} ${darkClass} ${transparentClass} ${activeClass} ${
                        this.props.className ? this.props.className : ""
                    }`}
                >
                    <div
                        className="loader-cover__loader loader"
                        style={{
                            animationDelay: `${delay}ms`,
                        }}
                    >
                        <svg viewBox="25 25 50 50">
                            <circle
                                cx="50"
                                cy="50"
                                r="20"
                                fill="none"
                                strokeWidth="2"
                                strokeMiterlimit="10"
                            />
                        </svg>
                    </div>

                    {this.props.content ? (
                        <div className="loader-cover__content">
                            {this.props.content}
                        </div>
                    ) : null}
                </div>
            </CSSTransition>
        );

        if (this.props.fixed && bodySelector) {
            return createPortal(loaderMarkup, bodySelector);
        } else {
            return loaderMarkup;
        }
    }
}

export default Loader;
