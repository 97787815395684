import React from "react";
import _ from "lodash";
const { useState } = React;

const parentClassname = "react-form";

const TextField = props => {
    const componentClassname = parentClassname
        ? `${parentClassname}__field`
        : "field";

    const labelPlaceholderClassname = props.labelplaceholder
        ? `${componentClassname}__label--labelplaceholder`
        : "";

    const hasErrors =
        !props.hideErrors &&
        props.errors &&
        props.errors.length &&
        props.showErrors;

    const [activeSuggestion, setActiveSuggestion] = useState(-1);

    const onKeyDown = e => {
        if (!props.autoSuggestion) {
            return;
        }
        // User pressed the enter key, update the input and close the
        // suggestions
        if (e.keyCode === 13 && activeSuggestion > -1) {
            props.onSuggestion &&
                props.onSuggestion(props.suggestions[activeSuggestion]);
            setActiveSuggestion(-1);
            e.preventDefault();
        }
        // User pressed the up arrow, decrement the index
        else if (e.keyCode === 38) {
            e.preventDefault();
            if (activeSuggestion === 0) {
                return;
            }
            setActiveSuggestion(activeSuggestion - 1);
        }
        // User pressed the down arrow, increment the index
        else if (e.keyCode === 40 || e.keyCode === 9) {
            if (
                activeSuggestion === props.suggestions.length - 1 ||
                props.suggestions.length === 0
            ) {
                return;
            }
            e.preventDefault();
            setActiveSuggestion(activeSuggestion + 1);
        } else {
            setActiveSuggestion(-1);
        }
    };

    return (
        <div
            className={`${componentClassname}
				${componentClassname}--${props.type}
				${props.classes.inheritedClasses}
				${props.classes.isDisabled}
				${props.classes.isIncomplete}
				${props.classes.isFocused}
				${props.classes.hasValue}
				${props.classes.isValid}
                ${props.classes.isInvalid}
                ${props.checked ? "is-checked" : ""}
                ${props.placeholder ? "has-placeholder" : ""}
			`}
        >
            <label htmlFor={props.accessibilityIds.input}>
                {/*label*/}
                {props.label ? (
                    <div
                        className={`${componentClassname}__label  ${labelPlaceholderClassname}`}
                    >
                        <div
                            className={`${componentClassname}__label__inner`}
                            id={props.accessibilityIds.label}
                        >
                            {props.label}
                            {props.required ? null : !props.hideOptional ? (
                                <span
                                    className={`${componentClassname}__label__optional-label`}
                                ></span>
                            ) : null}
                        </div>
                    </div>
                ) : null}
                <div
                    className={`${componentClassname}__input-container ${
                        props.icon ? "has-icon" : ""
                    }`}
                    {...(props.autoSuggestion
                        ? {
                              role: "combobox",
                              "aria-expanded": !!(
                                  props.suggestions &&
                                  props.suggestions.length > 0
                              ),
                              "aria-owns":
                                  props.accessibilityIds.input + "-suggestions",
                              "aria-haspopup": "listbox",
                          }
                        : {})}
                >
                    {props.icon ? (
                        <label
                            htmlFor={props.accessibilityIds.input}
                            className={`${componentClassname}__input-icon`}
                        >
                            <i className={`icon icon-${props.icon}`}></i>
                        </label>
                    ) : null}
                    {/*input element*/}
                    {React.cloneElement(
                        props.inputElement,
                        _.assign(
                            {
                                "aria-labelledby": props.label
                                    ? props.accessibilityIds.label
                                    : null,
                                "aria-describedby": props.info
                                    ? props.accessibilityIds.description
                                    : null,
                                "aria-activedescendant":
                                    activeSuggestion > -1
                                        ? "result-item-" + activeSuggestion
                                        : "",
                                onKeyDown: onKeyDown,
                                // onKeyUp: onKeyUp,
                            },
                            props.autoSuggestion
                                ? {
                                      "aria-autocomplete": "list",
                                      "aria-controls": "ex2-listbox",
                                  }
                                : {}
                        )
                    )}
                    {/*Datepicker toggle btn*/}
                    {props.hasDatepicker ? (
                        <div
                            className={`${componentClassname}__datepicker-icon`}
                            onClick={props.datepickerOnClickHandler}
                        >
                            DP
                        </div>
                    ) : null}
                    {/*password toggle btn*/}
                    {props.passwordToggleBtn}
                    {/*checkbox indicator*/}
                    {props.type === "checkbox" ? (
                        <div
                            className={`${componentClassname}__checkbox-indicator`}
                        />
                    ) : null}
                    {/*radio indicator*/}
                    {props.type === "radio" ? (
                        <div
                            className={`${componentClassname}__radio-indicator`}
                        />
                    ) : null}
                    {/*seelct indicator*/}
                    {props.type === "select" ? (
                        <div
                            className={`${componentClassname}__select-indicator`}
                        />
                    ) : null}
                    {/* Error icon */}
                    {hasErrors ? (
                        <div className={`${componentClassname}__error-icon`}>
                            <i className="icon icon-alert-triangle"></i>
                        </div>
                    ) : null}
                </div>
                {props.suggestions && props.suggestions.length > 0 && (
                    <ul
                        id={props.accessibilityIds.input + "-suggestions"}
                        {...{
                            "aria-labelledby": props.label
                                ? props.accessibilityIds.label
                                : null,
                            role: "listbox",
                        }}
                        className={`${componentClassname}__suggestions-list`}
                    >
                        {props.suggestions.map((suggestion, index) => (
                            <li
                                key={suggestion.key}
                                className={`${componentClassname}__suggestion-button result ${
                                    index === activeSuggestion ? "active" : ""
                                }`}
                                role="option"
                                aria-selected={index === activeSuggestion}
                                id={"result-item-" + index}
                                onClick={() => {
                                    props.onSuggestion &&
                                        props.onSuggestion(suggestion);
                                }}
                            >
                                {suggestion.text}
                            </li>
                        ))}
                    </ul>
                )}
            </label>

            {/*errors*/}
            {hasErrors ? (
                <div className={`${componentClassname}__errors`}>
                    <div
                        className={`${componentClassname}__errors__item`}
                        id={props.accessibilityIds.error}
                    >
                        Error: {props.errors[0]}
                    </div>
                </div>
            ) : null}

            {/*info*/}
            {props.info ? (
                <div
                    className={`${componentClassname}__info`}
                    id={props.accessibilityIds.description}
                    dangerouslySetInnerHTML={{
                        __html: props.info,
                    }}
                ></div>
            ) : null}
        </div>
    );
};

export default TextField;
