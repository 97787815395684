// @flow

import React, { Component } from "react";
import { CSSTransition } from "react-transition-group";
// import { ReactForm, ReactFormField } from "@adrenalin/react-form";
import createUniqueID from "@utils/createUniqueID";

type PropsType = {
    children: any,
    active: boolean,
    title: string,
    onClose: any,
    resetHandler: any,
};
class PrecinctMapFilters extends Component<PropsType> {
    uniqueId = createUniqueID();

    render(): any {
        return (
            <CSSTransition
                key={this.uniqueId}
                in={this.props.active}
                timeout={1000}
                classNames={"slideInLeft"}
                mountOnEnter
                unmountOnExit
            >
                <div className={`precinct-map__modal__pane`}>
                    <div className="precinct-map__modal__header">
                        <i
                            aria-hidden="true"
                            className="precinct-map__modal__header__icon icon icon-filter-vertical"
                        />

                        <div className="precinct-map__modal__header__title">
                            {this.props.title}
                        </div>

                        <button
                            type="button"
                            className="precinct-map__modal__header__close icon icon-close"
                            onClick={this.props.onClose}
                        ></button>
                    </div>

                    <div className="precinct-map__modal__body">
                        {this.props.children}
                    </div>

                    <div className="precinct-map__modal__footer">
                        {/*TODO - reset filters button*/}
                        <button
                            type="button"
                            className="precinct-map__filter-reset-btn"
                            onClick={this.props.resetHandler}
                        >
                            <i className="icon icon-reset" />
                            Reset
                        </button>
                        <button
                            type="button"
                            className="cta"
                            onClick={this.props.onClose}
                        >
                            Done
                        </button>
                    </div>
                </div>
            </CSSTransition>
        );
    }
}

export default PrecinctMapFilters;
