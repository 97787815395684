// @flow

import React from "react";
import ReactDOM from "react-dom";
import getPropsFromElement from "@utils/getPropsFromElement";

// Selector/Module initializer
const moduleInitializer = (
    selector: string, // selectors should use the data attribute selector convention. e.g. [data-live-search]
    module: any,
    isReactComponent?: boolean
) => {
    const $elements = document.querySelectorAll(selector);
    if ($elements) {
        $elements.forEach(function($el: HTMLElement) {
            if (isReactComponent) {
                // react component

                //const src = $el.getAttribute("src");
                const classList = $el.getAttribute("class") || "";
                let props = getPropsFromElement(selector, $el);
                const $renderEl = document.createElement("div");
                $renderEl.classList.add(classList);
                const Module = module;

                if ($el.parentNode) {
                    $el.parentNode.insertBefore($renderEl, $el.nextSibling);
                    $el.remove();
                    ReactDOM.render(<Module {...props} />, $renderEl);
                }
            } else {
                // vanilla js module
                new module($el);
            }
        });
    }
};

export default moduleInitializer;
