//@flow

import Glide from "@glidejs/glide";
import YoutubePlayer from "@modules/YoutubePlayer";
import Lightbox from "@modules/Lightbox";

class Carousel {
    $el: HTMLElement;
    $lightboxCarouselEl: HTMLElement;
    $pagination: ?HTMLElement;
    $gutter: ?HTMLElement;
    $slides: NodeList<HTMLElement>;
    $captions: NodeList<HTMLElement>;
    $buttons: NodeList<HTMLElement>;
    $triggerTarget: ?HTMLElement;
    id: string;
    GlideCarousel: any;
    youtubePlayers: Array<{}>;
    inLightbox: boolean;
    carouselLightbox: any;
    lightboxCarousel: any;
    startAt: number;
    carouselInteration: boolean;
    animationDuration: number;

    constructor(props: any) {
        this.$el = props.element;
        this.startAt = props.startAt;
        this.id = this.$el.getAttribute("data-carousel-id") || "";

        this.youtubePlayers = [];
        this.inLightbox = this.$el.closest(".lightbox") ? true : false;
        this.$pagination = this.$el.querySelector("[data-carousel-pagination]");
        this.$slides = this.$el.querySelectorAll("[data-carousel-slide]");
        this.$captions = this.$el.querySelectorAll("[data-carousel-caption]");
        this.$buttons = this.$el.querySelectorAll("[data-carousel-direction]");
        this.$gutter = this.$el.querySelector("[data-carousel-gutter]");
        this.$lightboxCarouselEl = this.$el.cloneNode(true);
        this.$triggerTarget = document.querySelector(
            `[data-for-gallery-id="${this.id}"]`
        );
        this.animationDuration = 300;

        // startup
        this.init();

        // events
        this.attachEvents();
    }

    init() {
        // init GlideJS carousel
        this.GlideCarousel = new Glide(this.$el, {
            startAt: this.startAt || 0,
            animationDuration: this.inLightbox ? 5 : this.animationDuration,
            perView: 1,
            gap: 0,
            peek: this.inLightbox ? 0 : this.getPeakAmount(),
            animationTimingFunc: "ease-out",
        });

        this.GlideCarousel.on("build.after", () => {
            if (window.lazyLoad) {
                window.lazyLoad.update();
                window.lazyLoad.loadAll();
            }
        });

        this.GlideCarousel.mount();

        // set pagination
        this.setPagination();

        // set first caption
        this.setActiveCaption();
    }

    attachEvents() {
        // on resize adjust the "peek" amount
        window.addEventListener("resize", (): any => {
            this.GlideCarousel.update({
                peek: this.inLightbox ? 0 : this.getPeakAmount(),
            });
        });
        const $expand = this.$el.querySelector("[data-carousel-expand]");

        if ($expand) {
            $expand.addEventListener("click", (event: any) => {
                this.handleSlideClick(event, this.GlideCarousel.index);
            });
        }

        this.GlideCarousel.on("run", (move: any): any => {
            this.carouselInteration = true;
        });
        this.GlideCarousel.on("move.after", (move: any): any => {
            this.carouselInteration = false;
        });

        // on GlideCarousel change slide
        this.GlideCarousel.on("run", (move: any): any => {
            // set pagination
            this.setPagination();
            // set first caption
            this.setActiveCaption();
            // pause videos
            this.pauseVideos();
        });

        // attach direction buttons to glide
        this.$buttons.forEach((button: any, index: number) => {
            button.addEventListener("click", (event: any): any => {
                this.GlideCarousel.go(
                    button.getAttribute("data-carousel-direction")
                );
            });
        });

        // Youtube Iframe API loaded event
        this.$slides.forEach((slide: any, index: number) => {
            this.setupYoutubeSlide(slide, index);
        });

        // slide lightbox trigger
        this.$slides.forEach(($slide: any, index: number) => {
            $slide.addEventListener("click", (event: any): any => {
                this.handleSlideClick(event, index);
            });
        });

        if (this.$triggerTarget && this.$triggerTarget.addEventListener) {
            this.$triggerTarget.addEventListener("click", (event: any): any => {
                this.handleSlideClick(event, 0);
            });
        }
    }

    handleSlideClick(event: any, index: number) {
        if (!this.inLightbox && !this.carouselInteration) {
            // launch lightbox
            this.setupLightbox(index);
        }
    }

    setupYoutubeSlide($youtubeEl: HTMLElement, index: number) {
        const youtubeId = $youtubeEl.getAttribute(
            "data-carousel-slide-youtube"
        );

        if (youtubeId) {
            this.youtubePlayers.push(
                new YoutubePlayer({
                    element: $youtubeEl,
                    id: youtubeId,
                    inLightbox: this.inLightbox,
                    loop: true,
                    autoplay:
                        index === this.GlideCarousel.index && this.inLightbox,
                })
            );
        }
    }

    getNumberOfSlides(): number {
        return this.GlideCarousel._c.Html.slides.length;

        // return this.$el.querySelectorAll("[data-carousel-slide]").length;
    }

    getPeakAmount(): {} {
        return {
            before: 0,
            after: this.$gutter ? this.$gutter.clientWidth : 10,
        };
    }

    setActiveCaption() {
        const $activeCaption = this.$captions[this.GlideCarousel.index];

        // remove active classes
        this.$captions.forEach((el: any) => {
            el.classList.remove("is-active");
        });

        // set active caption
        if ($activeCaption) {
            $activeCaption.classList.add("is-active");
        }
    }

    setPagination() {
        const paginationString = `${this.GlideCarousel.index +
            1} of ${this.getNumberOfSlides()}`;

        if (this.$pagination) {
            this.$pagination.innerHTML = paginationString;
        }
    }

    pauseVideos() {
        this.youtubePlayers.forEach((youtubeVideo: any) => {
            if (youtubeVideo.player && youtubeVideo.player.pauseVideo) {
                youtubeVideo.player.pauseVideo();
            }
        });
    }

    setupLightbox(activeSlideIndex: number) {
        // create the ligthtbox
        this.carouselLightbox = new Lightbox({
            contents: this.$lightboxCarouselEl,
            carousel: true,
            carouselStartAt: activeSlideIndex,
            onClose: (lastIndex: number) => {
                // set the index of the inline carousel from the lightbox one
                this.GlideCarousel.update({
                    animationDuration: 0,
                }).go(`=${lastIndex}`);

                setTimeout(() => {
                    this.GlideCarousel.update({
                        animationDuration: this.animationDuration,
                    });
                });
            },
        });
    }
}

export default Carousel;
