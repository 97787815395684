// @flow
import { scrollTo } from "@utils/";

class PageController {
    $el: HTMLElement;
    $links: NodeList<HTMLElement>;

    constructor(el: HTMLElement) {
        this.$el = el;
        this.$links = this.$el.querySelectorAll("a[href]");

        //this.init();
        this.attachLinkEvents();
    }

    init() {
        // TODO Scrolling on Pageload works, but calculating the position by greensocks is (probably) too early,
        // before images etc are loaded, this causes the scroll to not go all the way to the desired position.
        if ("scrollRestoration" in window.history) {
            window.history.scrollRestoration = "manual";
        }
        this.checkHasOnPageLoad();
    }

    checkHasOnPageLoad() {
        const hash = window.location.hash || "#nonexistenthash";
        const $target = this.$el.querySelector(`${hash}`);

        if (hash !== "" && $target) {
            this.scrollToTarget($target);
        }
    }

    attachLinkEvents() {
        const regex = /^#([\w\d-_]+)$/i;
        this.$links.forEach(($link: HTMLElement) => {
            const target = $link.getAttribute("href") || "";
            if (target.match(regex)) {
                const $target = this.$el.querySelector(`${target}`);
                if ($target) {
                    $link.addEventListener("click", (e: any) => {
                        this.scrollToTarget($target);
                        e.preventDefault();
                    });
                }
            }
        });
    }

    scrollToTarget($target: HTMLElement) {
        scrollTo($target, 30, 1000);
    }
}

export default PageController;
