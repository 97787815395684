class Scroller {
    constructor(el) {
        this.$el = el;
        this.$inner = this.$el.querySelector("[data-scroller-inner]");
        this.$wrapper = this.$el.querySelector("[data-scroller-wrapper]");
        this.$items = this.$el.querySelectorAll("[data-scroller-item]");
        this.$prevBtn = this.$el.querySelector("[data-scroller-prev]");
        this.$nextBtn = this.$el.querySelector("[data-scroller-next]");
        this.$links = this.$inner.querySelectorAll("a");

        this.itemMargin = 20;
        this.isDown = false;
        this.startX = 0;
        this.scrollLeft = 0;
        this.mouseMove = 0;

        this.init();
    }

    init() {
        this.$wrapper.style.overflow = "hidden";

        // stop links from firing if drag scrolling
        this.$links.forEach(link => {
            link.addEventListener("click", e => {
                if (e.pageX !== this.mouseMove) {
                    e.preventDefault();
                } else {
                }
            });
        });

        this.$inner.addEventListener("mousedown", e => {
            this.mouseMove = e.pageX;
            this.isDown = true;
            this.$inner.classList.add("active");
            this.startX = e.pageX - this.$inner.offsetLeft;
            this.scrollLeft = this.$inner.scrollLeft;
        });

        this.$inner.addEventListener("mouseleave", () => {
            this.isDown = false;
            this.$inner.classList.remove("active");
        });

        this.$inner.addEventListener("mouseup", () => {
            this.isDown = false;
            this.$inner.classList.remove("active");
        });

        this.$inner.addEventListener("mousemove", e => {
            if (!this.isDown) return;
            e.preventDefault();
            const x = e.pageX - this.$inner.offsetLeft;
            const walk = (x - this.startX) * 3; //scroll-fast
            this.$inner.scrollLeft = this.scrollLeft - walk;
        });

        // prev nav
        this.$prevBtn.addEventListener("click", e => {
            const itemWidth = this.$items[0].clientWidth;
            // this.$inner.scrollLeft = this.$inner.scrollLeft - itemWidth;
            this.$inner.scrollTo({
                left: this.$inner.scrollLeft - itemWidth - this.itemMargin,
                behavior: "smooth",
            });
        });

        // prev nav
        this.$nextBtn.addEventListener("click", e => {
            const itemWidth = this.$items[0].clientWidth;
            // this.$inner.scrollLeft = this.$inner.scrollLeft + itemWidth;
            this.$inner.scrollTo({
                left: this.$inner.scrollLeft + itemWidth + this.itemMargin,
                behavior: "smooth",
            });
        });

        this.animLoop();
    }

    animLoop() {
        const contentHeight = this.$inner.clientHeight;
        // const wrapperHeight = this.$wrapper.clientHeight;

        if (this.$wrapper && contentHeight) {
            this.$wrapper.style.height = `${contentHeight}px`;
        }

        requestAnimationFrame(() => {
            this.animLoop();
        });
    }
}

export default Scroller;
