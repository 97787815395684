// @flow

import { responsive } from "@utils/";
import { cookie } from "@utils/";

class SiteNotification {
    $el: HTMLElement;
    $primaryNav: HTMLElement;
    $closeButton: ?HTMLElement;
    prevTop: number;
    uniqueId: string;

    constructor(el: HTMLElement) {
        this.$el = el;
        this.$primaryNav = window.document.querySelector(".primary-nav");
        this.uniqueId =
            this.$el.getAttribute("data-site-notification-id") || "";
        this.$closeButton = this.$el.querySelector(
            "[data-site-notification-close]"
        );
        this.prevTop = 0;
        // startup
        this.init();

        // events
        this.attachEvents();
    }

    init() {
        window.utilcookie = cookie;
        if (!cookie.get(`notification-${this.uniqueId}`)) {
            this.animLoop();
            this.$el.classList.add("site-notification--show");
        }
    }

    attachEvents() {
        if (this.$closeButton) {
            this.$closeButton.addEventListener("click", (event: any): any => {
                this.closeNotification();
            });
        }
    }

    closeNotification() {
        cookie.set(`notification-${this.uniqueId}`, true, 1);
        this.$el.classList.remove("site-notification--show");
    }

    animLoop() {
        let offset = 0;
        if (!responsive.isMobileBreakpoint()) {
            // $FlowFixMe
            offset = Math.max(this.$el.getBoundingClientRect().bottom, 0);
        }
        if (this.prevTop !== offset) {
            this.$primaryNav.style.top = `${offset}px`;
            this.prevTop = offset;
        }

        requestAnimationFrame(() => {
            this.animLoop();
        });
    }
}

export default SiteNotification;
