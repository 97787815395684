// @flow

//import { get } from "./helpers";
//import Config from "../constants/Config";
import _ from "lodash";

export const getAvailableDates = async (year: number): Promise<any> => {
    // Mocking API with randomly generated Dates for given year (ignores if dates are valid, e.g. 2019/03/31)
    const response = {};

    const getRandomInt = (min: number, max: number): number => {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min)) + min;
    };

    for (let month = 1; month <= 12; month++) {
        const randomDates = [];
        const lpadMonth = _.padStart(month, 2, "0");
        for (let i = 1; i <= 31; i++) {
            const lpadDay = _.padStart(i, 2, "0");
            const randomDate = `${year}-${lpadMonth}-${lpadDay}`;
            randomDates.push(randomDate);
        }

        response[`${year}-${lpadMonth}`] = _.uniq(randomDates.sort());
    }
    // Returns a delayed promise
    return new Promise((resolve: any, reject: any) => {
        setTimeout(() => {
            resolve(response);
        }, getRandomInt(100, 1000));
    });

    //return get(`${Config.datepickerAPI}/${year}`);
};

export default {
    getAvailableDates,
};
