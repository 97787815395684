// @flow
import DatePicker from "@modules/DatePicker";
import { postFormData, getFormStructure } from "../api/formbuilder";
// import ReactFormBuilder from "@modules/ReactFormBuilder";
import React from "react";
import ReactDOM from "react-dom";
import _ from "lodash";
import { scrollTo } from "@utils/";
import PubSub from "../utils/pubsub";
import Loader from "../components/atoms/Loader";

let ReactFormBuilder;
const loaderPortal = document.querySelector(".loader-portal");

class FormBuilder {
    $el: HTMLElement;
    structureApiUrl: string;
    isPageType: boolean;
    tenantEmail: string;
    tenantGuid: string;
    nodeGuid: string;
    submissionApiUrl: string;
    formName: string;
    recaptchaSiteKey: string;
    hideGoogleRecaptcha: boolean;
    $reactRoot: HTMLElement;
    $datepickerRoot: HTMLElement;
    $thankYou: HTMLElement;
    $serverError: HTMLElement;
    thankYouUrl: ?string;
    cancelUrl: ?string;
    submitText: ?string;
    cancelText: ?string;
    rawFormStructure: Object;
    formStructure: Object;

    constructor(el: HTMLElement) {
        this.$el = el;
        this.$reactRoot =
            this.$el.querySelector("[data-form-builder-react-root]") ||
            document.createElement("div");
        this.$datepickerRoot =
            this.$el.querySelector("[data-form-builder-datepicker-root]") ||
            document.createElement("div");
        this.$serverError =
            this.$el.querySelector("[data-form-builder-server-error]") ||
            document.createElement("div");
        this.$thankYou =
            this.$el.querySelector("[data-form-builder-thank-you]") ||
            document.createElement("div");
        this.formName =
            this.$el.getAttribute("data-form-builder-form-name") || "";
        this.structureApiUrl =
            this.$el.getAttribute("data-form-builder-structure-api-url") || "";
        this.isPageType =
            this.$el.getAttribute("data-form-builder-is-page-type") === "true";
        this.tenantEmail =
            this.$el.getAttribute("data-form-builder-tenant-email") || "";
        this.tenantGuid =
            this.$el.getAttribute("data-form-builder-tenant-guid") || "";
        this.nodeGuid =
            this.$el.getAttribute("data-form-builder-node-guid") || "";
        this.submissionApiUrl = "";
        this.recaptchaSiteKey =
            this.$el.getAttribute("data-form-builder-recaptcha-site-key") || "";

        this.init();
        this.attachEvents();
    }

    init() {
        import("@modules/ReactFormBuilder").then((module: any): any => {
            ReactFormBuilder = module.default;

            getFormStructure(
                this.structureApiUrl,
                this.formName,
                this.isPageType,
                this.tenantGuid,
                this.nodeGuid
            )
                .then((json: Object) => {
                    if (json.FormSubmissionUrl) {
                        this.submissionApiUrl = json.FormSubmissionUrl;
                    }
                    if (json.ThankyouUrl) {
                        this.thankYouUrl = json.ThankyouUrl;
                    }
                    this.cancelUrl = json.CancelUrl || "";
                    this.submitText = json.SubmitButtonText || "";
                    this.cancelText = json.CancelButtonText || "";
                    this.hideGoogleRecaptcha =
                        json.HideGoogleRecaptcha || false;
                    this.formStructure = this.buildFormStructure(
                        json.Sections,
                        json.Steps
                    );
                })
                .catch((e: any) => {
                    console.error(e);
                });
        });
    }

    attachEvents() {
        PubSub.subscribe(`datepicker`, (data: any) => {
            if (data.type === "openLightbox") {
                this.showDatepicker(data.id);
            } else if (data.type === "dateSelection") {
                this.closeDatepicker();
            }
        });
        this.$datepickerRoot.addEventListener("click", (event: any) => {
            const $currentDatepicker = this.$datepickerRoot.querySelector(
                ".form-builder__datepicker.is-active"
            );
            if (
                $currentDatepicker &&
                !$currentDatepicker.contains(event.target)
            ) {
                this.closeDatepicker();
            }
        });
    }

    addDatepicker(fieldName: string) {
        const $datepicker = document.createElement("div");
        $datepicker.setAttribute(
            "class",
            `form-builder__datepicker form-builder__datepicker--${fieldName}`
        );
        ReactDOM.render(
            <DatePicker
                disablePastDays={true}
                datepickerId={fieldName}
                debug={false}
            />,
            $datepicker
        );
        this.$datepickerRoot.appendChild($datepicker);
    }

    buildFormStructure(sections: Object, steps: Object) {
        let formStructure = [];
        for (const sectionObject of sections) {
            let sectionStructure = [];
            for (const field of sectionObject.Fields) {
                sectionStructure.push(this.kenticoFieldMapper(field));
            }
            sectionStructure = this.mergeCheckboxFields(sectionStructure);
            formStructure.push({
                title: sectionObject.Title,
                fields: sectionStructure,
            });
        }

        this.buildReactForm(formStructure, steps);
    }

    processFormData(formDataObject: any) {
        formDataObject.append("FormName", this.formName);
        if (this.isPageType) {
            formDataObject.append("TenantEmail", this.tenantEmail);
            formDataObject.append("TenantGuid", this.tenantGuid);
            formDataObject.append("NodeGuid", this.nodeGuid);
        }
        this.postFormData(formDataObject);
    }

    postFormData(data: Object) {
        // show full page spinner loader
        this.showLoader();

        postFormData(this.submissionApiUrl, data)
            .then((response: any): Promise => {
                if (!response.ok) {
                    return Promise.reject(response);
                }
                return response.json();
            })
            .then((json: any) => {
                // hide error
                this.$serverError.innerHTML = "";
                this.$serverError.classList.remove("is-active");

                // hide loader
                this.hideLoader();

                if (this.thankYouUrl) {
                    if (this.isPageType && json.NodeGuid) {
                        window.location.href =
                            this.thankYouUrl + `&nodeGuid=${json.NodeGuid}`;
                    } else {
                        window.location.href = this.thankYouUrl;
                    }
                } else {
                    this.$reactRoot.style.display = "none";
                    this.$thankYou.style.display = "flex";
                    scrollTo(this.$thankYou, 30, 1000);
                }
            })
            .catch((error: any): any => {
                // hide loader
                this.hideLoader();

                if (typeof error.json === "function") {
                    error
                        .json()
                        .then((jsonError: any) => {
                            console.error("Json error from API", jsonError);
                            if (jsonError.Message) {
                                // set error
                                this.$serverError.innerHTML = jsonError.Message;

                                // show error
                                this.$serverError.classList.add("is-active");

                                scrollTo(this.$serverError, 30, 1000);
                            }
                        })
                        .catch((genericError: any) => {
                            console.error(
                                "Generic error from API",
                                error.statusText
                            );
                            // set error
                            this.$serverError.innerHTML = error.statusText;

                            // show error
                            this.$serverError.classList.add("is-active");
                        });
                } else {
                    console.error("Fetch error", error);
                }
            });
    }

    kenticoFieldMapper(field: Object): Object {
        const typeMapper = (kenticoType: string): string => {
            switch (kenticoType) {
                case "Kentico.TextInput":
                    return "text";
                case "Kentico.EmailInput":
                    return "email";
                case "Kentico.DropDown":
                    return "select";
                case "Kentico.RadioButtons":
                    return "radio";
                case "Kentico.CheckBox":
                    return "checkbox";
                case "Kentico.FileUploader":
                    return "file";
                case "Kentico.IntInput":
                    return "number";
                case "Kentico.USPhone":
                    return "tel";
                case "Kentico.Recaptcha":
                    return "recaptcha";
                case "Kentico.TextArea":
                    return "textarea";
                case "TheRocks.DateInput":
                    return "date";
                case "TheRocks.Heading":
                    return "heading";
                case "TheRocks.SubHeading":
                    return "label";
                case "TheRocks.Gallery":
                    return "gallery";
                case "TheRocks.RTE":
                    return "rte";
                case "TheRocks.Price":
                    return "price";
                default:
                    return "text";
            }
        };

        const validationMapper = (kenticoValidation: Object): any => {
            /*
            Kentico.MinimumLength
            Kentico.MaximumLength
            Kentico.Email
            Kentico.RegularExpression
            Kentico.BoolIsSet
             */
            const validations = [];
            if (!kenticoValidation) {
                return validations;
            }
            for (const validationObject of kenticoValidation) {
                switch (validationObject.Type) {
                    case "Kentico.Email":
                        validations.push({
                            type: "email",
                            errorMessage: validationObject.ErrorMessage,
                        });
                        break;
                    case "Kentico.MinimumLength":
                        validations.push({
                            type: "minCharLength",
                            value: validationObject.Value,
                            errorMessage: validationObject.ErrorMessage,
                        });
                        break;
                    case "Kentico.MaximumLength":
                        validations.push({
                            type: "maxCharLength",
                            value: validationObject.Value,
                            errorMessage: validationObject.ErrorMessage,
                        });
                        break;
                    case "Kentico.BoolIsSet":
                        validations.push({
                            type: "required",
                            errorMessage: validationObject.ErrorMessage,
                        });
                        break;
                    case "Kentico.RegularExpression":
                        validations.push({
                            type: "regularExpression",
                            value: validationObject.Value,
                            errorMessage: validationObject.ErrorMessage,
                        });
                        break;
                    case "TheRocks.BeforeDate":
                        validations.push({
                            type: "beforeDate",
                            value: validationObject.Value,
                            errorMessage: validationObject.ErrorMessage,
                        });
                        break;
                    default:
                        break;
                }
            }
            return validations;
        };

        const formField = {
            type: typeMapper(field.Settings.ComponentIdentifier),
            name: field.Name,
            required: !field.AllowEmpty,
            value: this.parseDefaultValue(field.DefaultValue),
            placeholder:
                field.Settings.Placeholder || field.Settings.OptionLabel,
            label: field.Caption,
            info: field.Properties.ExplanationText,
            tooltip: field.Properties.FieldDescription,
            validations: validationMapper(field.Validations),
            halfSize: field.Settings.HalfSize,
            options: [],
            checkboxLabel: "",
            subHeading: field.Settings.SubHeading,
            title: field.Properties.Title,
            description: field.Properties.Description,
            gallery: _.zipWith(
                field.Properties.Caption,
                field.Properties.YoutubeId,
                field.Properties.ImageUrl,
                (caption: string, youtube: string, image: string): Object => ({
                    caption,
                    youtube,
                    image,
                })
            ),
        };

        if (formField.type === "date") {
            this.addDatepicker(formField.name);
        }

        if (formField.type === "checkbox") {
            formField.options = [
                {
                    name: field.Name,
                    value: this.parseDefaultValue(field.Settings.Text),
                    label: field.Settings.Text,
                },
            ];
            formField.checkboxLabel = field.Settings.Text;
        }

        if (formField.type === "radio" || formField.type === "select") {
            formField.options = _.map(
                field.Options,
                (option: string): Object => {
                    return {
                        name: field.Name,
                        value: option,
                        label: option,
                    };
                }
            );
        }

        return formField;
    }

    mergeCheckboxFields(fields: Object): Object {
        const checkboxFields = {};
        let newFields = fields;
        const regex = /([\d\w\-_]+)(_\d+)/i;
        for (const field of fields) {
            if (field.type === "checkbox") {
                const match = field.name.match(regex);
                let optionField;

                if (match) {
                    optionField = {
                        value: field.name,
                        label: field.checkboxLabel,
                        name: match[1],
                        checked: this.parseDefaultValue(field.value),
                    };
                } else {
                    optionField = {
                        value: field.name,
                        label: field.checkboxLabel,
                        name: field.name,
                        checked: this.parseDefaultValue(field.value),
                    };
                }
                if (!checkboxFields[optionField.name]) {
                    checkboxFields[optionField.name] = [];
                }
                checkboxFields[optionField.name].push(optionField);
            }
        }

        for (const key in checkboxFields) {
            const checkboxField = checkboxFields[key];
            const name = checkboxField[0].name;
            const firstIdx = _.findIndex(
                newFields,
                (field: Object): boolean => {
                    return field.name.indexOf(name) === 0;
                }
            );
            if (firstIdx >= 0) {
                newFields[firstIdx].options = checkboxField;
                newFields[firstIdx].name = name;
                _.remove(newFields, (field: Object, key: number): boolean => {
                    return field.name.indexOf(name) === 0 && key !== firstIdx;
                });
            }
        }
        return newFields;
    }

    parseDefaultValue(value: any): any {
        if (value === "true" || value === "True" || value === true) {
            return true;
        } else if (value === "false" || value === "False" || value === false) {
            return false;
        } else if (value === null) {
            return "";
        } else if (_.parseInt(value).toString() === value) {
            return _.parseInt(value);
        } else {
            return value;
        }
    }

    buildReactForm(fields: Object, steps: Object) {
        if (ReactFormBuilder) {
            ReactDOM.render(
                <>
                    <ReactFormBuilder
                        formData={fields}
                        steps={steps}
                        formProcessor={this.processFormData.bind(this)}
                        recaptchaSiteKey={this.recaptchaSiteKey}
                        cancelText={this.cancelText}
                        hideGoogleRecaptcha={this.hideGoogleRecaptcha}
                        submitText={this.submitText}
                        cancelUrl={this.cancelUrl}
                    />
                </>,
                this.$reactRoot
            );
        }
    }

    showLoader() {
        if (loaderPortal) {
            ReactDOM.render(
                <Loader
                    active={true}
                    fixed={true}
                    dark={true}
                    content={
                        <>
                            <p>Please wait while we process your submission.</p>
                            <p>Do not refresh the page.</p>
                        </>
                    }
                />,
                loaderPortal
            );
        }
    }

    hideLoader() {
        if (loaderPortal) {
            ReactDOM.render(null, loaderPortal);
        }
    }

    showDatepicker(id: string) {
        this.$datepickerRoot
            .querySelectorAll(".form-builder__datepicker")
            .forEach(($datepicker: any, index: number) => {
                const matchesId = $datepicker.classList.contains(
                    `form-builder__datepicker--${id}`
                );
                $datepicker.style.display = matchesId ? "block" : "none";
                $datepicker.classList.toggle("is-active", matchesId);
            });
        const $dayWrapper = this.$datepickerRoot.querySelector(
            ".form-builder__datepicker.is-active .datepicker__days-wrapper"
        );
        this.$datepickerRoot.classList.add("is-active");
        $dayWrapper
            // $FlowFixMe
            .focus();
    }

    closeDatepicker() {
        this.$datepickerRoot.classList.remove("is-active");
    }
}

export default FormBuilder;
