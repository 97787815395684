// @flow

import { cookie } from "@utils/";
import { getQueryString } from "@utils/";

class NewsletterToaster {
    $el: HTMLElement;

    constructor(el: HTMLElement) {
        this.$footer = document.querySelector("#footer");

        this.$el = el;
        this.$dismissButton = this.$el.querySelector(
            "[newsletter-toaster-dismiss]"
        );
        this.showDelay =
            parseInt(
                this.$el.getAttribute("newsletter-toaster-show-delay") || 1
            ) * 1000;
        this.hideDays = parseInt(
            this.$el.getAttribute("newsletter-toaster-hide-days") || 30
        );
        this.observer = null;

        this.checkEdmSource();
        setTimeout(() => {
            this.init();
        }, this.showDelay);
    }

    init() {
        window.utilcookie = cookie;
        if (!this.disabledCheck()) {
            // this.$el.removeAttribute("style");
            this.$el.classList.add("newsletter-toaster--show");
            this.attachEvents();
        }
    }

    disabledCheck(): boolean {
        window.utilcookie = cookie;
        return (
            cookie.get(`newsletter-toaster`) ||
            window.location.pathname.indexOf("/subscribe") >= 0 ||
            window.location.pathname.indexOf("/Subscribe.html") >= 0
        );
    }

    attachEvents() {
        if (this.$dismissButton) {
            this.$dismissButton.addEventListener("click", (event: any): any => {
                this.closeNotification();
                event.preventDefault();
            });
        }
        let options = {
            root: null,
            rootMargin: "0px",
            threshold: 0.1,
        };

        this.observer = new IntersectionObserver(
            this.observerCallback.bind(this),
            options
        );
        this.observer.observe(this.$footer);
    }
    observerCallback(entries: any, observer: any) {
        entries.forEach((entry: any) => {
            if (entry.isIntersecting) {
                this.$el.classList.remove("newsletter-toaster--show");
            } else {
                this.$el.classList.add("newsletter-toaster--show");
            }
        });
    }

    closeNotification() {
        cookie.set(`newsletter-toaster`, true, this.hideDays);
        this.$el.classList.remove("newsletter-toaster--show");
        this.observer.unobserve(this.$footer);
    }

    checkEdmSource() {
        let utmQuery = getQueryString("utm_medium", null, true);

        if (utmQuery) {
            cookie.set(`newsletter-toaster`, true, 365);
        }
    }
}

export default NewsletterToaster;
