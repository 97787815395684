// @flow

// vendor
import React from "react";
import ReactDOM from "react-dom";
import { Route } from "react-router";
import { HashRouter } from "react-router-dom";
import IntersectionObserver from "intersection-observer";

// utils
import { pubsub } from "@utils/";
import moduleInitializer from "@utils/moduleInitializer";
import getPropsFromElement from "@utils/getPropsFromElement";
import LazyLoad from "vanilla-lazyload";

// modules/components
import Carousel from "@modules/Carousel";
import InstagramFeed from "@modules/InstagramFeed";
import FlexibleGrid from "@modules/FlexibleGrid";
import InlineSVG from "@modules/InlineSVG";
import ScrollState from "@modules/ScrollState";
import { enableBodyScroll, disableBodyScroll } from "@modules/ToggleBodyScroll";
import AnimateHeight from "@modules/AnimateHeight";
import InfoFlatHeader from "@modules/InfoFlatHeader";
import ToggleActive from "@modules/ToggleActive";
import YoutubePlayer from "@modules/YoutubePlayer";
import VideoPlayer from "@modules/VideoPlayer";
import NewsletterForm from "@modules/NewsletterForm";
import TenantLandingForm from "@modules/TenantLanding";
import FilmingApplication from "@modules/FilmingApplication";
import ButtonLink from "@modules/ButtonLink";
import LiveSearchContainer from "@modules/LiveSearchContainer";
import ListingPage from "@modules/ListingPage";
import Parallax from "@modules/Parallax";
import Reveal from "@modules/Reveal";
import RichText from "@modules/RichText";
import SearchPage from "@modules/SearchPage";
import SiteNotification from "@modules/SiteNotification";
import OutdatedBrowser from "@modules/OutdatedBrowser";
import PageController from "@modules/PageController";
import FormBuilder from "@modules/FormBuilder";
import PrecinctMap from "@modules/PrecinctMap";
import NewsletterToaster from "@modules/NewsletterToaster";
import VenueFormComponents from "@modules/VenueFormComponents";
import Scroller from "@modules/Scroller";
import AcknowledgementOfCountryModal from "@modules/AcknowledgementOfCountryModal";

// Toggle Active "data-toggle-active" init
new ToggleActive();

// Button Link Init
//initModule("[data-button-link]", ButtonLink);
new ButtonLink();

// Outdated Browser Init
new OutdatedBrowser();

//Lazy Loading
window.lazyLoad = new LazyLoad({
    elements_selector: "img, [data-bg]",
});

// Primary Nav Init
moduleInitializer("[data-scroll-state]", ScrollState);

// Animate Height Init
moduleInitializer("[data-animate-height]", AnimateHeight);

// Hader Init
moduleInitializer("[data-info-flat-header]", InfoFlatHeader);

// Inline SVG Init
moduleInitializer("[data-inline-svg]", InlineSVG);

// Instagram Feed Init
moduleInitializer("[data-instagram-feed]", InstagramFeed);

// Flexible Grid Init
moduleInitializer("[data-flexible-grid]", FlexibleGrid);

// Newsletter Forms
moduleInitializer("[data-newsletter-form]", NewsletterForm, true);

// Live Search
moduleInitializer("[data-live-search]", LiveSearchContainer);

// Parallax Init
moduleInitializer("[data-parallax]", Parallax);

// RichText Init
moduleInitializer("[data-rich-text]", RichText);

// Listing Page
moduleInitializer("[data-listing-page]", ListingPage);

// Reveal Init
moduleInitializer("[data-reveal]", Reveal);

// Search Page Init
moduleInitializer("[data-search-page]", SearchPage);

// Site Notification Init
moduleInitializer("[data-site-notification]", SiteNotification);

// Form Builder Init
moduleInitializer("[data-form-builder]", FormBuilder);

// Tenant Landing Init
moduleInitializer("[tenant-landing]", TenantLandingForm, true);

// Filming Application Init
moduleInitializer("[filming-application]", FilmingApplication, true);

// Newsletter Toaster Init
moduleInitializer("[newsletter-toaster]", NewsletterToaster);

// HTML Video Init
moduleInitializer("[data-inline-video]", VideoPlayer);

// HTML Scroller Init
moduleInitializer("[data-scroller]", Scroller);

// Acknowledgement of Country
moduleInitializer(
    "[data-acknowledgement-of-country]",
    AcknowledgementOfCountryModal
);

// Inline Youtube Player init
const $InlineYoutube = document.querySelectorAll("[data-inline-youtube]");
if ($InlineYoutube) {
    $InlineYoutube.forEach(function($el: HTMLElement) {
        new YoutubePlayer({
            element: $el,
            id: $el.getAttribute("data-inline-youtube-id"),
            playInLightbox: $el.hasAttribute("data-inline-youtube-lightbox"),
            inLightbox: false,
            autoplay: $el.hasAttribute("data-inline-youtube-lightbox") ? 0 : 1,
            loop: $el.hasAttribute("data-inline-youtube-lightbox")
                ? false
                : true,
            mute: $el.hasAttribute("data-inline-youtube-lightbox")
                ? false
                : true,
        });
    });
}

// Carousel Init
const $Carousel = document.querySelectorAll("[data-carousel]");
if ($Carousel) {
    $Carousel.forEach(function($el: HTMLElement) {
        new Carousel({ element: $el });
    });
}

// Page Controller Init
moduleInitializer("body", PageController);

// Enable body scrolling pubsub event
pubsub.subscribe("enableBodyScroll", enableBodyScroll);

// Disable body scrolling pubsub event
pubsub.subscribe("disableBodyScroll", disableBodyScroll);

// Precinct Map (React init)
const selector = "[data-precinct-map]";
const precinctMapReactRoot = document.querySelector(selector);
const props = getPropsFromElement(selector, precinctMapReactRoot);

if (precinctMapReactRoot) {
    ReactDOM.render(
        <HashRouter basename={"/"} hashType={"noslash"}>
            <Route
                path="/map/:type?/:tenantGuid?"
                component={(routerProps: any): any => {
                    return <PrecinctMap {...routerProps} {...props} />;
                }}
            />
        </HashRouter>,
        precinctMapReactRoot
    );
}

// Venu forms components (remove later)
const venuesComponentsSelector = ".venue-form-components";
const venuesComponentReactRoot = document.querySelector(
    venuesComponentsSelector
);
const venuesComponentsProps = getPropsFromElement(
    venuesComponentsSelector,
    precinctMapReactRoot
);

if (venuesComponentReactRoot) {
    ReactDOM.render(
        <VenueFormComponents {...venuesComponentsProps} />,
        venuesComponentReactRoot
    );
}
