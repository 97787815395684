//@flow

import _ from "lodash";

class InstagramFeed {
    $el: HTMLElement;
    $featureArea: ?HTMLElement;
    $rows: ?NodeList<HTMLElement>;
    $items: NodeList<HTMLElement>;
    numberOfPosts: number;
    handle: string;
    posts: any;

    constructor(el: HTMLElement) {
        this.numberOfPosts = 6;
        this.$el = el;
        this.$featureArea = this.$el
            ? this.$el.querySelector("[data-instagram-feed-feature]")
            : null;
        this.$rows = this.$el
            ? this.$el.querySelectorAll("[data-instagram-feed-row]")
            : null;
        this.handle = this.$el.getAttribute("data-instagram-feed") || "";

        // startup
        this.init();

        // events
        this.attachEvents();
    }

    attachEvents() {}

    init() {
        // hide component until posts are loaded
        this.$el.style.display = "none";

        this.fetchFeed();
    }

    fetchFeed() {
        fetch(`https://www.instagram.com/${this.handle}/?__a=1`)
            .then((res: any): any => res.json())
            .then((data: any) => {
                this.posts = _.get(
                    data,
                    "graphql.user.edge_owner_to_timeline_media.edges"
                ).slice(0, 6);

                this.renderPosts();
            })
            .catch((err: any): any => {
                return err;
            });
    }

    renderTile(post: any, renderTo: HTMLElement) {
        const postImage = _.get(post, "node.display_url");
        const postShortCode = _.get(post, "node.shortcode");
        const $postDiv = document.createElement("a");

        $postDiv.setAttribute(
            "href",
            `https://www.instagram.com/p/${postShortCode}`
        );
        $postDiv.classList.add("instagram-feed__tile");
        $postDiv.setAttribute("target", "_blank");
        $postDiv.setAttribute(
            "aria-label",
            `Image on Instagram for ${_.get(post, "node.owner.username")}`
        );
        $postDiv.setAttribute("data-bg", `url(${postImage})`);
        renderTo.prepend($postDiv);
    }

    renderPosts() {
        // render feature tile
        if (this.$featureArea && this.posts[0]) {
            this.renderTile(this.posts[0], this.$featureArea);
            // remove first tile from posts
            this.posts = this.posts.slice(1, this.posts.length);
        }

        // render remaining tiles into rows
        const numberOfRows = this.$rows ? this.$rows.length : 0;
        const postsPerRow = Math.round(this.posts.length / numberOfRows);
        for (let i = 0; i < numberOfRows; i++) {
            const rowOfPosts = this.posts.slice(
                i * postsPerRow,
                i * postsPerRow + postsPerRow
            );

            rowOfPosts.reverse().forEach((post: any, index: number) => {
                if (this.$rows && this.$rows[i]) {
                    this.renderTile(post, this.$rows[i]);
                }
            });
        }

        if (window.lazyLoad) {
            window.lazyLoad.update();
            // window.lazyLoad.loadAll();
        }

        // reveal the entire component
        this.$el.style.display = "block";
    }
}

export default InstagramFeed;
