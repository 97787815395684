// @flow

import React, { Component } from "react";
import { createPortal } from "react-dom";
import { CSSTransition } from "react-transition-group";
import createUniqueID from "@utils/createUniqueID";

type PropsType = {
    children: any,
    className?: string,
    active: boolean,
    onClose?: any,
};

class Modal extends Component<PropsType> {
    uniqueId = createUniqueID();
    bodySelector = document.querySelector("body");

    render(): any {
        if (this.bodySelector) {
            return createPortal(
                <CSSTransition
                    key={this.uniqueId}
                    in={this.props.active}
                    timeout={1000}
                    classNames={"modalFade"}
                    mountOnEnter
                    unmountOnExit
                >
                    <div
                        key={this.uniqueId}
                        className={`modal
                            ${this.props.active ? "is-active" : ""}
                            ${this.props.className || ""}
                        `}
                    >
                        <div className="modal__inner">
                            <div className="modal__content">
                                {this.props.children}
                            </div>
                        </div>
                    </div>
                </CSSTransition>,
                this.bodySelector
            );
        } else {
            return null;
        }
    }
}

export default Modal;
