// @flow

import { post } from "./helpers";

export const postSubscribe = (
    email: string,
    firstname: string,
    campaignId: string,
    segment: string,
    listIdSource: string,
    newsletterApi: string,
    captchaResponse: string
): Promise<any> => {
    return post(newsletterApi, {
        body: {
            EmailAddress: email,
            Name: firstname,
            Resubscribe: true,
            ConsentToTrack: "Unchanged",
            CampaignId: campaignId,
            Segment: segment || "",
            ListIdSource: listIdSource,
            GoogleRecaptchaResponse: captchaResponse,
        },
        headers: {},
    });
};

export default {
    postSubscribe,
};
