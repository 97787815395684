// @flow

const getQueryString = (name: string, url: ?string, encoded: ?boolean): any => {
    if (!url) url = window.location.href;
    name = name.replace(/[[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";

    if (encoded) {
        return results[2].replace(/\+/g, " ");
    } else {
        return decodeURIComponent(results[2].replace(/\+/g, " "));
    }
};

export default getQueryString;
