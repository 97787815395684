// @flow

import createUniqueID from "./createUniqueID";
import escapeRegExp from "./escapeRegExp";

const uniqueSvg = (svgMarkup: string): string => {
    const re = new RegExp(/(id=".*?")/gi);
    const idArray = svgMarkup.match(re) || [];

    for (let idAttribute of idArray) {
        const randomID = createUniqueID();
        let cleanId = idAttribute
            .replace(new RegExp('"', "g"), "")
            .replace("id=", "");
        svgMarkup = svgMarkup
            .replace(
                new RegExp(escapeRegExp("url(#") + cleanId, "g"),
                "url(#" + randomID
            )
            .replace(
                new RegExp(escapeRegExp('href="#') + cleanId, "g"),
                'href="#' + randomID
            )
            .replace(
                new RegExp(escapeRegExp('id="') + cleanId, "g"),
                'id="' + randomID
            );
    }

    return svgMarkup;
};

export default uniqueSvg;
