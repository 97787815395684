// @flow

import * as React from "react";
import moment from "moment";
import type Moment from "moment";
import { getAvailableDates } from "../api/datepicker";
import _ from "lodash";
import PubSub from "../utils/pubsub";
import Config from "../constants/Config";

type PropsType = {
    disablePastDays?: boolean,
    datepickerId: string,
    debug?: boolean,
    disableDays?: any,
    disableDaysAfterDays?: integer,
};

type StateType = {
    selectedDate: Moment,
    focusedDate: Moment,
    availableDates: Object,
    loading: boolean,
};

class DatePicker extends React.Component<PropsType, StateType> {
    state = {
        selectedDate: null,
        focusedDate: moment(),
        availableDates: {},
        loading: true,
    };

    today = moment();

    requestedYears = [];

    loading = true;

    subscription = null;

    componentDidMount = () => {
        this.attachSubscription();

        // fetch current and next year preemptive
        this.fetchAvailableDates(this.today.year());
        this.fetchAvailableDates(this.today.year() + 1);
    };

    attachSubscription = () => {
        this.subscription = PubSub.subscribe(`datepicker`, (data: any) => {
            if (this.props.debug) {
                console.log(
                    `[Datepicker:${this.props.datepickerId}], Event Received:`,
                    data
                );
            }
            const date = moment(data.date, Config.dateFormat);
            if (
                data.type === "availabilityCheckRequest" &&
                data.id === this.props.datepickerId
            ) {
                const response = {
                    id: this.props.datepickerId,
                    type: "availabilityCheckResponse",
                    date: data.date,
                    available: this.checkDayAvailability(date),
                    valid: date.isValid(),
                };
                PubSub.publish(`datepicker`, response);

                if (this.checkDayAvailability(date)) {
                    this.selectDate(date, true);
                }

                if (this.props.debug) {
                    console.log(
                        `[Datepicker:${this.props.datepickerId}], Event Sent:`,
                        response
                    );
                }
            }
        });
    };

    attachKeyListener = () => {
        document.addEventListener("keydown", this.handleKeyPress, true);
    };

    detachKeyListener = () => {
        document.removeEventListener("keydown", this.handleKeyPress, true);
    };

    handleKeyPress = (event: any) => {
        if (this.loading) {
            event.preventDefault();
            return;
        }

        let diffAmount = 0;
        let diffType = "days";

        const key = event.key || event.keyCode;

        switch (key) {
            case "ArrowLeft":
            case "Left":
                diffAmount = -1;
                break;
            case "ArrowRight":
            case "Right":
                diffAmount = 1;
                break;
            case "ArrowUp":
            case "Up":
                diffAmount = -7;
                break;
            case "ArrowDown":
            case "Down":
                diffAmount = 7;
                break;
            case "PageDown":
                diffAmount = 1;
                diffType = event.altKey ? "years" : "months";
                break;
            case "PageUp":
                diffAmount = -1;
                diffType = event.altKey ? "years" : "months";
                break;
            case "Enter":
                this.selectDate(this.state.focusedDate);
                event.preventDefault();
                break;
            default:
                break;
        }
        if (diffAmount !== 0) {
            this.changeFocusDate(diffAmount, diffType);
            event.preventDefault();
        }
    };

    selectDate = (selectedDate: Moment, avoidPublish?: boolean = false) => {
        if (this.loading || !this.checkDayAvailability(selectedDate)) {
            return;
        }

        if (!avoidPublish) {
            const response = {
                id: this.props.datepickerId,
                type: "dateSelection",
                date: selectedDate.format(Config.dateFormat),
                available: true,
                valid: true,
            };
            PubSub.publish(`datepicker`, response);
            if (this.props.debug) {
                console.log(
                    `[Datepicker:${this.props.datepickerId}], Event Sent:`,
                    response
                );
            }
        }

        if (this.props.onSelect) {
            this.props.onSelect(selectedDate.format(Config.dateFormat));
        }

        this.setState({
            selectedDate: selectedDate,
            focusedDate: selectedDate,
        });
    };

    checkDayAvailability = (day: Moment): boolean => {
        const month = day.format("YYYY-MM");
        const dayFormatted = day.format("YYYY-MM-DD");

        if (day.isValid() && !_.has(this.state.availableDates, month)) {
            this.fetchAvailableDates(day.year());
        }

        if (
            this.props.disableDays &&
            this.props.disableDays.includes(dayFormatted)
        ) {
            return false;
        }

        const diffDays = Math.floor(
            moment.duration(day.diff(new moment())).asDays()
        );

        if (
            this.props.disableDaysAfterDays &&
            diffDays >= this.props.disableDaysAfterDays
        ) {
            return false;
        }

        if (
            !day.isValid() ||
            !_.has(this.state.availableDates, month) ||
            (_.has(this.state.availableDates, month) &&
                !this.state.availableDates[month].includes(dayFormatted)) ||
            (this.props.disablePastDays && day.isBefore(this.today, "day"))
        ) {
            return false;
        } else {
            return true;
        }
    };

    changeFocusDate = (diffAmount: number, diffType: string) => {
        if (this.loading) {
            return;
        }
        const newDate = this.state.focusedDate.clone();
        newDate.add(diffAmount, diffType);
        this.setState({
            focusedDate: newDate,
        });
    };

    getDaysOfMonth = (year: number, month: number): Object => {
        const daysOfMonth = [
            ...Array(moment([year, month - 1]).daysInMonth()).keys(),
        ].map((dayIdx: number): Object => {
            const day = moment([year, month - 1, dayIdx + 1]);
            return {
                moment: day,
                dayOfMonth: day.format("D"),
                date: day.format("YYYY-MM-DD"),
                label: day.format("dddd, MMMM Do YYYY"),
                labelAria: day.format("Do, dddd MMMM YYYY"),
            };
        });

        return daysOfMonth;
    };

    getStyleClassesForDay = (day: Object): Array<string> => {
        let styleClasses = ["datepicker__day"];
        if (day.dayOfMonth === "1") {
            const firstDayOfMonth = day.moment.format("E");
            styleClasses.push(`datepicker__day--${firstDayOfMonth}-day-push`);
        }

        if (day.moment.isSame(this.today, "day")) {
            styleClasses.push("datepicker__day--today");
        }

        if (!this.checkDayAvailability(day.moment)) {
            const dayFormatted = day.moment.format("YYYY-MM-DD");
            if (
                this.props.disableDays &&
                this.props.disableDays.includes(dayFormatted)
            ) {
                styleClasses.push("datepicker__day--closed");
            } else {
                styleClasses.push("datepicker__day--disabled");
            }
        }
        const diffDays = Math.floor(
            moment.duration(day.moment.diff(new moment())).asDays()
        );

        if (
            this.props.disableDaysAfterDays &&
            diffDays >= this.props.disableDaysAfterDays
        ) {
            styleClasses.push("datepicker__day--disabled");
        }

        if (day.moment.isSame(this.state.selectedDate, "day")) {
            styleClasses.push("datepicker__day--selected");
        }

        if (day.moment.isSame(this.state.focusedDate, "day")) {
            styleClasses.push("datepicker__day--focused");
        }

        return styleClasses;
    };

    fetchAvailableDates = (year: number): any => {
        if (this.requestedYears.includes(year)) {
            return;
        }

        this.loading = true;
        const availableDates = getAvailableDates(year);
        if (this.props.debug) {
            console.log(
                `[Datepicker:${this.props.datepickerId}], Requesting Available Dates of Year ${year}`
            );
        }
        this.requestedYears.push(year);
        availableDates.then((dates: Object) => {
            if (this.props.debug) {
                console.log(
                    `[Datepicker:${this.props.datepickerId}], Received Available Dates of Year ${year}`,
                    dates
                );
            }
            this.loading = false;
            this.setState({
                availableDates: _.merge(this.state.availableDates, dates),
            });
        });
    };

    renderHeader = (): any => {
        return (
            <div className="datepicker__header">
                <button
                    className="datepicker__month-button"
                    type="button"
                    aria-label={`Previous Month, ${this.state.focusedDate
                        .clone()
                        .subtract(1, "months")
                        .format("MMMM YYYY")}`}
                    onClick={(e: any): any => {
                        e.preventDefault();
                        this.changeFocusDate(-1, "month");
                    }}
                >
                    <i className="icon icon-chevron-left"></i>
                </button>
                <h4 className="datepicker__month">
                    {this.state.focusedDate.format("MMMM YYYY")}
                </h4>
                <button
                    className="datepicker__month-button"
                    aria-label={`Next Month, ${this.state.focusedDate
                        .clone()
                        .subtract(1, "months")
                        .format("MMMM YYYY")}`}
                    onClick={(e: any): any => {
                        e.preventDefault();
                        this.changeFocusDate(1, "month");
                    }}
                >
                    <i className="icon icon-chevron-right"></i>
                </button>
                <div className="datepicker__weekdays">
                    {moment.weekdays().map((day: string): Object => {
                        return (
                            <div
                                key={day}
                                aria-label={day}
                                className="datepicker__weekday"
                            >
                                {day.substr(0, 3)}
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    };

    renderDays = (): any => {
        const daysOfMonth = this.getDaysOfMonth(
            this.state.focusedDate.format("YYYY"),
            this.state.focusedDate.format("M")
        );

        return (
            <div
                className="datepicker__days-wrapper"
                tabIndex="0"
                aria-label={`${
                    this.checkDayAvailability(this.state.focusedDate)
                        ? ""
                        : "Not Available, "
                }${this.state.focusedDate.format("Do, dddd MMMM YYYY")}`}
                onBlur={(e: any): any => this.detachKeyListener()}
                onFocus={(e: any): any => this.attachKeyListener()}
            >
                {daysOfMonth.map((day: Object): Object => {
                    const classNames = this.getStyleClassesForDay(day);
                    const available = this.checkDayAvailability(day.moment);
                    return (
                        <div
                            className={classNames.join(" ")}
                            key={day.date}
                            title={`${available ? "" : "Not Available, "}${
                                day.label
                            }`}
                            onClick={(e: any): any => {
                                if (available) {
                                    this.selectDate(day.moment);
                                } else {
                                    e.preventDefault();
                                }
                            }}
                        >
                            {day.dayOfMonth}
                        </div>
                    );
                })}
            </div>
        );
    };

    renderLoadingIndicator = (): any => {
        const loading = this.loading;
        return (
            <div
                className={`datepicker__loading-overlay${
                    loading ? " datepicker__loading-overlay--active" : ""
                }`}
            >
                <span>Loading…</span>
            </div>
        );
    };

    render = (): any => {
        const year = this.state.focusedDate.year();
        this.fetchAvailableDates(year);

        return (
            <div className="datepicker__wrapper" role="dialog">
                {this.renderLoadingIndicator()}
                {this.renderHeader()}
                {this.renderDays()}
                {this.props.disableDays && (
                    <div className="datepicker__infotext">
                        Due to major event precinct activity not all dates are
                        available. You will not be able to select shoot dates
                        that fall within major event planning or delivery
                        periods.
                    </div>
                )}
            </div>
        );
    };
}

export default DatePicker;
