// @flow

import * as React from "react";
import {
    ReactForm,
    ReactFormField,
    ReactFormGroup,
} from "@adrenalin/react-form";
import { postFormData, getFormStructure } from "../api/formbuilder";
import _ from "lodash";
// import useAsyncState from "../hooks/useAsyncState";
import useMergableState from "../hooks/useMergableState";
import RadioGroup from "../components/formfields/RadioGroup";
import CheckboxGroup from "../components/formfields/CheckboxGroup";
import CustomField from "../components/formfields/CustomField";
import TextField from "../components/formfields/TextField";
import TextArea from "../components/formfields/TextArea";
import HiddenField from "../components/formfields/HiddenField";
import DatePicker from "../components/formfields/DatePicker";
import TimePicker from "../components/formfields/TimePicker";
import AccordionGroup from "../components/molecules/AccordionGroup";
import FileUploadField from "../components/formfields/FileUpload";
import RecaptchaField from "../components/formfields/RecaptchaField";
import Loader from "../components/atoms/Loader";

const { useState, useEffect, useRef } = React;

type PropsType = {
    structureApiUrl: string,
    formName: string,
    recaptchaSiteKey: string,
};

const timePrettyPrint = (timestamp: string): string => {
    const hoursSince = Math.floor(timestamp / 60 / 60);
    const minutesSince = Math.floor(timestamp / 60 - hoursSince * 60);
    return timestamp
        ? `${_.padStart(hoursSince, 2, "0")}:${_.padStart(
              minutesSince,
              2,
              "0"
          )}`
        : null;
};

const FilmingApplication = (props: PropsType): React.Node => {
    const [loadingState, setLoadingState] = useState(1); //0 ready, 1 loading, 2 submitting
    const [apiResponse, setApiResponse] = useState(null);
    const [serverError, setServerError] = useState("");

    useEffect(() => {
        getFormStructure(props.structureApiUrl, props.formName).then(
            (json: Object) => {
                setApiResponse(json);
                setLoadingState(0);
            }
        );
    }, []);

    /**
     *
     * InitFormData
     */

    const defaultInputValues = {};

    const [inputValues, setInputValues] = useMergableState(defaultInputValues);

    /**
     * Form Submission
     *
     *
     *
     *
     */

    const submitForm = (data: any) => {
        setLoadingState(2);
        postFormData(apiResponse.FormSubmissionUrl, data)
            .then((response: any): Promise => {
                if (!response.ok) {
                    return Promise.reject(response);
                }
                return response.json();
            })
            .then((json: any) => {
                window.location.href = apiResponse.ThankyouUrl;
                setServerError("");
                setLoadingState(0);
            })
            .catch((error: any): any => {
                setServerError(error);
                setLoadingState(0);
            });
    };

    /**
     * Form Fields
     *
     *
     *
     *
     */
    const customValidators = [
        {
            ID: "phone",
            validate: (input: any): boolean => {
                var auPhoneRegex = /^([\d+\-\(\) ]){1,15}$/i;
                return auPhoneRegex.test(input.value);
            },
            error: "Please enter a valid phone number",
        },
        {
            ID: "abn",
            validate: (input: any): boolean => {
                var auPhoneRegex = /^(\d){1,14}$/i;
                return auPhoneRegex.test(input.value);
            },
            error: "Please enter a valid ABN/ACN",
        },
    ];
    const fieldWrapper = (element: {}, markup: React.Node): React.Node => {
        return (
            <div
                key={element.name}
                className={`react-form__element react-form__element--${
                    element.type
                } ${
                    element.halfSize ? "react-form__element--half-collapse" : ""
                } ${
                    element.subHeading
                        ? "react-form__element--has-subheading"
                        : ""
                }`}
                data-sub-heading={element.subHeading}
            >
                {markup}
            </div>
        );
    };

    const basicField = (config: {}): React.Node => {
        return fieldWrapper(
            config,
            <ReactFormField
                required={true}
                fieldComponent={TextField}
                {...config}
            />
        );
    };

    const dateField = (config: {}): React.Node => {
        const validatorKey = `<=:${new Date().getTime() +
            365 * 24 * 60 * 60 * 1000}`;
        return fieldWrapper(
            config,
            <>
                <ReactFormField
                    fieldComponent={DatePicker}
                    disableDays={apiResponse.UnavailableDates}
                    disableDaysAfterDays={365}
                    name={config.name}
                    label={config.label}
                    placeholder="dd/mm/yyyy"
                    required={true}
                    validators={[validatorKey]}
                    onChange={(event: any) => {
                        setInputValues({
                            [config.name || "default"]: event.target.value,
                        });
                    }}
                    errorMessages={{
                        "<=": "Date must be within a year",
                    }}
                    {...config}
                    type="text"
                />
            </>
        );
    };

    const timeField = (config: {}): React.Node => {
        return (
            <>
                <ReactFormField
                    fieldComponent={HiddenField}
                    name={config.nameStart}
                    required={false}
                    type={"text"}
                    value={timePrettyPrint(
                        inputValues[config.nameStart + "TS"]
                    )}
                />
                <ReactFormField
                    fieldComponent={HiddenField}
                    name={config.nameEnd}
                    required={false}
                    type={"text"}
                    value={timePrettyPrint(inputValues[config.nameEnd + "TS"])}
                />
                {fieldWrapper(
                    {
                        type: "time",
                        halfSize: true,
                    },
                    <>
                        <ReactFormField
                            fieldComponent={TimePicker}
                            name={config.nameStart + "TS"}
                            label={config.labelStart}
                            required={
                                config.required ||
                                inputValues[config.requiredDependant]
                            }
                            onChange={(event: any) => {
                                setInputValues({
                                    [config.nameStart + "TS" ||
                                    "default"]: event.target.value,
                                });
                            }}
                            {...config}
                            type="text"
                        />
                    </>
                )}
                {fieldWrapper(
                    {
                        type: "time",
                        halfSize: true,
                    },
                    <>
                        <ReactFormField
                            fieldComponent={TimePicker}
                            name={config.nameEnd + "TS"}
                            label={config.labelEnd}
                            required={
                                config.required ||
                                inputValues[config.requiredDependant]
                            }
                            validators={
                                inputValues[config.requiredDependant] ||
                                config.required
                                    ? [`>field:${config.nameStart}TS`]
                                    : ""
                            }
                            errorMessages={{
                                ">field":
                                    "End time must be after the start time.",
                            }}
                            onChange={(event: any) => {
                                setInputValues({
                                    [config.nameEnd + "TS" || "default"]: event
                                        .target.value,
                                });
                            }}
                            {...config}
                            type="text"
                        />
                    </>
                )}
            </>
        );
    };

    const selectField = (config: {}): React.Node => {
        return fieldWrapper(
            config,
            <ReactFormField
                type="select"
                required={true}
                className={!inputValues[config.name] ? "has-placeholder" : ""}
                onChange={(event: any) => {
                    setInputValues({
                        [config.name]: event.target.value,
                    });
                }}
                {...config}
            >
                {!inputValues[config.name] && <option value="">Select</option>}
                {config.options.map(
                    (option: any, index: number): React.Node => (
                        <option value={option} key={index}>
                            {option}
                        </option>
                    )
                )}
            </ReactFormField>
        );
    };

    const checkboxField = (config: {}): React.Node => {
        return fieldWrapper(
            config,
            <CheckboxGroup
                label={config.label}
                info={config.info || ""}
                tooltip={config.tooltip || ""}
            >
                <div className="radio-group-col">
                    {config.options.map(
                        (option: any, index: number): React.Node => {
                            return (
                                <ReactFormField
                                    key={index}
                                    type={"checkbox"}
                                    fieldComponent={CustomField}
                                    name={option.name}
                                    info={""}
                                    value={inputValues[option.name] ? "on" : ""}
                                    label={option.label}
                                    id={option.value}
                                    checked={inputValues[option.name]}
                                    required={config.required}
                                    onChange={(event: any) => {
                                        setInputValues({
                                            [option.name]: inputValues[
                                                option.name
                                            ]
                                                ? ""
                                                : "on",
                                        });
                                    }}
                                ></ReactFormField>
                            );
                        }
                    )}
                </div>
            </CheckboxGroup>
        );
    };

    const radioField = (config: {}): React.Node => {
        return fieldWrapper(
            config,
            <RadioGroup
                label={config.label}
                info={config.info || ""}
                tooltip={config.tooltip || ""}
            >
                <div className="radio-group-col">
                    {config.options.map(
                        (option: any, index: number): React.Node => {
                            return (
                                <ReactFormField
                                    key={index}
                                    type={"radio"}
                                    fieldComponent={CustomField}
                                    name={`${config.name}`}
                                    info={""}
                                    value={option}
                                    label={option}
                                    id={`${config.name}-${option}`}
                                    checked={
                                        inputValues[config.name] === option ||
                                        (inputValues[config.name] ==
                                            undefined &&
                                            config.default == option)
                                    }
                                    required={config.required}
                                    onChange={(event: any) => {
                                        console.log(config.name, option, event);
                                        setInputValues({
                                            [config.name]: option,
                                        });
                                    }}
                                    // defaultChecked={
                                    //     inputValues[config.name] === undefined
                                    // }
                                ></ReactFormField>
                            );
                        }
                    )}
                </div>
            </RadioGroup>
        );
    };

    const locationField = (config: {}): React.Node => {
        const precinctKeys = [
            "Darling Harbour Precinct",
            "The Rocks Precinct",
            "Pyrmont Precinct",
        ];
        return fieldWrapper(
            {
                type: "location",
            },
            <CheckboxGroup
                label={config.label}
                info={`<a
                        href="/venue-hire/venue-film-and-photography-locations"
                        target="_blank"
                    >
                        Click here to see a map of our locations
                    </a>`}
                tooltip={config.tooltip || ""}
            >
                <div style={{ paddingTop: 20 }}>
                    <AccordionGroup>
                        {precinctKeys.map(
                            (
                                precinctKey: any,
                                precinctIndex: number
                            ): React.Node => {
                                return (
                                    <AccordionGroup.Section key={precinctIndex}>
                                        <AccordionGroup.Title>
                                            {precinctKey}
                                        </AccordionGroup.Title>
                                        <AccordionGroup.Content>
                                            <div className="row">
                                                {apiResponse.Locations[
                                                    precinctKey
                                                ].map(
                                                    (
                                                        location: any,
                                                        locationIndex: number
                                                    ): React.Node => {
                                                        const boxKey = `${config.name}:${precinctKey}:${location}`;
                                                        return (
                                                            <ReactFormField
                                                                key={
                                                                    locationIndex
                                                                }
                                                                className="col-xs-6"
                                                                type={
                                                                    "checkbox"
                                                                }
                                                                fieldComponent={
                                                                    CustomField
                                                                }
                                                                name={boxKey}
                                                                info={""}
                                                                label={location}
                                                                value={
                                                                    inputValues[
                                                                        boxKey
                                                                    ]
                                                                        ? "on"
                                                                        : ""
                                                                }
                                                                onChange={(
                                                                    event: any
                                                                ) => {
                                                                    setInputValues(
                                                                        {
                                                                            [boxKey]: inputValues[
                                                                                boxKey
                                                                            ]
                                                                                ? ""
                                                                                : "on",
                                                                        }
                                                                    );
                                                                }}
                                                            />
                                                        );
                                                    }
                                                )}
                                            </div>
                                        </AccordionGroup.Content>
                                    </AccordionGroup.Section>
                                );
                            }
                        )}
                    </AccordionGroup>
                </div>
            </CheckboxGroup>
        );
    };

    const fileField = (config: {}): React.Node => {
        return fieldWrapper(
            config,
            <ReactFormField
                type={"file"}
                required={true}
                fieldComponent={FileUploadField}
                {...config}
            />
        );
    };

    const textareaField = (config: {}): React.Node => {
        return fieldWrapper(
            config,
            <ReactFormField
                type="textarea"
                required={true}
                fieldComponent={TextArea}
                {...config}
            />
        );
    };

    /**
     * Form Structure
     *
     *
     *
     *
     */

    const ultraLow = (): React.Node => {
        return (
            <div className="filming-application__form-wrapper row">
                <ReactForm
                    disableSubmitUntilValid={false}
                    validators={customValidators}
                    onSuccess={(
                        data: Object,
                        state: any,
                        e: any,
                        formDataObject: any
                    ) => {
                        submitForm(formDataObject);
                    }}
                    className="filming-application__form"
                >
                    <fieldset className="react-form__section">
                        <div className="heading-3">About this form</div>
                        <div className="react-form__rte rich-text">
                            <ol>
                                <li>
                                    This form is to apply for Ultra-Low Filming
                                    and Photography activity within any Place
                                    Management NSW open space as per{" "}
                                    <a
                                        href="https://www.screen.nsw.gov.au/page/film-friendly-nsw"
                                        target="_blank"
                                    >
                                        New South Wales Film Friendly 2009
                                        protocol
                                    </a>
                                    .
                                </li>
                                <li>
                                    This form requires at least{" "}
                                    <strong>
                                        2 business days submission lead time
                                    </strong>
                                    .
                                </li>
                                <li>
                                    If your Filming & Photography does not
                                    strictly meet the criteria listed below you
                                    must apply for a Low to High sized shoot
                                    which requires a minimum of{" "}
                                    <strong>
                                        5 business days submission lead time
                                    </strong>
                                    . Shoots requiring drones, other unmanned
                                    aerial vehicles (UAV) &/OR Road & Footway
                                    permits for road closures and pedestrian
                                    holds require a minimum of{" "}
                                    <strong>
                                        15 business days submission lead time.{" "}
                                    </strong>
                                    <a
                                        href="/venues/venue-low-high"
                                        target="_blank"
                                    >
                                        Apply for Low to High Category Film &
                                        Photography activity HERE
                                    </a>
                                    .
                                </li>
                                <li>
                                    Your activity will be assessed based on the
                                    information you provide in this form.
                                </li>
                                <li>
                                    This application type provides{" "}
                                    <strong>
                                        no booking or priority over any space or
                                        location.
                                    </strong>
                                </li>
                                <li>
                                    Your approval may not be issued until the
                                    last working day before the shoot date. This
                                    ensures there are no conflicting bookings
                                    for the same space.
                                </li>
                                <li>
                                    Your booking or notification is not
                                    confirmed until you have received a written
                                    confirmation from Place Management NSW.
                                </li>
                                <li>
                                    Ultra-low impact filming is defined by these
                                    criteria:
                                    <ul>
                                        <li>
                                            <strong>no more than</strong> 10
                                            people will be on-site
                                        </li>
                                        <li>
                                            <strong>no more than</strong>{" "}
                                            maximum of 2 cameras, 1 battery
                                            operated light, 1 tripod, 1 handheld
                                            reflector, 1 piece of handheld sound
                                            equipment. No infrastructure or
                                            large props included. No laptops
                                            permitted
                                        </li>
                                        <li>
                                            <strong>no disruption</strong> is
                                            caused to the Place Management NSW
                                            stakeholders, retailers, motorists
                                            or other events in the vicinity of
                                            the activities
                                        </li>
                                        <li>
                                            <strong>
                                                activities are contained
                                            </strong>{" "}
                                            to footways or public open space
                                            areas only. No vehicular laneways
                                        </li>
                                        <li>
                                            <strong>
                                                public safety is maintained
                                            </strong>{" "}
                                            at the locations at all times during
                                            the conduct of the activities
                                        </li>
                                        <li>
                                            there are no{" "}
                                            <strong>associated vehicles</strong>{" "}
                                            for the shoot
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    All fields in this form are mandatory, you
                                    must fill or upload the relevant
                                    documentation to these sections to proceed
                                    with an application submission
                                </li>
                            </ol>
                        </div>
                    </fieldset>
                    <fieldset className="react-form__section">
                        <legend className="react-form__section-label">
                            Production contact details
                        </legend>
                        <p>Onsite contact:</p>
                        <div className="react-form__section__container">
                            {basicField({
                                type: "text",
                                name: "FirstName",
                                label: "First Name",
                            })}
                            {basicField({
                                type: "text",
                                name: "LastName",
                                label: "Last Name",
                            })}
                            {basicField({
                                type: "text",
                                name: "Position",
                                label: "Position",
                            })}
                            {basicField({
                                type: "tel",
                                name: "Mobile",
                                label: "Mobile",
                                validators: ["phone"],
                            })}
                            {basicField({
                                type: "email",
                                name: "Email",
                                label: "Email",
                                validators: ["email"],
                            })}
                        </div>
                    </fieldset>
                    <fieldset className="react-form__section">
                        <legend className="react-form__section-label">
                            Applying organisation details
                        </legend>
                        <p>
                            Must match the insured party on the public liability
                            insurance certificate
                        </p>
                        <div className="react-form__section__container">
                            {basicField({
                                type: "text",
                                name: "OrganisationName",
                                label: "Organisation Name",
                            })}
                            {basicField({
                                type: "text",
                                name: "ABNOrACN",
                                label: "ABN/ACN",
                                validators: ["abn"],
                            })}
                            {basicField({
                                type: "tel",
                                name: "CompanyPhoneNumber",
                                label: "Company phone number",
                                validators: ["phone"],
                            })}
                            {basicField({
                                type: "email",
                                name: "CompanyEmailAddress",
                                label: "Company email address",
                                validators: ["email"],
                            })}
                        </div>
                    </fieldset>
                    <fieldset className="react-form__section">
                        <legend className="react-form__section-label">
                            Organisation address
                        </legend>
                        <div className="react-form__section__container">
                            {basicField({
                                type: "text",
                                name: "OrganisationAddress",
                                label: "Organisation address",
                            })}
                            {basicField({
                                type: "text",
                                name: "City",
                                label: "City",
                            })}
                            {selectField({
                                type: "select",
                                name: "State",
                                label: "State",
                                options: [
                                    "ACT",
                                    "NSW",
                                    "NT",
                                    "QLD",
                                    "SA",
                                    "TAS",
                                    "VIC",
                                    "WA",
                                ],
                            })}
                            {basicField({
                                type: "number",
                                name: "Postcode",
                                label: "Postcode",
                            })}
                        </div>
                    </fieldset>

                    <fieldset className="react-form__section">
                        <legend className="react-form__section-label">
                            Production information
                        </legend>
                        <div className="react-form__section__container">
                            {basicField({
                                type: "text",
                                name: "NameOfProduction",
                                label: "Name of production",
                            })}

                            {textareaField({
                                type: "textarea",
                                name: "FullDescriptionOfScenes",
                                label:
                                    "Full description of scene(s) being filmed",
                            })}
                            {selectField({
                                type: "select",
                                name: "NumberOfPeopleOnSite",
                                label: "How many people will be onsite",
                                info:
                                    "Include all crew talent agency and so on",
                                options: [
                                    "1",
                                    "2",
                                    "3",
                                    "4",
                                    "5",
                                    "6",
                                    "7",
                                    "8",
                                    "9",
                                    "10",
                                ],
                            })}
                            {dateField({
                                type: "date",
                                name: "DateOfFilming",
                                label: "Date of filming",
                            })}
                            {timeField({
                                type: "time",
                                nameStart: "StartTime",
                                labelStart: "Start time",
                                nameEnd: "EndTime",
                                labelEnd: "End time",
                                required: true,
                            })}

                            {locationField({
                                type: "location",
                                name: "SpecificLocations",
                                label: "Specific location(s)",
                                info:
                                    "Click here to see a map of our locations",
                            })}
                        </div>
                    </fieldset>

                    <fieldset className="react-form__section">
                        <legend className="react-form__section-label">
                            Equipment to be used
                        </legend>
                        <div className="react-form__section__container">
                            {checkboxField({
                                type: "checkbox",
                                options: [
                                    {
                                        name: "ChkOneOrTwoCamera",
                                        label: "1 or 2 x camera",
                                    },
                                    {
                                        name: "ChkOneTripod",
                                        label: "1 x tripod",
                                    },
                                    {
                                        name: "ChkOneBatteryOperatedLight",
                                        label: "1 x battery operated light",
                                    },
                                    {
                                        name: "ChkOneHandheldReflector",
                                        label: "1 x handheld reflector",
                                    },
                                    {
                                        name: "ChkOneHandheldSound",
                                        label: "1 x handheld sound",
                                    },
                                ],
                            })}
                        </div>
                    </fieldset>

                    <fieldset className="react-form__section">
                        <legend className="react-form__section-label">
                            Required Application supporting documentation
                        </legend>
                        <div className="react-form__section__container">
                            {fileField({
                                type: "file",
                                name: "PublicLiabilityInsuranceCoverFileUpload",
                                label: "Public liability insurance cover",
                                placeholder:
                                    "Upload your file (Max file size 2MB)",
                                info:
                                    "Please note: Your application will not be accepted unless a valid and current public liability insurance certificate is provided, which must match the applicant organisation listed on this application. <br/>(Max file size 2MB)",
                                validators: ["maxFileSize:2"],
                            })}
                            {fileField({
                                type: "file",
                                name: "RequestedLocationsSiteMapFileUpload",
                                label: "Requested location/s site map",

                                placeholder:
                                    "Upload your file (Max file size 5MB)",
                                info: "(Max file size 5MB)",
                                validators: ["maxFileSize:5"],
                            })}
                            {fileField({
                                type: "file",
                                name: "Covid19SafePlanFileUpload",
                                label: "Covid-19 – Covid-Safe Plan",
                                placeholder:
                                    "Upload your file (Max file size 3MB)",
                                info:
                                    "Any approved activities taking place on Place Management NSW land must ensure compliance with state and federal government directives and restrictions in relation to Covid-19.<br/>(Max file size 3MB)",
                                validators: ["maxFileSize:3"],
                            })}
                        </div>
                    </fieldset>

                    <fieldset className="react-form__section">
                        <legend className="react-form__section-label">
                            Applicant Declaration
                        </legend>
                        <div className="react-form__section__container">
                            {checkboxField({
                                type: "checkbox",
                                required: true,
                                options: [
                                    {
                                        name: "ChkAcknowledgeAndUnderstand",
                                        label:
                                            "Yes - I acknowledge and understand the following related to this application",
                                    },
                                ],
                            })}

                            <div className="react-form__element react-form__rte react-form__rte--small rich-text">
                                <ul>
                                    <li>
                                        No other equipment/infrastructure
                                        whatsoever is permitted other than as
                                        declared above. Additional equipment
                                        (such as laptop, props, tables, KD’s and
                                        so on.) will breach any approval issued.
                                        This will result in approval being
                                        retracted, fines and all future
                                        applications will be suspended for the
                                        applicant.
                                    </li>

                                    <li>
                                        Place Management NSW takes no
                                        responsibility for any third-party
                                        commitment entered into by the applicant
                                    </li>

                                    <li>
                                        Place Management NSW manages locations
                                        in The Rocks, Darling Harbour, some
                                        areas of Circular Quay and some areas of
                                        Pyrmont. It is the applicants
                                        responsibility to ensure they have the
                                        appropriate landowner permissions for
                                        locations that fall outside of Place
                                        Management NSW approval areas.
                                    </li>

                                    <li>
                                        Place Management NSW (PMNSW) Rangers may
                                        move you on or close your shoot
                                        immediately if your activity becomes an
                                        inconvenience, hazard or nuisance to the
                                        public or other persons
                                    </li>

                                    <li>
                                        Your activity must not impede pedestrian
                                        movement or access - No pedestrian
                                        ‘holds’ are permitted in any area
                                    </li>

                                    <li>
                                        You are not permitted to block access to
                                        any tenants/residents buildings or
                                        stairways
                                    </li>

                                    <li>
                                        Your Ultra-Low booking or notification
                                        if approved by Place Management NSW does
                                        not give you ownership over any space
                                    </li>

                                    <li>
                                        No tripods or floor mounted equipment
                                        are permitted in high pedestrian traffic
                                        areas
                                    </li>

                                    <li>
                                        Your Ultra-Low booking or notification
                                        if approved by Place Management NSW does
                                        not provide you with permission to use
                                        any tenants buildings for your shoot,
                                        this includes internal & external
                                        facilities
                                    </li>

                                    <li>
                                        Strictly no vehicle access or parking
                                        permitted on site
                                    </li>
                                </ul>
                            </div>

                            {checkboxField({
                                type: "checkbox",
                                required: true,
                                options: [
                                    {
                                        name: "ChkAcceptTerms",
                                        label: `Yes - I confirm and accept the <a
                                        href='/privacy-policy'
                                        target='_blank'
                                    >terms of this form</a>`,
                                    },
                                ],
                            })}
                        </div>
                    </fieldset>

                    <ReactFormField
                        value={inputValues["GoogleRecaptchaResponse"] || ""}
                        type="text"
                        fieldComponent={RecaptchaField}
                        required={true}
                        recaptchaSiteKey={props.recaptchaSiteKey}
                        name="GoogleRecaptchaResponse"
                    ></ReactFormField>

                    <button
                        type="submit"
                        className="react-form__button cta cta--inline cta--big"
                    >
                        Next
                    </button>
                </ReactForm>
            </div>
        );
    };

    const lowHigh = (): React.Node => {
        return (
            <div className="filming-application__form-wrapper row">
                <ReactForm
                    validators={customValidators}
                    disableSubmitUntilValid={false}
                    onSuccess={(
                        data: Object,
                        state: any,
                        e: any,
                        formDataObject: any
                    ) => {
                        submitForm(formDataObject);
                    }}
                    className="filming-application__form"
                >
                    <fieldset className="react-form__section">
                        <div className="heading-3">About this form</div>
                        <div className="react-form__element react-form__rte rich-text">
                            <ol>
                                <li>
                                    This form is to apply for Low to High
                                    Category Filming and Photography activity
                                    within any Place Management NSW open space
                                    as per{" "}
                                    <a
                                        href="https://www.screen.nsw.gov.au/page/film-friendly-nsw"
                                        target="_blank"
                                    >
                                        New South Wales Film Friendly 2009
                                        protocol
                                    </a>
                                    .
                                </li>
                                <li>
                                    This form requires at least{" "}
                                    <strong>
                                        5 business days submission lead time
                                    </strong>
                                    . Applications that do not meet this minimum
                                    lead time requirement will be refused.
                                </li>
                                <li>
                                    Shoots requiring drones, other unmanned
                                    aerial vehicles (UAV) &/OR Road & Footway
                                    permits for road closures and pedestrian
                                    holds require a minimum of{" "}
                                    <strong>
                                        15 business days submission lead time
                                    </strong>
                                    . Applications that do not meet this minimum
                                    lead time requirement will be refused.
                                </li>
                                <li>
                                    Application fee is due and payable on
                                    submission of application and is
                                    non-refundable. Your application will not be
                                    assessed until the application fee has been
                                    paid in full.
                                </li>
                                <li>
                                    Your activity will be assessed based on the
                                    information you provide in this form.
                                </li>
                                <li>
                                    Your booking or permit is not confirmed
                                    until your payments have been processed and
                                    you have received a written confirmation
                                    from Place Management NSW representatives.
                                </li>
                                <li>
                                    Your booking or notification is not
                                    confirmed until you have received a written
                                    confirmation from Place Management NSW.
                                </li>
                                <li>
                                    Your booking or notification if approved by
                                    Place Management NSW does not provide you
                                    with permission to use any tenants buildings
                                    for your shoot, this includes internal &
                                    external facilities
                                </li>
                                <li>
                                    All fields in this form are mandatory, you
                                    must fill or upload the relevant
                                    documentation to these sections to proceed
                                    with an application submission
                                </li>
                            </ol>
                        </div>
                    </fieldset>
                    <fieldset className="react-form__section">
                        <legend className="react-form__section-label">
                            Onsite contact:
                        </legend>
                        <div className="react-form__section__container">
                            {basicField({
                                type: "text",
                                name: "FirstName",
                                label: "First Name",
                            })}
                            {basicField({
                                type: "text",
                                name: "LastName",
                                label: "Last Name",
                            })}
                            {basicField({
                                type: "text",
                                name: "Position",
                                label: "Position",
                            })}
                            {basicField({
                                type: "tel",
                                name: "Mobile",
                                label: "Mobile",
                                validators: ["phone"],
                            })}
                            {basicField({
                                type: "email",
                                name: "Email",
                                label: "Email",
                                validators: ["email"],
                            })}
                        </div>
                    </fieldset>
                    <fieldset className="react-form__section">
                        <legend className="react-form__section-label">
                            Applying organisation details
                        </legend>
                        <p>
                            Must match the insured party on the public liability
                            insurance certificate
                        </p>
                        <div className="react-form__section__container">
                            {basicField({
                                type: "text",
                                name: "OrganisationName",
                                label: "Organisation Name",
                            })}
                            {basicField({
                                type: "text",
                                name: "ABNOrACN",
                                label: "ABN/ACN",
                                validators: ["abn"],
                            })}
                            {basicField({
                                type: "tel",
                                name: "CompanyPhoneNumber",
                                label: "Company phone number",
                                validators: ["phone"],
                            })}
                            {basicField({
                                type: "email",
                                name: "CompanyEmailAddress",
                                label: "Company email address",
                                validators: ["email"],
                            })}
                        </div>
                    </fieldset>
                    <fieldset className="react-form__section">
                        <legend className="react-form__section-label">
                            Organisation address
                        </legend>
                        <div className="react-form__section__container">
                            {basicField({
                                type: "text",
                                name: "OrganisationAddress",
                                label: "Organisation address",
                            })}
                            {basicField({
                                type: "text",
                                name: "City",
                                label: "City",
                            })}
                            {selectField({
                                type: "select",
                                name: "State",
                                label: "State",
                                options: [
                                    "ACT",
                                    "NSW",
                                    "NT",
                                    "QLD",
                                    "SA",
                                    "TAS",
                                    "VIC",
                                    "WA",
                                ],
                            })}
                            {basicField({
                                type: "number",
                                name: "Postcode",
                                label: "Postcode",
                            })}
                        </div>
                    </fieldset>
                    <fieldset className="react-form__section">
                        <legend className="react-form__section-label">
                            Production information
                        </legend>
                        <div className="react-form__section__container">
                            {basicField({
                                type: "text",
                                name: "NameOfProduction",
                                label: "Name of production",
                            })}

                            {textareaField({
                                type: "textarea",
                                name: "FullDescriptionOfScenes",
                                label:
                                    "Full description of scene(s) being filmed",
                            })}
                            {selectField({
                                type: "select",
                                name: "NumberOfPeopleOnSite",
                                label: "How many people will be onsite",
                                info:
                                    "Include all crew talent agency and so on",
                                options: ["11-25", "26-50", "> 50"],
                            })}
                            {dateField({
                                type: "date",
                                name: "DateOfFilming",
                                label: "Date of filming",
                            })}
                            {timeField({
                                type: "time",
                                nameStart: "StartTime",
                                labelStart: "Start time",
                                nameEnd: "EndTime",
                                labelEnd: "End time",
                                required: true,
                            })}

                            {dateField({
                                type: "date",
                                name: "AdditionalDateOfFilming_1",
                                label:
                                    "Additional date of filming (if applicable)",
                                required: false,
                                validators: [">field:DateOfFilming"],
                            })}
                            {timeField({
                                type: "time",
                                nameStart: "StartTime_1",
                                labelStart: "Start time",
                                nameEnd: "EndTime_1",
                                labelEnd: "End time",
                                requiredDependant: "AdditionalDateOfFilming_1",
                            })}

                            {dateField({
                                type: "date",
                                name: "AdditionalDateOfFilming_2",
                                label:
                                    "Additional date of filming (if applicable)",
                                required: false,
                                validators: [
                                    ">field:AdditionalDateOfFilming_1",
                                ],
                            })}
                            {timeField({
                                type: "time",
                                nameStart: "StartTime_2",
                                labelStart: "Start time",
                                nameEnd: "EndTime_2",
                                labelEnd: "End time",
                                requiredDependant: "AdditionalDateOfFilming_2",
                            })}

                            {locationField({
                                type: "location",
                                name: "SpecificLocations",
                                label: "Specific location(s)",
                                info:
                                    "Click here to see a map of our locations",
                            })}
                            {textareaField({
                                type: "textarea",
                                name: "FullDetailsOfEquipmentAndActivities",
                                label:
                                    "Full details of equipment and description of activities/action",
                            })}
                        </div>
                    </fieldset>
                    <fieldset className="react-form__section">
                        <legend className="react-form__section-label">
                            Unit base facilities - additional fees may apply
                        </legend>
                        <p>
                            Depending on the location venue hire fees may apply.
                            Venue hire starts from $1,400.00 Inc. GST per day,
                            per venue.
                            <br /> Hire rates vary depending on location and
                            usage.
                        </p>
                        <div className="react-form__section__container">
                            {radioField({
                                type: "radio",
                                name: "RbAreYouApplyingForUnitBaseFacilities",
                                label:
                                    "Are you applying for Unit Base facilities?",
                                options: ["Yes", "No"],
                                default: "No",
                            })}
                            {inputValues[
                                "RbAreYouApplyingForUnitBaseFacilities"
                            ] === "Yes" && (
                                <>
                                    {locationField({
                                        type: "location",
                                        name: "UnitBase_SpecificLocations",
                                        label: "Specific location(s)",
                                        info:
                                            "Click here to see a map of our locations",
                                    })}
                                    {textareaField({
                                        type: "textarea",
                                        name:
                                            "UnitBase_FullDetailsOfEquipmentAndActivities",
                                        label:
                                            "Full details of equipment and description of activities/action",
                                    })}
                                </>
                            )}
                        </div>
                    </fieldset>
                    <fieldset className="react-form__section">
                        <legend className="react-form__section-label">
                            Place Management NSW Street Parking facilities -
                            additional fees apply
                        </legend>
                        <p>
                            Additional fees may apply.{" "}
                            <a
                                href="https://www.therocks.com/therocks/media/therocksmedialibrary/venue%20hire/pmnswticketparkingschemearea20181217.pdf"
                                target="_blank"
                            >
                                Place Management NSW Street Parking locations
                                can be found here
                            </a>
                            .
                            <br />
                            Parking spaces are $85.00 Inc. GST per space, per
                            day based on a regular vehicle size.
                        </p>
                        <p>
                            Place Management NSW precincts are also surrounded
                            by several parking stations where you can book paid
                            spaces direct with the operators.
                        </p>
                        <div className="react-form__section__container">
                            {radioField({
                                type: "radio",
                                name:
                                    "RbAreYouApplyingForPMNSWStreetParkingFacilities",
                                label:
                                    "Are you applying for Place Management NSW Street Parking facilities?",
                                options: ["Yes", "No"],
                                default: "No",
                            })}
                            {inputValues[
                                "RbAreYouApplyingForPMNSWStreetParkingFacilities"
                            ] === "Yes" && (
                                <>
                                    {dateField({
                                        type: "date",
                                        name: "StreetParking_DateRequired",
                                        label: "Date required",
                                    })}
                                    {timeField({
                                        type: "time",
                                        nameStart: "StreetParking_StartTime",
                                        labelStart: "Start time",
                                        nameEnd: "StreetParking_EndTime",
                                        labelEnd: "End time",
                                        required: true,
                                    })}
                                    {dateField({
                                        type: "date",
                                        name:
                                            "StreetParking_AdditionalDateRequired_1",
                                        label:
                                            "Additional date required (if applicable)",
                                        required: false,
                                    })}
                                    {timeField({
                                        type: "time",
                                        nameStart: "StreetParking_StartTime_1",
                                        labelStart: "Start time",
                                        nameEnd: "StreetParking_EndTime_1",
                                        labelEnd: "End time",
                                        requiredDependant:
                                            "StreetParking_AdditionalDateRequired_1",
                                    })}
                                    {dateField({
                                        type: "date",
                                        name:
                                            "StreetParking_AdditionalDateRequired_2",
                                        label:
                                            "Additional date required (if applicable)",
                                        required: false,
                                    })}
                                    {timeField({
                                        type: "time",
                                        nameStart: "StreetParking_StartTime_2",
                                        labelStart: "Start time",
                                        nameEnd: "StreetParking_EndTime_2",
                                        labelEnd: "End time",
                                        requiredDependant:
                                            "StreetParking_AdditionalDateRequired_2",
                                    })}

                                    <ReactFormGroup
                                        name="parkingLocationGroup"
                                        label="Location(s)"
                                        // info="Please select at least 1 parking location."
                                        minCheckboxes="1"
                                    >
                                        {apiResponse.StreetParking_Locations.map(
                                            (val: object): object => {
                                                return checkboxField({
                                                    type: "checkbox",
                                                    options: [
                                                        {
                                                            name: val.Name,
                                                            label: val.Label,
                                                        },
                                                    ],
                                                });
                                            }
                                        )}
                                    </ReactFormGroup>

                                    {/* {checkboxField({
                                        type: "checkbox",
                                        label: "Location(s)",
                                        options: apiResponse.StreetParking_Locations.map(
                                            (val: object): object => {
                                                return {
                                                    name: val.Name,
                                                    label: val.Label,
                                                };
                                            }
                                        ),
                                    })} */}

                                    {selectField({
                                        type: "select",
                                        name:
                                            "StreetParking_NumberOfSpacesRequired",
                                        label: "Number of spaces required",
                                        options: [
                                            "1",
                                            "2",
                                            "3",
                                            "4",
                                            "5",
                                            "6",
                                            "7",
                                            "8",
                                            "9",
                                            "10",
                                            "11",
                                            "12",
                                            "13",
                                            "14",
                                            "15",
                                        ],
                                    })}
                                    {textareaField({
                                        type: "textarea",
                                        name:
                                            "StreetParking_VehiclePlateNumbers",
                                        label: "Vehicle Plate Numbers",
                                    })}
                                    {textareaField({
                                        type: "textarea",
                                        name: "StreetParking_OtherDetails",
                                        placeholder:
                                            "Such as barricade/ reservation plan",
                                        label: "Other details",
                                    })}
                                    {checkboxField({
                                        type: "checkbox",
                                        required: true,
                                        options: [
                                            {
                                                name:
                                                    "ChkAcknowledgeAndUnderstandStreetParking",
                                                label:
                                                    "Yes - I acknowledge and understand the following related to Parking Cancellation",
                                            },
                                        ],
                                    })}

                                    <div className="react-form__element react-form__rte react-form__rte--small rich-text">
                                        <p className="react-form__field__label   react-form__label-title">
                                            Parking cancellation acknowledgement
                                        </p>

                                        <p>
                                            The applicant may cancel the
                                            application by notice in writing to
                                            PMNSW application representatives at
                                            any stage prior to the date of
                                            commencement. If the cancellation is
                                            received 24 hours prior to the date
                                            of commencement, the parking fees
                                            will be withheld in full.
                                        </p>
                                        <p>
                                            The applicant acknowledges that this
                                            location is in the public domain and
                                            that PMNSW may be unable to provide
                                            the applicant with use and
                                            occupation of the location due to
                                            circumstances beyond its reasonable
                                            control, including but not limited
                                            to, an emergency or unforeseen
                                            urgent requirement.{" "}
                                        </p>
                                        <p style={{ paddingBottom: 30 }}>
                                            Where PMNSW is unable to provide use
                                            and occupation of the location due
                                            to unforeseen circumstances the
                                            applicant will be provided a refund
                                            of the parking charges paid. Parking
                                            cancellation notification email{" "}
                                            <a href="mailto:pmnsw@cbre.com.au">
                                                pmnsw@cbre.com.au
                                            </a>
                                        </p>
                                    </div>
                                </>
                            )}
                        </div>
                    </fieldset>
                    <fieldset className="react-form__section">
                        <legend className="react-form__section-label">
                            Essential Vehicle/s - additional fees apply
                        </legend>
                        <p>
                            An essential vehicle is one that must be part of
                            your shoot. This would apply to vehicles that are
                            part of the shoot content such as a vehicle being
                            photographed for a car manufacturers campaign or a
                            link truck requiring line of sight to the filming
                            location.
                        </p>
                        <p>
                            Vehicles that are not deemed as essential on review
                            of your request will not be permitted access to
                            pedestrianised public domain areas.
                        </p>
                        <p>
                            If you require access to pedestrianised public
                            domain areas additional fees apply for ranger team
                            escort and vehicle movement assistance. Ranger
                            assistance is $72.00 per hour with a minimum 4 hour
                            call out applicable per shift. Hours required will
                            be determined on review of your application
                            information.
                        </p>
                        <div className="react-form__section__container">
                            {radioField({
                                type: "radio",
                                name: "RbAreYouApplyingForEssentialVehicles",
                                label:
                                    "Are you applying for essential vehicle/s?",
                                options: ["Yes", "No"],
                                default: "No",
                            })}
                            {inputValues[
                                "RbAreYouApplyingForEssentialVehicles"
                            ] === "Yes" && (
                                <>
                                    {dateField({
                                        type: "date",
                                        name: "EssentialVehicles_DateRequired",
                                        label: "Date required",
                                    })}
                                    {timeField({
                                        type: "time",
                                        nameStart:
                                            "EssentialVehicles_StartTime",
                                        labelStart: "Start time",
                                        nameEnd: "EssentialVehicles_EndTime",
                                        labelEnd: "End time",
                                        required: true,
                                    })}
                                    {dateField({
                                        type: "date",
                                        name:
                                            "EssentialVehicles_AdditionalDateRequired_1",
                                        label:
                                            "Additional date required (if applicable)",
                                        required: false,
                                    })}
                                    {timeField({
                                        type: "time",
                                        nameStart:
                                            "EssentialVehicles_StartTime_1",
                                        labelStart: "Start time",
                                        nameEnd: "EssentialVehicles_EndTime_1",
                                        labelEnd: "End time",
                                        requiredDependant:
                                            "EssentialVehicles_AdditionalDateRequired_1",
                                    })}
                                    {dateField({
                                        type: "date",
                                        name:
                                            "EssentialVehicles_AdditionalDateRequired_2",
                                        label:
                                            "Additional date required (if applicable)",
                                        required: false,
                                    })}
                                    {timeField({
                                        type: "time",
                                        nameStart:
                                            "EssentialVehicles_StartTime_2",
                                        labelStart: "Start time",
                                        nameEnd: "EssentialVehicles_EndTime_2",
                                        labelEnd: "End time",
                                        requiredDependant:
                                            "EssentialVehicles_AdditionalDateRequired_2",
                                    })}
                                    {locationField({
                                        type: "location",
                                        name: "EssentialVehicles_Locations",
                                        label: "Specific location(s)",
                                        info:
                                            "Click here to see a map of our locations",
                                    })}
                                    {selectField({
                                        type: "select",
                                        name:
                                            "EssentialVehicles_NumberOfSpacesRequired",
                                        label: "Number of vehicles required",
                                        options: ["< 4", "4 - 10", "> 10"],
                                    })}
                                    {textareaField({
                                        type: "textarea",
                                        name:
                                            "EssentialVehicles_VehiclePlateNumbers",
                                        label: "Vehicle Plate Numbers",
                                    })}
                                    {textareaField({
                                        type: "textarea",
                                        name: "EssentialVehicles_OtherDetails",
                                        label:
                                            "Detail on why vehicle/s are essential to the shoot* ",
                                    })}
                                </>
                            )}
                        </div>
                    </fieldset>
                    <fieldset className="react-form__section">
                        <legend className="react-form__section-label">
                            Drones or other unmanned aerial vehicles (UAV) -
                            additional fees apply
                        </legend>
                        <p>
                            PMNSW Drone Permit Assessment Administration Fee
                            $150.00 Inc. GST. This is a non-refundable permit
                            assessment fee and does not constitute approval for
                            equipment operation.
                        </p>{" "}
                        <p>
                            Place Management NSW (PMNSW) generally do not allow
                            drones to be flown within PMNSW managed areas for
                            safety reasons. The Civil Aviation Safety Authority
                            (CASA) do not allow drone operations within 30m of
                            any member of the public (CASR 101.238) which is
                            largely unavoidable across all PMNSW managed
                            locations.
                        </p>
                        <p>
                            {" "}
                            For drone operation consideration you are required
                            to hold the relevant Civil Aviation Safety Authority
                            (CASA) approvals. Further information and licensing
                            procedure queries can be directed to the{" "}
                            <a href="https://www.casa.gov.au/" target="_blank">
                                Civil Aviation Safety Authority
                            </a>{" "}
                            or phone 131 757.
                        </p>
                        <div className="react-form__section__container">
                            {radioField({
                                type: "radio",
                                name: "RbAreYouApplyingForDrones",
                                label:
                                    "Are you applying for the use of drones or other unmanned aerial vehicles (UAV)?",
                                options: ["Yes", "No"],
                                default: "No",
                            })}
                            {inputValues["RbAreYouApplyingForDrones"] ===
                                "Yes" && (
                                <>
                                    <div className="react-form__element react-form__element--rte">
                                        <div className="heading-4">
                                            Please note
                                        </div>
                                        <p>
                                            If you intend to fly a drone over
                                            water, it is recommended that the
                                            launch and recovery to be conducted
                                            from a vessel. You would then only
                                            require CASA permission to operate
                                            in the restricted airspace{" "}
                                            <a
                                                href="https://www.casa.gov.au/drones"
                                                target="_blank"
                                            >
                                                https://www.casa.gov.au/drones
                                            </a>
                                            .
                                        </p>
                                        <p>
                                            This does not apply to Cockle Bay,
                                            Darling Harbour which is managed by
                                            Place Management NSW (PMNSW).
                                            Application for this space must be
                                            via PMNSW Teams.
                                        </p>
                                        <p>
                                            If vehicle access is also required
                                            for your drone activity you will
                                            need to fill the essential vehicle/s
                                            section of this form.
                                        </p>
                                    </div>
                                    {dateField({
                                        type: "date",
                                        name: "Drones_DateRequired",
                                        label: "Date required",
                                    })}
                                    {timeField({
                                        type: "time",
                                        nameStart: "Drones_StartTime",
                                        labelStart: "Start time",
                                        nameEnd: "Drones_EndTime",
                                        labelEnd: "End time",
                                        required: true,
                                    })}
                                    {dateField({
                                        type: "date",
                                        name: "Drones_AdditionalDateRequired_1",
                                        label:
                                            "Additional date required (if applicable)",
                                        required: false,
                                    })}
                                    {timeField({
                                        type: "time",
                                        nameStart: "Drones_StartTime_1",
                                        labelStart: "Start time",
                                        nameEnd: "Drones_EndTime_1",
                                        labelEnd: "End time",
                                        requiredDependant:
                                            "Drones_AdditionalDateRequired_1",
                                    })}
                                    {dateField({
                                        type: "date",
                                        name: "Drones_AdditionalDateRequired_2",
                                        label:
                                            "Additional date required (if applicable)",
                                        required: false,
                                    })}
                                    {timeField({
                                        type: "time",
                                        nameStart: "Drones_StartTime_2",
                                        labelStart: "Start time",
                                        nameEnd: "Drones_EndTime_2",
                                        labelEnd: "End time",
                                        requiredDependant:
                                            "Drones_AdditionalDateRequired_2",
                                    })}
                                    {selectField({
                                        type: "select",
                                        name:
                                            "Drones_CategoryYourPermitApplication",
                                        label:
                                            "Category your permit application pertains to from the selection below",
                                        options: [
                                            "Private Use",
                                            "Government Agency",
                                            "TV/ Film Crew",
                                            "Survey Works",
                                            "Security Survey/ Works",
                                            "Other",
                                        ],
                                    })}
                                    {textareaField({
                                        type: "textarea",
                                        name:
                                            "SummaryWhyTheFlightsAreBeingProposed",
                                        label:
                                            "Clearly summarise why the flights are being proposed and what activity these drone flights support",
                                    })}
                                    <div className="react-form__element react-form__element--rte rich-text">
                                        <div className="heading-4">
                                            If your request can be considered,
                                            Drone Application Required
                                            Supporting Information that will
                                            need to be supplied by email is as
                                            follows:
                                        </div>
                                        <ul>
                                            <li>
                                                <strong>Flight Plan Map</strong>{" "}
                                                including take-off, landing, and
                                                30m radius
                                            </li>
                                            <li>
                                                <strong>
                                                    Flight Risk Assessment
                                                </strong>{" "}
                                                must be site specific
                                            </li>
                                            <li>
                                                <strong>
                                                    Pedestrian Management Plan
                                                </strong>
                                                showing exclusion zones
                                            </li>
                                            <li>
                                                <strong>
                                                    Safety Management Plan
                                                </strong>{" "}
                                                including Safety Officers
                                                details
                                            </li>
                                            <li>
                                                <strong>
                                                    Current remotely piloted
                                                    aircraft (RPA) operator’s
                                                    certificate (ReOC)
                                                </strong>
                                            </li>
                                            <li>
                                                <strong>
                                                    Detail on your Aircraft
                                                    model and take-off weight
                                                    listed on company ReOC
                                                </strong>
                                            </li>
                                            <li>
                                                <strong>
                                                    Pilots current CASA Remote
                                                    Pilot Licence (RePL)
                                                </strong>
                                            </li>
                                            <li>
                                                <strong>
                                                    Public Liability Insurance
                                                </strong>{" "}
                                                that includes 3rd Party
                                                Liability (Aviation Specific)
                                            </li>
                                            <li>
                                                <strong>
                                                    Evidence of notification to
                                                    Building Managers
                                                </strong>{" "}
                                                within 30m of any building
                                                within the flight path
                                            </li>
                                            <li>
                                                <strong>Camera package</strong>{" "}
                                                Information on Camera and other
                                                equipment in use
                                            </li>
                                        </ul>
                                    </div>
                                </>
                            )}
                        </div>
                    </fieldset>
                    <fieldset className="react-form__section">
                        <legend className="react-form__section-label">
                            Road or Temporary Footway Permits - additional fees
                            apply
                        </legend>
                        <p>
                            This applies to shoots with temporary rood closures,
                            traffic management, vehicle or pedestrian hold
                            activity. Additional fees may apply.
                        </p>

                        <div className="react-form__section__container">
                            {radioField({
                                type: "radio",
                                name:
                                    "RbAreYouApplyingForRoadOrTemporaryFootwayPermitsFacilities",
                                label:
                                    "Are you applying for Road or Temporary Footway permits facilities?",
                                options: ["Yes", "No"],
                                default: "No",
                            })}
                            {inputValues[
                                "RbAreYouApplyingForRoadOrTemporaryFootwayPermitsFacilities"
                            ] === "Yes" && (
                                <>
                                    <div className="react-form__element react-form__element--rte">
                                        <div className="heading-4">
                                            Please note
                                        </div>
                                        <p>
                                            <a
                                                href="https://www.placepermits.com.au/permit/roadfootway"
                                                target="_blank"
                                            >
                                                Place Management NSW Road &
                                                Footway Application for
                                                Temporary works registration
                                                form can be found here.
                                            </a>
                                        </p>
                                    </div>
                                    {textareaField({
                                        type: "textarea",
                                        name:
                                            "SummaryWhyYouRequireTemporaryRoadOrFootwayManagement",
                                        label:
                                            "Clearly summarise why you require temporary road or footway management",
                                    })}
                                </>
                            )}
                        </div>
                    </fieldset>
                    <fieldset className="react-form__section">
                        <legend className="react-form__section-label">
                            Logistics Details
                        </legend>
                        <p>
                            Tick any items that are applicable to your shoot.
                            Additional documentation will be required for any of
                            the below items
                        </p>

                        <div className="react-form__section__container">
                            {checkboxField({
                                type: "checkbox",
                                options: [
                                    {
                                        name: "ChkLogistics_1",
                                        label: "Car chases/ driving sequences",
                                    },
                                    {
                                        name: "ChkLogistics_2",
                                        label:
                                            "Temporary structures/ scaffolding",
                                    },
                                    {
                                        name: "ChkLogistics_3",
                                        label: "Camera Crane",
                                    },
                                    {
                                        name: "ChkLogistics_4",
                                        label: "Smoke effects",
                                    },
                                    {
                                        name: "ChkLogistics_5",
                                        label:
                                            "Working with children. <i>Note working with children documentation will be required</i>",
                                    },
                                    {
                                        name: "ChkLogistics_6",
                                        label: "Street dressing",
                                    },
                                    {
                                        name: "ChkLogistics_7",
                                        label:
                                            "Reconstruction of crime or emergency",
                                    },
                                    {
                                        name: "ChkLogistics_8",
                                        label:
                                            "Cast dressed as police or emergency services",
                                    },
                                    {
                                        name: "ChkLogistics_9",
                                        label: "Camera dolly / tracks",
                                    },
                                    {
                                        name: "ChkLogistics_10",
                                        label: "SFX / Fire / Explosives",
                                    },
                                    {
                                        name: "ChkLogistics_11",
                                        label: "Low ladders",
                                    },
                                    {
                                        name: "ChkLogistics_12",
                                        label: "Generator",
                                    },
                                    {
                                        name: "ChkLogistics_13",
                                        label: "Stunts",
                                    },
                                    {
                                        name: "ChkLogistics_14",
                                        label: "Playback",
                                    },
                                ],
                            })}
                        </div>
                    </fieldset>

                    <fieldset className="react-form__section">
                        <legend className="react-form__section-label">
                            Required Application supporting documentation
                        </legend>
                        <div className="react-form__section__container">
                            {fileField({
                                type: "file",
                                name: "PublicLiabilityInsuranceCoverFileUpload",
                                label: "Public liability insurance cover",
                                placeholder:
                                    "Upload your file (Max file size 2MB)",
                                info:
                                    "Please note: Your application will not be accepted unless a valid and current public liability insurance certificate is provided, which must match the applicant organisation listed on this application.<br/>(Max file size 2MB)",
                                validators: ["maxFileSize:2"],
                            })}
                            {fileField({
                                type: "file",
                                name: "RequestedLocationsSiteMapFileUpload",
                                label: "Requested location/s site map",
                                placeholder:
                                    "Upload your file (Max file size 5MB)",
                                info: "<br/>(Max file size 5MB)",
                                validators: ["maxFileSize:5"],
                            })}
                            {fileField({
                                type: "file",
                                name: "ProductionScheduleFileUpload",
                                label: "Production schedule",
                                placeholder:
                                    "Upload your file (Max file size 3MB)",
                                info: "<br/>(Max file size 3MB)",
                                validators: ["maxFileSize:3"],
                            })}
                            {fileField({
                                type: "file",
                                name: "Covid19SafePlanFileUpload",
                                label: "Covid-19 – Covid-Safe Plan",
                                placeholder:
                                    "Upload your file (Max file size 3MB)",
                                info:
                                    "Any approved activities taking place on Place Management NSW land must ensure compliance with state and federal government directives and restrictions in relation to Covid-19.<br/>(Max file size 3MB)",
                                validators: ["maxFileSize:3"],
                            })}
                        </div>
                    </fieldset>
                    <fieldset className="react-form__section">
                        <legend className="react-form__section-label">
                            Other supporting documentation (as applicable)
                        </legend>
                        <div className="react-form__section__container">
                            {fileField({
                                type: "file",
                                name:
                                    "WorkersCompensationCertificateFileUpload",
                                label: "Workers Compensation Certificate",
                                placeholder:
                                    "Upload your file (Max file size 2MB)",
                                info:
                                    "Required where there are paid workers on your shoot that are not independent contractors. <br/>(Max file size 2MB)",
                                validators: ["maxFileSize:2"],
                                required: false,
                            })}
                            {fileField({
                                type: "file",
                                name:
                                    "TenantAndResidentNotificationLetterFileUpload",
                                label:
                                    "Tenant and resident notification letter",
                                info:
                                    "For review where your activity will impact on a tenant &/or resident location. <br/>(Max file size 2MB)",
                                placeholder:
                                    "Upload your file (Max file size 2MB)",
                                validators: ["maxFileSize:2"],
                                required: false,
                            })}
                            {fileField({
                                type: "file",
                                name: "WorkingWithChildrenCheckFileUpload",
                                label:
                                    "Working with Children Check (WWCC) documentation ",
                                info:
                                    "Where your shoot involves working with minors.<br/>(Max file size 2MB)",
                                placeholder:
                                    "Upload your file (Max file size 2MB)",
                                validators: ["maxFileSize:2"],
                                required: false,
                            })}
                            {fileField({
                                type: "file",
                                name: "WorkingWithAnimalsCertificateFileUpload",
                                label: "Working with Animals Certificate",
                                info:
                                    "Where your shoot involves working with animals.<br/>(Max file size 2MB)",
                                placeholder:
                                    "Upload your file (Max file size 2MB)",
                                validators: ["maxFileSize:2"],
                                required: false,
                            })}
                        </div>
                    </fieldset>

                    <fieldset className="react-form__section">
                        <legend className="react-form__section-label">
                            Service charges
                        </legend>
                        <div className="react-form__section__container">
                            <div className="react-form__element react-form__rte react-form__rte--small rich-text">
                                <p>
                                    Service charges may apply to your shoot and
                                    will be advised on review of your
                                    application. Service charges that may be
                                    applicable:
                                </p>
                                <ul>
                                    <li>
                                        Security, Rangers, Site Representative
                                        or cleaning staff: $72.00 per hour,
                                        minimum 4 hour call out applicable per
                                        shift
                                        <ul>
                                            <li>
                                                10% loading for call times
                                                commencing between 4am and 6am;
                                                15% loading for call times
                                                commencing between 10pm and 4am;
                                                250% loading for public
                                                holidays.
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        Upon request, other services such as
                                        site alteration, equipment provision,
                                        maintenance, etc. will be charged as per
                                        approved quotes.
                                    </li>
                                </ul>
                            </div>

                            {checkboxField({
                                type: "checkbox",
                                required: true,
                                options: [
                                    {
                                        name:
                                            "ChkAcknowledgeAndUnderstandTheServiceCharges",
                                        label:
                                            "Yes - I acknowledge and understand the service charges that may be applicable to my shoot",
                                    },
                                ],
                            })}
                        </div>
                    </fieldset>

                    <fieldset className="react-form__section">
                        <legend className="react-form__section-label">
                            Cancellations and unsuccessful applications
                        </legend>
                        <div className="react-form__section__container">
                            <div className="react-form__element react-form__rte react-form__rte--small rich-text">
                                <p>
                                    Once an application is received and
                                    processed, cancellations will only be
                                    accepted in writing.
                                </p>
                                <p>
                                    No refund of fees (additional to the
                                    application fee) will be issued for
                                    cancellations that are received less than 5
                                    working days before the first shoot date.
                                </p>
                                <p style={{ paddingBottom: 30 }}>
                                    The application fee is non-refundable for
                                    cancellations or refused applications.
                                </p>
                            </div>
                        </div>
                    </fieldset>

                    <fieldset className="react-form__section">
                        <legend className="react-form__section-label">
                            Applicant Declaration
                        </legend>
                        <div className="react-form__section__container">
                            {checkboxField({
                                type: "checkbox",
                                required: true,
                                options: [
                                    {
                                        name: "ChkAcknowledgeAndUnderstand",
                                        label:
                                            "Yes - I acknowledge and understand the following related to this application",
                                    },
                                ],
                            })}

                            <div className="react-form__element react-form__rte react-form__rte--small rich-text">
                                <ul>
                                    <li>
                                        The application fee must be paid in full
                                        for the application to be reviewed. This
                                        is a non-refundable fee. This fee will
                                        not be refunded for cancellations or
                                        refused applications.
                                    </li>
                                    <li>
                                        If the application is missing required
                                        supporting documentation the application
                                        will be refused.
                                    </li>
                                    <li>
                                        You are applying for a Low to High
                                        category sized shoot which requires a
                                        minimum of 5 business days submission
                                        lead time. Applications that do not meet
                                        this minimum lead time requirement will
                                        be refused.
                                    </li>
                                    <li>
                                        Shoots requiring drones, other unmanned
                                        aerial vehicles (UAV) &/OR Road &
                                        Footway permits for road closures and
                                        pedestrian holds require a minimum of 15
                                        business days submission lead time.
                                        Applications that do not meet this
                                        minimum lead time requirement will be
                                        refused.
                                    </li>
                                    <li>
                                        The Applicant’s responsibilities and
                                        obligations are recognised under The
                                        Local Government Filming Protocol 2009
                                        (Protocol) and Code of Conduct for
                                        location filming in NSW (Code of
                                        Conduct).
                                    </li>
                                    <li>
                                        Place Management NSW (PMNSW) supports
                                        the Protocol and Code of Conduct and
                                        will issue a permit for associated
                                        filming activities once all requirements
                                        of the Protocol and Code of Conduct are
                                        met.
                                    </li>
                                    <li>
                                        The permit may stipulate additional
                                        conditions. It is the responsibility of
                                        the applicant to ensure all cast and
                                        crew are thoroughly briefed on all
                                        conditions and requirements of the
                                        permit and appropriately manage all
                                        elements of the shoot to ensure there is
                                        no breech of the permit or undue impact
                                        on the amenity of the public domain and
                                        surrounding tenants/residents
                                    </li>
                                    <li>
                                        {" "}
                                        Place Management NSW takes no
                                        responsibility for any third-party
                                        commitment entered into by the applicant
                                    </li>
                                    <li>
                                        Place Management NSW manages locations
                                        in The Rocks, Darling Harbour, some
                                        areas of Circular Quay and some areas of
                                        Pyrmont. It is the applicants
                                        responsibility to ensure they have the
                                        appropriate landowner permissions for
                                        locations that fall outside of Place
                                        Management NSW approval areas.
                                    </li>
                                    <li>
                                        Your permit if approved by Place
                                        Management NSW does not provide you with
                                        permission to use any tenants buildings
                                        for your shoot, this includes internal &
                                        external facilities
                                    </li>
                                </ul>
                            </div>

                            {checkboxField({
                                type: "checkbox",
                                required: true,
                                options: [
                                    {
                                        name: "ChkAcceptTerms",
                                        label: `Yes - I confirm and accept the <a
                                            href='/privacy-policy'
                                            target='_blank'
                                        >terms of this form</a>`,
                                    },
                                ],
                            })}
                        </div>
                    </fieldset>

                    <ReactFormField
                        value={inputValues["GoogleRecaptchaResponse"] || ""}
                        type="text"
                        fieldComponent={RecaptchaField}
                        required={true}
                        recaptchaSiteKey={props.recaptchaSiteKey}
                        name="GoogleRecaptchaResponse"
                        onChange={(event: any) => {
                            setInputValues({
                                [event.target.name]: event.target.value,
                            });
                        }}
                    ></ReactFormField>

                    <button
                        type="submit"
                        className="react-form__button cta cta--inline cta--big"
                    >
                        Submit
                    </button>
                </ReactForm>
            </div>
        );
    };

    return (
        <div className="filming-application form-builder">
            <div className="container-fluid">
                <div className="col-xs-12 col-md-8">
                    {serverError && (
                        <div className="form-builder__servererror is-active">
                            {serverError}
                        </div>
                    )}
                    {apiResponse && (
                        <div
                            style={{
                                display: loadingState === 0 ? "block" : "none",
                            }}
                        >
                            {props.formName === "VenueUltraLowFormCustom" &&
                                ultraLow()}
                            {props.formName === "VenueLowHighFormCustom" &&
                                lowHigh()}
                        </div>
                    )}
                    {loadingState === 1 && <div></div>}
                    {loadingState === 2 && (
                        <Loader
                            active={true}
                            fixed={true}
                            dark={true}
                            content={
                                <>
                                    <p>
                                        Please wait while we process your
                                        submission.
                                    </p>
                                    <p>Do not refresh the page.</p>
                                </>
                            }
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default FilmingApplication;
