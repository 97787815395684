// @flow
import wrapEl from "@utils/wrapEl";

class RichText {
    $el: HTMLElement;

    constructor(el: HTMLElement) {
        this.$el = el;

        const tables = this.$el.querySelectorAll("table");
        for (const table of tables) {
            const outer = document.createElement("div");
            const inner = document.createElement("div");
            outer.classList.add("table-wrapper");
            inner.classList.add("table-wrapper__inner");
            wrapEl(table, outer);
            wrapEl(table, inner);
        }
    }
}

export default RichText;
