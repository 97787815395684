// @flow
let bodyScrollPosition = 0;
const body = document.querySelector("body");

export const enableBodyScroll = () => {
    if (body) {
        body.classList.remove("prevent-scrolling");
        body.style.top = "auto";
        window.scroll(null, bodyScrollPosition);
    }
};

export const disableBodyScroll = () => {
    if (body) {
        bodyScrollPosition = window.scrollY;
        body.classList.add("prevent-scrolling");
        body.style.top = `${bodyScrollPosition * -1}px`;
    }
};
