import React from "react";

const parentClassname = "react-form";

const Field = props => {
    const componentClassname = parentClassname
        ? `${parentClassname}__field`
        : "field";

    const labelPlaceholderClassname = props.labelplaceholder
        ? `${componentClassname}__label--labelplaceholder`
        : "";

    const hasErrors =
        !props.hideErrors &&
        props.errors &&
        props.errors.length &&
        props.showErrors;

    return (
        <div
            className={`${componentClassname} 
				${componentClassname}--${props.type}
				${props.classes.inheritedClasses} 
				${props.classes.isDisabled} 
				${props.classes.isIncomplete} 
				${props.classes.isFocused} 
				${props.classes.hasValue}
				${props.classes.isValid}
                ${props.classes.isInvalid}
                ${props.checked ? "is-checked" : ""}
			`}
        >
            <label htmlFor={props.accessibilityIds.input}>
                {/*label*/}
                {props.label ? (
                    <div
                        className={`${componentClassname}__label  ${labelPlaceholderClassname}`}
                    >
                        <div
                            className={`${componentClassname}__label__inner`}
                            id={props.accessibilityIds.label}
                        >
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: props.label,
                                }}
                            ></div>
                            {props.required ? null : !props.hideOptional ? (
                                <span
                                    className={`${componentClassname}__label__optional-label`}
                                ></span>
                            ) : null}
                        </div>
                    </div>
                ) : null}
                <div
                    className={`${componentClassname}__input-container ${
                        props.icon ? "has-icon" : ""
                    }`}
                >
                    {props.icon ? (
                        <label
                            htmlFor={props.accessibilityIds.input}
                            className={`${componentClassname}__input-icon`}
                        >
                            <i className={`icon icon-${props.icon}`}></i>
                        </label>
                    ) : null}
                    {/*input element*/}
                    {React.cloneElement(props.inputElement, {
                        "aria-labelledby": props.label
                            ? props.accessibilityIds.label
                            : null,
                        "aria-describedby": props.info
                            ? props.accessibilityIds.description
                            : null,
                    })}
                    {/*password toggle btn*/}
                    {props.passwordToggleBtn}
                    {/*checkbox indicator*/}
                    {props.type === "checkbox" ? (
                        <div
                            className={`${componentClassname}__checkbox-indicator`}
                        />
                    ) : null}
                    {/*radio indicator*/}
                    {props.type === "radio" ? (
                        <div
                            className={`${componentClassname}__radio-indicator`}
                        />
                    ) : null}
                    {/*seelct indicator*/}
                    {props.type === "select" ? (
                        <div
                            className={`${componentClassname}__select-indicator`}
                        />
                    ) : null}
                    {/* Error icon */}
                    {hasErrors ? (
                        <div className={`${componentClassname}__error-icon`}>
                            <i className="icon icon-alert-triangle"></i>
                        </div>
                    ) : null}
                </div>
            </label>

            {/*errors*/}
            {hasErrors ? (
                <div className={`${componentClassname}__errors`}>
                    <div
                        className={`${componentClassname}__errors__item`}
                        id={props.accessibilityIds.error}
                    >
                        Error: {props.errors[0]}
                    </div>
                </div>
            ) : null}

            {/*info*/}
            {props.info ? (
                <div
                    className={`${componentClassname}__info`}
                    id={props.accessibilityIds.description}
                    dangerouslySetInnerHTML={{ __html: props.info }}
                ></div>
            ) : null}
        </div>
    );
};

export default Field;
