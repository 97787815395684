// @flow

// import _ from "lodash";
import AnimateHeight from "@modules/AnimateHeight";
import { getBreakpoint } from "@utils/responsive";

class Reveal {
    $el: HTMLElement;
    $button: any;
    $outerContainer: any;
    $collapsibleContainer: any;
    $collapsibleContent: any;
    collapsedHeight: number;
    minDifference: number;
    moreLabel: string;
    lessLabel: string;
    buttonClasses: string;
    collapsed: boolean;
    mobileOnly: boolean;

    constructor(el: HTMLElement) {
        this.$el = el;
        this.collapsedHeight = Number(
            this.$el.getAttribute("data-reveal-collapsed-height") || 0
        );
        this.minDifference = Number(
            this.$el.getAttribute("data-reveal-min-difference") || 0
        );
        this.moreLabel =
            this.$el.getAttribute("data-reveal-more-label") || "Read more";
        this.lessLabel =
            this.$el.getAttribute("data-reveal-less-label") || "Read less";
        this.buttonClasses =
            this.$el.getAttribute("data-reveal-button-classes") || "";
        this.mobileOnly =
            this.$el.getAttribute("data-reveal-mobile-only") === "true";
        this.collapsed = false;

        this.prepareMarkup();
        this.init();

        window.getBreakpoint = getBreakpoint;
    }

    prepareMarkup() {
        let innerHTML = `
        <div class="reveal reveal--disabled">
            <div class="reveal__collapsible-container">
                <div class="reveal__collapsible-content">
                    ${this.$el.innerHTML}
                </div>
            </div>
            <button class="reveal__button ${this.buttonClasses}" aria-hidden="true">
                <span>${this.lessLabel}</span><span>${this.moreLabel}</span>
            </button>
        </div>`;
        this.$el.innerHTML = innerHTML;
    }

    init() {
        this.$outerContainer = this.$el.querySelector(".reveal");

        this.$collapsibleContainer = this.$el.querySelector(
            ".reveal__collapsible-container"
        );

        this.$collapsibleContent = this.$el.querySelector(
            ".reveal__collapsible-content"
        );

        this.$collapsibleContainer &&
            new AnimateHeight(this.$collapsibleContainer);

        this.$button = this.$el.querySelector(".reveal__button");
        this.$button &&
            this.$button.addEventListener(
                "click",
                this.toggleReveal.bind(this)
            );

        this.activationCheck();
    }

    activationCheck() {
        const exceedsHeight =
            this.$collapsibleContainer.clientHeight >
            this.collapsedHeight + this.minDifference;

        if (this.mobileOnly && getBreakpoint() === "xs" && exceedsHeight) {
            this.activate();
        } else if (!this.mobileOnly && exceedsHeight) {
            this.activate();
        } else {
            this.deactivate();
        }
    }

    activate() {
        this.$collapsibleContent.style.height = `${this.collapsedHeight}px`;
        this.$outerContainer.classList.remove("reveal--disabled");
        setTimeout(() => {
            this.$collapsibleContainer.style.transition = "all 1000ms";
        }, 1000);

        this.collapsed = true;
    }

    deactivate() {
        this.$collapsibleContent.style.height = "auto";
        this.$outerContainer.classList.add("reveal--disabled");
        this.$collapsibleContainer.style.transition = "none";
        this.collapsed = false;
    }

    toggleReveal() {
        if (this.collapsed) {
            this.$outerContainer.classList.add("state-open");
            this.$collapsibleContent.style.height = "auto";
            this.collapsed = false;
        } else {
            this.$outerContainer.classList.remove("state-open");
            this.$collapsibleContent.style.height = `${this.collapsedHeight}px`;
            this.collapsed = true;
        }
    }
}

export default Reveal;
