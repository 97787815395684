// @flow

import * as React from "react";

const { useState } = React;

export type MergableStateStateType = any;
export type MergableStateSetterType = (newState: any) => void;

const useMergableState = (
    initialState: any
): [MergableStateStateType, MergableStateSetterType] => {
    const [state, _setState] = useState(initialState);

    const setState = (newStatePartial: any): any => {
        _setState((old: any): any => ({
            ...old,
            ...newStatePartial,
        }));
    };

    return [state, setState];
};

export default useMergableState;
