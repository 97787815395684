// @flow

import * as React from "react";

type PropsType = {
    options: Array<any>,
    onChange?: Function,
    label?: string,
};

const { useState } = React;

const Tabs = (props: PropsType): React.Node => {
    if (props.options.length === 0) {
        return null;
    }
    const [selected, setSelected] = useState(props.options[0]);
    return (
        <div className="tabs" role="group" aria-labelledby={props.label}>
            {props.options.map((option: any): React.Node => (
                <button
                    type="button"
                    className={`tabs__tab ${
                        option.key === selected.key ? "selected" : ""
                    }`}
                    onClick={() => {
                        setSelected(option);
                        props.onChange && props.onChange(option.key);
                    }}
                    key={option.key}
                >
                    {option.text}
                </button>
            ))}
        </div>
    );
};

export default Tabs;
