// @flow
import Lightbox from "@modules/Lightbox";
import React from "react";
import ReactDOM from "react-dom";

class AcknowledgementOfCountryModal {
    $el: HTMLElement;
    searchLightbox: any;
    $triggerTargets: any;
    $reactRoot: any;

    constructor(el: HTMLElement) {
        this.$el = el;
        this.$triggerTargets = document.querySelectorAll(
            `[data-acknowledgement-of-country]`
        );
        this.$reactRoot = null;
        this.init();
    }

    init() {
        // check if the modal was shown in the last 48 hours
        const modalShownAt = localStorage.getItem("modalShownAt");
        if (modalShownAt) {
            const modalShownTime = new Date(modalShownAt).getTime();
            const now = new Date().getTime();
            const hoursElapsed = (now - modalShownTime) / (1000 * 60 * 60);
            if (hoursElapsed < 48) {
                // the modal was shown less than 48 hours ago, don't show it again
                return;
            } else {
                this.setupLightbox();
            }
        } else {
            this.setupLightbox();
        }
    }

    setupLightbox() {
        // create the ligthtbox
        this.searchLightbox = new Lightbox({
            contents: this.$el,
            additionalClass: "lightbox--acknowledgement-of-country",
        });
        localStorage.setItem("modalShownAt", new Date().toISOString());
    }
}

export default AcknowledgementOfCountryModal;
