// @flow

export const get = (url: string): any => {
    return fetch(url)
        .then((res: Response): Promise<string> => res.json())
        .catch((error: any) => {
            console.error(error);
            throw error;
        });
};

export const post = (
    url: string,
    config: { body: {}, headers: {} }
): Promise<any> => {
    return fetch(url, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            ...config.headers,
        },
        body: JSON.stringify(config.body),
    })
        .then((res: Response): any => {
            if (res.status >= 400 && res.status < 600) {
                throw new Error("Bad response from server");
            }

            return res.json();
        })
        .catch((error: any) => {
            console.error(error);
            throw error;
        });
};
export const postUpload = (
    url: string,
    body: FormData,
    headers: Object
): Promise<any> => {
    return fetch(url, {
        method: "POST",
        headers: {
            ...headers,
        },
        body: body,
    });
};
