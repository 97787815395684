// @flow

import queryString from "query-string";
import { getQueryString } from "@utils/";
import { scrollTo } from "@utils/";
import SearchPage from "@modules/SearchPage";
import DatePicker from "@modules/DatePicker";
import React from "react";
import ReactDOM from "react-dom";
import PubSub from "../utils/pubsub";
import moment from "moment";
import Config from "../constants/Config";
import xss from "xss";

// import _ from "lodash";

class ListingPage {
    $el: HTMLElement;
    $resultsArea: any;
    $resultsTotal: ?HTMLElement;
    $resultsSearchTerm: ?HTMLElement;
    $paginationArea: ?HTMLElement;
    $inputs: ?any;
    $inputGroups: ?NodeList<HTMLElement>;
    $inputGroupSubmitBtns: ?NodeList<HTMLElement>;
    $filterToggles: ?NodeList<HTMLElement>;
    $searchField: any;
    $resultsLabel: ?HTMLElement;
    $mobileFilterCount: HTMLElement;
    $buttons: ?NodeList<HTMLElement>;
    $resultsDividerContent: ?NodeList<HTMLElement>;
    endpoint: string;
    apiSource: ?string;
    apiType: ?string;
    shouldLoadAll: ?boolean;
    resultData: any;
    isSearch: boolean;
    hasDatepicker: boolean;
    $datepickers: NodeList<HTMLElement>;
    $datepickerIcons: NodeList<HTMLElement>;
    subscription: any;

    constructor(el: HTMLElement) {
        // elements
        this.$el = el;
        this.$inputs = this.$el.querySelectorAll("[data-listing-page-input]");
        this.$inputGroups = this.$el.querySelectorAll(
            "[data-listing-page-filter-group]"
        );
        this.$inputGroupSubmitBtns = this.$el.querySelectorAll(
            "[data-listing-page-filter-group-submit]"
        );
        this.$searchField = this.$el.querySelector('input[type="search"]');
        this.$buttons = this.$el.querySelectorAll("button");
        this.$resultsArea = this.$el.querySelector(
            "[data-listing-page-results]"
        );
        this.$resultsTotal = this.$el.querySelector(
            "[data-listing-page-total]"
        );
        this.$resultsSearchTerm = this.$el.querySelector(
            "[data-listing-page-query]"
        );
        this.$resultsLabel = this.$el.querySelector(
            "[data-listing-results-label]"
        );
        this.$mobileFilterCount =
            this.$el.querySelector("[data-listing-mobile-filter-count]") ||
            document.createElement("span");
        this.$paginationArea = this.$el.querySelector(
            "[data-listing-page-pagination]"
        );
        this.$filterToggles = this.$el.querySelectorAll(
            ".filter-dropdown__toggle"
        );
        this.$resultsDividerContent = this.$resultsArea.querySelector(
            "[data-listing-page-results-divider]"
        );
        this.$datepickers = this.$el.querySelectorAll("[data-datepicker]");
        this.$datepickerIcons = this.$el.querySelectorAll(".date-input .icon");
        this.hasDatepicker = this.$datepickers.length !== 0;

        // constants
        this.endpoint = this.$el.getAttribute("data-listing-page") || "";
        this.apiSource =
            this.$el.getAttribute("data-listing-page-source") || "";
        this.apiType = this.$el.getAttribute("data-listing-page-type");
        this.shouldLoadAll =
            this.$el.getAttribute("data-listing-page-load-all") === "true"
                ? true
                : false;
        this.isSearch = this.endpoint.indexOf("/search/") >= 0;

        // startup
        this.init();
        this.attachEvents();

        if (this.hasDatepicker && this.$datepickers.length > 0) {
            //initDatepicker
            this.initDatepicker();
        }
    }

    init() {
        this.formatLabels();
        this.setInputStateFromUrl();

        this.fetchPage();
    }

    initDatepicker() {
        this.$datepickers.forEach(($datepickerEl: HTMLElement): any => {
            if ($datepickerEl) {
                const id =
                    $datepickerEl.getAttribute("data-datepicker-id") || "date";
                ReactDOM.render(
                    <DatePicker
                        disablePastDays={true}
                        datepickerId={id}
                        debug={false}
                    />,
                    $datepickerEl
                );
            }
        });

        const $datepickerRadio = this.$el.querySelector(
            ".date-range-radio .indicator"
        );

        // Registers Radio Toggle for "select dates" to also toggle filtergroup checkbox so the dropdown opens
        if ($datepickerRadio) {
            $datepickerRadio.addEventListener("click", () => {
                const $daterangeCheckbox = this.getClosest(
                    $datepickerRadio,
                    `[data-listing-page-date-filter-group]`
                ).querySelector(".filter-dropdown__toggle");
                $daterangeCheckbox.checked = !$daterangeCheckbox.checked;
            });
        }

        this.subscription = PubSub.subscribe(`datepicker`, (data: any) => {
            this.handleDatepickerEvent(data);
        });
        setTimeout(() => {
            PubSub.publish(`datepicker`, {
                id: "date-from",
                type: "availabilityCheckRequest",
                date: this.getDate("from"),
            });
            PubSub.publish(`datepicker`, {
                id: "date-to",
                type: "availabilityCheckRequest",
                date: this.getDate("to"),
            });
        }, 1000);
    }

    attachEvents() {
        // bind window history change event
        window.onpopstate = (event: Object) => {
            // set input values from query paramaters
            this.setInputStateFromUrl();
            // fetch new page using document.location.search
            this.fetchPage(true);
        };

        // Bind filter inputs (only those not in input groups)
        if (this.$inputs) {
            this.$inputs.forEach(($inputEl: HTMLElement): any => {
                if (
                    !this.getClosest(
                        $inputEl,
                        "[data-listing-page-filter-group]"
                    )
                ) {
                    $inputEl.addEventListener(
                        "change",
                        this.handleInputChange.bind(this)
                    );
                } else if (
                    this.getClosest(
                        $inputEl,
                        ".date-container [data-listing-page-filter-group]"
                    )
                ) {
                    $inputEl.addEventListener(
                        "change",
                        this.handleInputChange.bind(this)
                    );
                }
            });
        }

        // Bind filter group submit buttons
        if (this.$inputGroupSubmitBtns) {
            this.$inputGroupSubmitBtns.forEach(
                ($inputSubmitEl: HTMLElement): any => {
                    $inputSubmitEl.addEventListener(
                        "click",
                        this.handleInputGroupSubmit.bind(this)
                    );
                }
            );
        }

        if (this.$buttons) {
            this.$buttons.forEach(($buttonEl: HTMLElement): any => {
                $buttonEl.addEventListener(
                    "click",
                    this.handleButtonClick.bind(this)
                );
            });
        }

        // Filter Toggles (toggle overflow of parent filters )
        if (this.$filterToggles) {
            this.$filterToggles.forEach(($filterToggleEl: HTMLElement): any => {
                $filterToggleEl.addEventListener("change", (e: any) => {
                    const parentPane = this.$el.querySelector(
                        ".listing-page__filter-pane"
                    );
                    if (parentPane) {
                        if (e.target.checked) {
                            parentPane.classList.add("hide-overflow");
                        } else {
                            parentPane.classList.remove("hide-overflow");
                            if (this.hasDatepicker) {
                                this.closeDatepickers();
                            }
                            this.handleInputGroupSubmit(e);
                        }
                    }
                });
            });
        }

        // Toggle Datepicker
        if (this.hasDatepicker) {
            if (this.$datepickerIcons) {
                this.$datepickerIcons.forEach(($iconEl: HTMLElement): any => {
                    $iconEl.addEventListener(
                        "click",
                        this.toggleDatepicker.bind(this)
                    );
                });
            }
            const $datepickerModal = this.$el.querySelector(
                ".listing-page__datepicker-modal"
            );
            if ($datepickerModal) {
                $datepickerModal.addEventListener("click", (e: any) => {
                    const $currentDatepicker = $datepickerModal.querySelector(
                        ".datepicker.is-active"
                    );
                    if (
                        $currentDatepicker &&
                        !$currentDatepicker.contains(e.target)
                    ) {
                        this.closeDatepickers();
                    }
                });
            }
        }
    }

    formatLabels() {
        const $labels = this.$el.querySelectorAll(
            ".filter-dropdown__label__text, .listing-page__filter-label .text"
        );

        if ($labels) {
            $labels.forEach(($label: HTMLElement): any => {
                $label.textContent = ($label.textContent || "").replace(
                    /_n_/g,
                    " & "
                );
                $label.innerText = ($label.innerText || "").replace(/_/g, " ");
            });
        }
    }

    toggleDatepicker(event: any) {
        if (event.target) {
            const datepickerId = event.target.getAttribute("data-for");
            const $datepickerEl = this.$el.querySelector(
                `[data-datepicker-id="${datepickerId}"]`
            );
            const $datepickerModal = this.$el.querySelector(
                ".listing-page__datepicker-modal"
            );
            if ($datepickerEl && $datepickerModal) {
                const isOpen = $datepickerEl.classList.contains("is-active");
                this.closeDatepickers();
                $datepickerEl.classList.toggle("is-active", !isOpen);
                $datepickerModal.classList.toggle("is-active", !isOpen);
                if (!isOpen) {
                    $datepickerEl
                        .querySelector(".datepicker__days-wrapper")
                        // $FlowFixMe
                        .focus();
                }
            }
        } else {
            this.closeDatepickers();
        }
        event.preventDefault();
    }

    closeDatepickers() {
        this.$datepickers.forEach(($datepickerEl: HTMLElement): any => {
            $datepickerEl.classList.remove("is-active");
        });
        const $datepickerModal = this.$el.querySelector(
            ".listing-page__datepicker-modal"
        );
        if ($datepickerModal) {
            $datepickerModal.classList.remove("is-active");
        }
    }

    setInputStateFromUrl() {
        let parsedQueryParams = queryString.parse(document.location.search, {
            decode: false,
        });
        const queryKeysArray = Object.keys(parsedQueryParams);

        if (this.$inputs) {
            this.$inputs.forEach(($inputEl: HTMLInputElement): any => {
                const inputName = $inputEl.getAttribute("name");
                const inputValue = $inputEl.getAttribute("value");
                const inputType = $inputEl.type;

                if (queryKeysArray.includes(inputName)) {
                    const matchedQueryKeyValues = parsedQueryParams[
                        inputName
                    ].split(",");

                    // handle radio and checkbox or standard inputs
                    if (inputType === "checkbox" || inputType === "radio") {
                        // if input value exists in query params then set checked value
                        if (
                            inputValue &&
                            matchedQueryKeyValues.includes(
                                encodeURIComponent(inputValue)
                            )
                        ) {
                            // checkbox/param part is active
                            $inputEl.checked = true;
                        } else {
                            // checkbox/param part is inactive
                            $inputEl.checked = false;
                        }
                    } else {
                        // other inputs
                        $inputEl.setAttribute(
                            "value",
                            xss(decodeURI(parsedQueryParams[inputName]))
                        );
                    }
                } else {
                    // input doesnt match active query key, set empty default states
                    if (inputName === "date-from" || inputName === "date-to") {
                        // date range sub input handling
                        if (parsedQueryParams.dateRange) {
                            // 'dateRange' query param exists so set input values from param value
                            const dates = parsedQueryParams.dateRange.split(
                                "-"
                            );
                            if (inputName === "date-from") {
                                const fromDate = moment(dates[0], "YYYYMMDD")
                                    .format("DD/MM/YYYY")
                                    .replace("Invalid date", "");
                                $inputEl.setAttribute("value", fromDate);
                            } else if (inputName === "date-to") {
                                const toDate = moment(dates[1], "YYYYMMDD")
                                    .format("DD/MM/YYYY")
                                    .replace("Invalid date", "");
                                $inputEl.setAttribute("value", toDate);
                            }
                        } else {
                            // set default empty date field values
                            $inputEl.setAttribute("value", "");
                        }
                    } else if (
                        inputType === "checkbox" ||
                        inputType === "radio"
                    ) {
                        // checkbox and radio default handling
                        $inputEl.checked = false;
                    } else {
                        // standard input default handling
                        $inputEl.setAttribute("value", "");
                    }
                }
            });
        }

        this.setDropdownSelectedIndicator();
        this.setDropdownSelectedDatesIndicator();
        //this.setMobileFilterCount();
    }

    handleInputChange(event: any) {
        if (event.target.type === "checkbox") {
            this.setNewPage(
                this.setQueryParam(
                    event.target.name,
                    event.target.value,
                    event.target.checked
                )
            );
        } else if (event.target.type === "radio") {
            this.setNewPage(
                this.setQueryParam(event.target.name, event.target.value)
            );
        }
        //this.setMobileFilterCount();

        // reset page on input/filter change
        //this.setQueryParam("page", parsedHref.page);
    }

    handleInputGroupSubmit(event: any) {
        const $submitBtn = event.target;
        const $parentGroup = this.getClosest(
            $submitBtn,
            "[data-listing-page-filter-group]"
        );
        const $groupInputs = $parentGroup.querySelectorAll(
            "[data-listing-page-input]"
        );

        const isDateGroup =
            $parentGroup.getAttribute("data-listing-page-date-filter-group") !==
            null;

        // disable group dropdown/pane
        const $paneToggleCheckbox = $parentGroup.querySelector(
            ".filter-dropdown__toggle"
        );
        $paneToggleCheckbox.checked = false;

        if ($groupInputs && !isDateGroup) {
            let queryArray = [];
            $groupInputs.forEach(($inputGroupEl: HTMLInputElement): any => {
                if ($inputGroupEl.checked) {
                    queryArray.push(encodeURIComponent($inputGroupEl.value));
                }
            });
            this.setDropdownSelectedIndicator($groupInputs[0].name);

            // set filter array group param and fetch new page
            this.setNewPage(
                this.setQueryParam(
                    $groupInputs[0].name,
                    queryArray.join(","),
                    undefined,
                    true
                )
            );
        } else if (isDateGroup) {
            const $dateFrom = $parentGroup.querySelector(
                `[data-listing-page-input][name="date-from"]`
            );
            const $dateTo = $parentGroup.querySelector(
                `[data-listing-page-input][name="date-to"]`
            );
            const $radio = $parentGroup.querySelector(
                "[data-listing-page-input]"
            );
            if (
                $dateFrom instanceof HTMLInputElement &&
                $dateTo instanceof HTMLInputElement &&
                $radio instanceof HTMLInputElement
            ) {
                let queryDates = [];
                if ($dateFrom.value !== "") {
                    const formattedFrom = this.getDate("from", "YYYYMMDD");
                    queryDates.push(formattedFrom);
                } else {
                    queryDates.push("");
                }
                if ($dateTo.value !== "") {
                    const formattedTo = this.getDate("to", "YYYYMMDD");
                    queryDates.push(formattedTo);
                } else {
                    queryDates.push("");
                }
                const queryString =
                    queryDates.join("-") === "-" ? "" : queryDates.join("-");
                this.setNewPage(this.setQueryParam("dateRange", queryString));
                this.setDropdownSelectedDatesIndicator();
                if (!$radio.checked) {
                    $radio.checked = true;
                    const event = new Event("change");
                    $radio.dispatchEvent(event);
                }
            }
        }
    }

    handleDatepickerEvent(data: any) {
        if (data.type === "dateSelection") {
            const $inputfield = this.$el.querySelector(
                `[data-listing-page-input][name="${data.id}"]`
            );

            if ($inputfield instanceof HTMLInputElement) {
                $inputfield.value = data.date;
                $inputfield.focus();
            }

            if (data.id === "date-from") {
                const $dateToField = this.$el.querySelector(
                    `[data-listing-page-input][name="date-to"]`
                );
                if (
                    $dateToField instanceof HTMLInputElement &&
                    $dateToField.value === ""
                ) {
                    $dateToField.value = data.date;
                    PubSub.publish(`datepicker`, {
                        id: "date-to",
                        type: "availabilityCheckRequest",
                        date: data.date,
                    });
                }
            }
            this.closeDatepickers();
            this.checkDateValidity();
        }
    }

    handleButtonClick(event: any) {
        if (event.currentTarget.type === "reset") {
            this.setQueryParam("searchTerm", "");
            this.$searchField.value = "";
            event.preventDefault();
        } else if (event.currentTarget.type === "submit") {
            this.setNewPage(
                this.setQueryParam("searchTerm", this.$searchField.value)
            );
            event.preventDefault();
        }
    }

    handlePaginationLink(event: any) {
        event.preventDefault();
        const href = event.target.getAttribute("href");

        if (href) {
            const parsedHref = queryString.parse(
                href.replace(document.location.pathname, "")
            );

            if (parsedHref.page && parsedHref.page === "all") {
                this.fetchPage(true, true);
            } else if (parsedHref.page) {
                this.setNewPage(this.setQueryParam("page", parsedHref.page));
            }
        }
    }

    getClosest(elem: any, selector: string): any {
        // Element.matches() polyfill
        if (!window.Element.prototype.matches) {
            window.Element.prototype.matches =
                window.Element.prototype.matchesSelector ||
                window.Element.prototype.mozMatchesSelector ||
                window.Element.prototype.msMatchesSelector ||
                window.Element.prototype.oMatchesSelector ||
                window.Element.prototype.webkitMatchesSelector ||
                function(s: any): any {
                    var matches = (
                            this.document || this.ownerDocument
                        ).querySelectorAll(s),
                        i = matches.length;
                    while (--i >= 0 && matches.item(i) !== this) {}
                    return i > -1;
                };
        }

        // Get the closest matching element
        if (elem && elem.parentNode) {
            for (; elem && elem !== document; elem = elem.parentNode) {
                if (elem.matches(selector)) return elem;
            }
        }

        return null;
    }

    addToArray(array: any, value: string) {
        if (array.indexOf(value) === -1) {
            array.push(value);
        }
    }

    removeFromArray(array: any, value: string) {
        var index = array.indexOf(value);
        if (index !== -1) {
            array.splice(index, 1);
        }
    }

    setQueryParam(
        name: string,
        value: string,
        checked: ?boolean,
        encodedValue: ?boolean
    ): any {
        // get object of query params
        let parsedQueryParams = queryString.parse(document.location.search, {
            decode: false,
        });

        // pre encode the value for url param comparison
        value = encodedValue
            ? value
            : encodeURIComponent(value).replace("'", "%27");

        // convert the query paramater to an array
        let passedQueryObject = {};
        let currentQueryValue = getQueryString(name, null, true);

        if (currentQueryValue) {
            // query param exists
            passedQueryObject[name] = currentQueryValue.split(",");
        }

        if (
            typeof checked === "undefined" ||
            (!passedQueryObject[name] && checked)
        ) {
            // initial query param setting (radios, first checkbox array items)
            parsedQueryParams[name] = value;
        } else {
            // array type paramters (checkboxes)

            // convert to array if single string
            if (!Array.isArray(passedQueryObject[name])) {
                let firstOptionValue = passedQueryObject[name];
                passedQueryObject[name] = [];
                passedQueryObject[name].push(firstOptionValue);
            }

            if (checked) {
                // add to query array
                this.addToArray(passedQueryObject[name], value);
            } else {
                // remove from query array
                this.removeFromArray(passedQueryObject[name], value);
            }

            if (!passedQueryObject[name] || !passedQueryObject[name][0]) {
                // if empty query param value, clear the paramater alltogether
                delete parsedQueryParams[name];
            } else {
                // set the array back to a string in the query param object
                parsedQueryParams[name] = passedQueryObject[name].join(",");
            }
        }

        // query that was modified was not a page query so reset the page query param
        if (name !== "page") {
            delete parsedQueryParams.page;
        }

        if (value === "") {
            delete parsedQueryParams[name];
        }

        // stringify the query param object
        return queryString.stringify(parsedQueryParams, {
            encode: false,
        });
    }

    checkDateValidity() {
        const $labelFrom = this.$el.querySelector(`label[for="date-from"]`);
        const $labelTo = this.$el.querySelector(`label[for="date-to"]`);
        if ($labelFrom && $labelTo) {
            $labelFrom.classList.remove("error");
            $labelTo.classList.remove("error");
            const momentFrom = moment(this.getDate("from"), Config.dateFormat);
            const momentTo = moment(this.getDate("to"), Config.dateFormat);
            const momentToday = moment(moment());
            if (momentFrom.isBefore(momentToday, "day")) {
                $labelFrom.classList.add("error");
            }
            if (momentTo.isBefore(momentToday, "day")) {
                $labelTo.classList.add("error");
            }
            if (momentTo.isBefore(momentFrom, "day")) {
                $labelTo.classList.add("error");
            }
        }
    }

    setDropdownSelectedIndicator(groupName?: string) {
        // goes through each input group (checkbox dropdown)
        // set an indicator showing what/how many filters are selected
        if (this.$inputGroups) {
            this.$inputGroups.forEach(($inputGroup: HTMLElement): any => {
                const $subInputs = $inputGroup.querySelectorAll(
                    "[data-listing-page-input]"
                );
                const $selectedStateEl = $inputGroup.querySelector(
                    "[data-listing-page-filter-group-selected]"
                );

                if ($subInputs && $subInputs[0]) {
                    const subGroupName = $subInputs[0].getAttribute("name");

                    if (
                        !this.getClosest($inputGroup, ".date-container") &&
                        (!groupName || groupName === subGroupName)
                    ) {
                        let checkedFilters = [];
                        // loop over child inputs
                        $subInputs.forEach(($subInput: any): any => {
                            if ($subInput.checked) {
                                checkedFilters.push($subInput.value);
                            }
                        });

                        if ($selectedStateEl) {
                            // add style class
                            if (checkedFilters.length) {
                                $inputGroup.classList.add("has-values");
                            } else {
                                $inputGroup.classList.remove("has-values");
                            }

                            // set selected value, amount of selected items or clear count
                            if (checkedFilters.length > 1) {
                                $selectedStateEl.innerHTML = `${checkedFilters.length} selected`;
                            } else if (checkedFilters.length) {
                                $selectedStateEl.innerHTML = checkedFilters[0];
                            } else {
                                $selectedStateEl.innerHTML = "";
                            }
                        }
                    }
                }
            });
        }
    }

    setDropdownSelectedDatesIndicator() {
        const $selectedStateEl = this.$el.querySelector(
            ".date-container [data-listing-page-filter-group-selected]"
        );
        const $selectedStateParentEl = this.$el.querySelector(
            ".date-container .filter-dropdown__label__inner"
        );
        if ($selectedStateEl && $selectedStateParentEl) {
            const formattedFrom = this.getDate("from");
            const formattedTo = this.getDate("to");

            $selectedStateParentEl.classList.add("has-values");
            if (
                formattedFrom !== "" &&
                formattedTo !== "" &&
                formattedFrom !== formattedTo
            ) {
                $selectedStateEl.innerHTML = `${formattedFrom} – ${formattedTo}`;
            } else if (
                formattedFrom !== "" &&
                formattedTo !== "" &&
                formattedFrom === formattedTo
            ) {
                $selectedStateEl.innerHTML = `${formattedFrom}`;
            } else if (formattedFrom !== "" && formattedTo === "") {
                $selectedStateEl.innerHTML = `From ${formattedFrom}`;
            } else if (formattedFrom === "" && formattedTo !== "") {
                $selectedStateEl.innerHTML = `Now – ${formattedTo}`;
            } else {
                $selectedStateParentEl.classList.remove("has-values");
                $selectedStateEl.innerHTML = "";
            }
        }
    }

    setMobileFilterCount() {
        let filterCount = 0;

        let parsedQueryParams = queryString.parse(document.location.search, {
            decode: true,
        });

        Object.keys(parsedQueryParams).forEach((key: string) => {
            const splitArrayParam = parsedQueryParams[key].split(",");

            splitArrayParam.forEach((paramPart: string) => {
                if (key !== "dateRange") {
                    filterCount++;
                }
            });
        });
        if (this.$mobileFilterCount instanceof HTMLElement) {
            this.$mobileFilterCount.innerHTML = `${
                filterCount > 0 ? filterCount : ""
            }`;
            this.$mobileFilterCount.style.display =
                filterCount > 0 ? "flex" : "none";
        }
    }

    getDate(name: string, format: string = Config.dateFormat): string {
        const $dateFrom = this.$el.querySelector(
            `[data-listing-page-input][name="date-from"]`
        );
        const $dateTo = this.$el.querySelector(
            `[data-listing-page-input][name="date-to"]`
        );
        if (
            $dateFrom instanceof HTMLInputElement &&
            $dateTo instanceof HTMLInputElement
        ) {
            if (name === "from") {
                return moment($dateFrom.value, Config.dateFormat)
                    .format(format)
                    .replace("Invalid date", "");
            } else if (name === "to") {
                return moment($dateTo.value, Config.dateFormat)
                    .format(format)
                    .replace("Invalid date", "");
            } else {
                return "";
            }
        } else {
            return "";
        }
    }

    setNewPage(queryString: string) {
        // push the updated query string to history/url
        window.history.pushState(
            {},
            "",
            `${document.location.pathname}?${queryString}`
        );

        // fetch new page
        this.fetchPage(true);
    }

    fetchPage(newPage: ?boolean, allPages: ?boolean) {
        // get api soure sub type paramater
        let apiTypeQuery = "";
        if (this.apiType) {
            const splitApiType = this.apiType.split("|");
            apiTypeQuery = this.apiType
                ? `${splitApiType[0]}=${encodeURIComponent(splitApiType[1])}`
                : "";
        }

        // get all results if data-listing-page-load-all attribute set
        let pageSize = "";
        if (this.shouldLoadAll && allPages) {
            pageSize = "&pageSize=-1";
        }

        if (this.endpoint) {
            fetch(
                `${this.endpoint}${this.apiSource ||
                    ""}?${apiTypeQuery}${pageSize}${document.location.search.replace(
                    "?",
                    "&"
                )}`
            )
                .then((res: any): any => res.json())
                .then((data: any) => {
                    if (data) {
                        if (
                            this.resultData &&
                            this.resultData.CurrentPage !== data.CurrentPage
                        ) {
                            scrollTo(this.$resultsArea, 30, 1000);
                        }

                        this.resultData = data;
                        this.renderResults(newPage);
                        this.updatePagination();
                    }
                })
                .catch((err: any): any => {
                    console.error(err);
                });
        }
        this.setMobileFilterCount();
    }

    setResultsHtml(results: any) {
        // remove old results whilst retaining dividers - [data-listing-page-results-divider]
        Array.from(this.$resultsArea.children).forEach(
            (resultChild: any, i: any) => {
                if (
                    !resultChild.hasAttribute ||
                    (resultChild.hasAttribute &&
                        !resultChild.hasAttribute(
                            "data-listing-page-results-divider"
                        ))
                ) {
                    resultChild.remove();
                }
            }
        );

        // append new results html
        this.$resultsArea.insertAdjacentHTML("beforeend", results);
    }

    renderResults(newPage: ?boolean) {
        // result markup
        if (this.$resultsArea && "classList" in this.$resultsArea) {
            if (
                this.resultData.Results &&
                this.resultData.Results.trim() !== ""
            ) {
                if (newPage) {
                    this.$resultsArea.classList.remove("fadeIn");
                    this.$resultsArea.classList.add("fadeOut");
                    setTimeout(() => {
                        this.setResultsHtml(this.resultData.Results);

                        this.$resultsArea.classList.remove("fadeOut");
                        this.$resultsArea.classList.add("fadeIn");
                        if (this.isSearch) {
                            new SearchPage(
                                this.$resultsArea,
                                this.$searchField.value
                            );
                        }
                    }, 250);
                } else {
                    this.setResultsHtml(this.resultData.Results);

                    if (this.isSearch) {
                        new SearchPage(
                            this.$resultsArea,
                            this.$searchField.value
                        );
                    }
                }
            } else {
                this.setResultsHtml(`
                    <div data-listing-page-no-results>
                        No matching results found
                    </div>
                `);
            }
        }

        // result total number (count)
        if (this.$resultsTotal) {
            this.$resultsTotal.innerHTML = this.resultData.TotalResults || 0;
        }

        // search specific results/no results rendering logic
        if (
            this.isSearch &&
            this.$resultsLabel &&
            this.$resultsArea &&
            this.$searchField.value !== ""
        ) {
            if (this.resultData.TotalResults >= 1) {
                this.$resultsLabel.innerHTML = `Your search for <strong>${this.$searchField.value}</strong> has found ${this.resultData.TotalResults} results.`;
            } else if (this.resultData.TotalResults === 0) {
                this.$resultsLabel.innerHTML = `Your search for <strong>${this.$searchField.value}</strong> has found no results.`;

                this.setResultsHtml("");
            }
        } else if (
            this.isSearch &&
            (!this.resultData.Results ||
                (this.resultData.Results &&
                    this.resultData.Results.trim() === "")) &&
            this.$resultsLabel &&
            this.$resultsArea
        ) {
            this.$resultsLabel.innerHTML = "";
            this.setResultsHtml("");
        }

        if (window.lazyLoad) {
            setTimeout(() => {
                window.lazyLoad.update();
                window.lazyLoad.loadAll();
            }, 500);
        }
    }

    pageBulletLinkLogic($container: HTMLElement) {
        const pages = this.resultData.TotalPages;
        const activePage = this.resultData.CurrentPage;

        for (var i = 0; i < pages; i++) {
            const pageNumber = i + 1;
            let renderItem;

            if (activePage === 1) {
                //first page
                renderItem = pageNumber <= 3;
            } else if (activePage === pages) {
                //last page
                renderItem = pageNumber > pages - 3;
            } else {
                //middle page
                renderItem =
                    pageNumber >= activePage - 1 &&
                    pageNumber <= activePage + 1;
            }

            if (renderItem) {
                $container.appendChild(
                    this.createPageLink({
                        innerHTML: pageNumber,
                        ariaLabel: pageNumber,
                        page: pageNumber,
                        className: `pagination__bullet ${
                            pageNumber === activePage ? "is-active" : ""
                        }`,
                    })
                );
            }
        }
    }

    updatePagination() {
        let hasContent = false;

        const $prevNextNav = document.createElement("div");
        $prevNextNav.classList.add("pagination__prev-next");

        const $paginationNav = document.createElement("div");
        $paginationNav.classList.add("pagination__pages");

        // clear the previous pagination
        if (this.$paginationArea) {
            this.$paginationArea.innerHTML = "";
        }

        if (this.resultData.TotalPages > 1 && !this.shouldLoadAll) {
            hasContent = true;
            // normal navigationa and pagination

            //
            // Prev/Next nav
            //
            if (this.resultData.PrevPage) {
                // render prev button
                /*$prevNextNav.appendChild(
                    this.createPageLink({
                        innerHTML: "Show previous",
                        ariaLabel: "Show previous",
                        page: this.resultData.CurrentPage - 1,
                        className: "cta",
                    })
                );*/
            }
            if (this.resultData.NextPage) {
                // render next button
                $prevNextNav.appendChild(
                    this.createPageLink({
                        innerHTML: "Show Next",
                        ariaLabel: "Show Next",
                        page: this.resultData.CurrentPage + 1,
                        className: "cta",
                    })
                );
            }

            //
            // Page link nav
            //

            // first page bullet link logic
            if (
                this.resultData.CurrentPage > 2 &&
                this.resultData.TotalPages > 3
            ) {
                // first page
                $paginationNav.appendChild(
                    this.createPageLink({
                        innerHTML: "1",
                        ariaLabel: "1",
                        page: "1",
                        className: `pagination__bullet`,
                    })
                );
            }

            if (
                this.resultData.CurrentPage > 3 &&
                this.resultData.TotalPages > 4
            ) {
                // ellipsis
                $paginationNav.appendChild(
                    this.createPageLink({
                        innerHTML: "...",
                        className: `pagination__bullet`,
                    })
                );
            }

            // middle page bullet links
            this.pageBulletLinkLogic($paginationNav);

            if (
                this.resultData.CurrentPage < this.resultData.TotalPages - 2 &&
                this.resultData.TotalPages > 4
            ) {
                // ellipsis
                $paginationNav.appendChild(
                    this.createPageLink({
                        innerHTML: "…",
                        className: `pagination__bullet`,
                    })
                );
            }

            // last page bullet link logic
            if (
                this.resultData.CurrentPage < this.resultData.TotalPages - 1 &&
                this.resultData.TotalPages > 3
            ) {
                // last page
                $paginationNav.appendChild(
                    this.createPageLink({
                        innerHTML: this.resultData.TotalPages,
                        ariaLabel: this.resultData.TotalPages,
                        page: this.resultData.TotalPages,
                        className: `pagination__bullet`,
                    })
                );
            }
        } else if (this.resultData.TotalPages > 1 && this.shouldLoadAll) {
            // load all link
            hasContent = true;
            $prevNextNav.appendChild(
                this.createPageLink({
                    innerHTML: `Show all ${this.resultData.TotalResults} items`,
                    ariaLabel: `Show all ${this.resultData.TotalResults} items`,
                    page: "all",
                    className: "cta",
                })
            );
        }

        // add navs to pagination area
        if (this.$paginationArea && $prevNextNav) {
            this.$paginationArea.appendChild($prevNextNav);
        }

        if (this.$paginationArea && $paginationNav) {
            this.$paginationArea.appendChild($paginationNav);
        }

        // rebind pagination links
        if (this.$paginationArea) {
            const $paginationLinks = this.$paginationArea.querySelectorAll("a");
            if ($paginationLinks) {
                $paginationLinks.forEach(
                    ($paginationLink: HTMLElement): any => {
                        $paginationLink.addEventListener(
                            "click",
                            this.handlePaginationLink.bind(this)
                        );
                    }
                );
            }
        }

        if (this.$paginationArea) {
            this.$paginationArea.style.display = hasContent ? "block" : "none";
        }
    }

    createPageLink(options: any): HTMLElement {
        // update page query string with options.page
        let currentQueryString = queryString.parse(document.location.search);
        currentQueryString.page = options.page;
        const updatedQueryString = queryString.stringify(currentQueryString, {
            encode: false,
        });

        const cta = document.createElement("a");
        cta.setAttribute("aria-label", options.ariaLabel || "hidden");
        if (options.page) {
            cta.setAttribute(
                "href",
                `${document.location.pathname}?${updatedQueryString}`
            );
        }
        cta.setAttribute("class", options.className);
        cta.innerHTML = options.innerHTML;
        return cta;
    }
}

export default ListingPage;
