// @flow
import _ from "lodash";
import * as React from "react";

class AnimatedHeight extends React.Component {
    state = {
        height: 0,
    };
    _isMounted = false;
    outerRef = React.createRef();
    innerRef = React.createRef();
    delay = 0;
    componentDidMount() {
        this._isMounted = true;
        this.animLoop();
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    animLoop() {
        let innerRefHeight = _.get(this, "innerRef.current.clientHeight", 0);
        let outerRefHeight = _.get(this, "outerRef.current.clientHeight", 0);
        if (this.innerRef.current && this.outerRef.current) {
            const innerHeight = !isNaN(parseInt(this.props.height))
                ? parseInt(this.props.height)
                : innerRefHeight;
            if (!this.state.height || outerRefHeight !== innerHeight) {
                this.setHeightInState(innerHeight);
            }
        }
        if (this._isMounted) {
            requestAnimationFrame(() => {
                this.animLoop();
            });
        }
    }
    setHeightInState(innerHeight: any) {
        if (this.innerRef.current && this.outerRef.current) {
            this.delay =
                this.innerRef.current.clientHeight <
                this.outerRef.current.clientHeight
                    ? this.props.delayShrink || 0
                    : this.props.delayExpand || 0;
            this.setState({
                height: !isNaN(parseInt(innerHeight))
                    ? parseInt(innerHeight)
                    : "auto",
            });
        }
    }
    render(): any {
        let styleObject = {
            height: this.state.height,
            transition: `all ${this.props.duration || 500}ms`,
            transitionDelay: `${this.delay || 0}ms`,
            overflow: this.props.hideOverflow ? "hidden" : "visible",
            transform: "translateZ(0px)",
        };
        if (this.state.height === 0) {
            styleObject = {
                ...styleObject,
                borderTopWidth: 0,
                borderBottomWidth: 0,
            };
        }
        return (
            <div
                ref={this.outerRef}
                className={this.props.className}
                style={styleObject}
            >
                <div
                    ref={this.innerRef}
                    style={
                        this.state.height && this.props.expandFromCenter
                            ? {
                                  position: "relative",
                                  top: "50%",
                                  transform: "translate3d(0, -50%, 0)",
                              }
                            : {}
                    }
                >
                    {this.props.children}
                </div>
            </div>
        );
    }
}
export default AnimatedHeight;
