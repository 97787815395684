import { isMobileBreakpoint } from "@utils/responsive";
import { TweenMax, ScrollToPlugin } from "gsap/all";

const plugins = [ScrollToPlugin]; //eslint-disable-line no-unused-vars

const scrollTo = function(destination, offset = 0, duration = 200, callback) {
    const mobileNavOffset = 155;
    const desktopNavOffset = 0;
    const navOffset = isMobileBreakpoint() ? mobileNavOffset : desktopNavOffset;
    TweenMax.to(window, duration / 1000, {
        scrollTo: {
            y: destination,
            offsetY: navOffset + offset,
            autoKill: false,
        },
    }).eventCallback("onComplete", () => {
        if (callback) {
            callback();
        }
    });
};

export default scrollTo;
