import React from "react";
import { ReactForm, ReactFormField } from "@adrenalin/react-form";
const { useState, useEffect } = React;

const parentClassname = "react-form";

const TimePicker = props => {
    const componentClassname = parentClassname
        ? `${parentClassname}__field`
        : "field";

    const hasErrors =
        !props.hideErrors &&
        props.errors &&
        props.errors.length &&
        props.showErrors;

    // initial time values
    const [hours, setHours] = useState();
    const [minutes, setMinutes] = useState();
    const [amPm, setAmPm] = useState();
    const [time, setTime] = useState(null);

    // set time use effect, sets full combined time back to hidden input
    useEffect(() => {
        if (props.onChange) {
            props.onChange({
                target: {
                    value: time,
                    name: props.name,
                },
            });
        }
    }, [time]);

    // set time part use effect
    useEffect(() => {
        let hours24 = 0;
        if (amPm === "am" && Number(hours) === 12) {
            hours24 = 0;
        } else if (amPm === "am" && Number(hours) < 12) {
            hours24 = Number(hours);
        } else if (amPm === "pm" && Number(hours) === 12) {
            hours24 = 12;
        } else if (amPm === "pm" && Number(hours) < 12) {
            hours24 = Number(hours) + 12;
        }

        const secondsSinceMidnight =
            hours24 * 60 * 60 + Number(minutes) * 60 + 1;

        setTime(secondsSinceMidnight);
    }, [hours, minutes, amPm]);

    const handleHourChange = e => {
        if (e.target && e.target.value) {
            setHours(e.target.value);
        }
    };

    const handleMinutesChange = e => {
        if (e.target && e.target.value) {
            setMinutes(e.target.value);
        }
    };

    const handleAmPmChange = e => {
        if (e.target && e.target.value) {
            setAmPm(e.target.value);
        }
    };

    return (
        <div
            className={`${componentClassname} react-form__time-picker
				${componentClassname}--${props.type}
				${props.classes.inheritedClasses} 
				${props.classes.isDisabled} 
				${props.classes.isIncomplete} 
				${props.classes.isFocused} 
				${props.classes.hasValue}
				${props.classes.isValid}
                ${props.classes.isInvalid}
                ${props.checked ? "is-checked" : ""}
			`}
        >
            <label htmlFor={props.accessibilityIds.input}>
                <ReactForm
                    subForm={true}
                    disableFormValidateOnChange={true}
                    className={`react-form__time-picker__select-row`}
                >
                    <ReactFormField
                        type="select"
                        label={props.label}
                        onChange={handleHourChange}
                        className="has-placeholder"
                        required={false}
                    >
                        <option>-</option>
                        {[...Array(12)].map((el, index) => (
                            <option key={index} value={index + 1}>
                                {index + 1}
                            </option>
                        ))}
                    </ReactFormField>

                    <div className={`react-form__time-picker__separator`}>
                        <span>:</span>
                    </div>

                    <ReactFormField
                        type="select"
                        onChange={handleMinutesChange}
                        required={false}
                    >
                        <option>-</option>
                        <option value="00">00</option>
                        <option value="15">15</option>
                        <option value="30">30</option>
                        <option value="45">45</option>
                    </ReactFormField>

                    <ReactFormField
                        type="select"
                        onChange={handleAmPmChange}
                        required={false}
                    >
                        <option>-</option>
                        <option value="am">AM</option>
                        <option value="pm">PM</option>
                    </ReactFormField>
                </ReactForm>
            </label>

            {/*errors*/}
            {hasErrors ? (
                <div className={`${componentClassname}__errors`}>
                    <div
                        className={`${componentClassname}__errors__item`}
                        id={props.accessibilityIds.error}
                    >
                        Error: {props.errors[0]}
                    </div>
                </div>
            ) : null}

            {/*info*/}
            {props.info ? (
                <div
                    className={`${componentClassname}__info`}
                    id={props.accessibilityIds.description}
                    dangerouslySetInnerHTML={{
                        __html: props.info,
                    }}
                ></div>
            ) : null}
        </div>
    );
};

export default TimePicker;
