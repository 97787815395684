// @flow

import { get } from "./helpers";

export const getTenants = async (
    apiEndpoint: string,
    query: string
): Promise<any> => {
    const url = apiEndpoint + `?searchText=${query}`;
    return get(url);
};

export const getEventsAndOffers = async (
    apiEndpoint: string,
    query: string
): Promise<any> => {
    const url = apiEndpoint + `?tenantGuid=${query}`;
    return get(url);
};

export default {
    getTenants,
    getEventsAndOffers,
};
