//@flow

class SearchPage {
    $el: HTMLElement;
    $highlightElements: any;
    searchTerm: string;
    $clearButton: any;
    $inputField: any;
    $liveSearchButton: any;

    constructor(el: HTMLElement, searchTerm: string = "") {
        this.$el = el;
        this.$highlightElements = this.$el.querySelectorAll("[data-highlight]");
        this.$clearButton = document.querySelector(
            ".search-header .live-search__button--clear"
        );
        this.$inputField = document.querySelector(
            ".search-header .live-search__input"
        );
        this.$liveSearchButton = document.querySelector(
            ".live-search-container__mobile-button"
        );
        this.searchTerm = searchTerm;
        // startup
        this.init();

        // events
        this.attachEvents();
    }

    init() {
        this.highlightWord();
        this.hideLiveSearchButton();
    }

    attachEvents() {
        this.$inputField.addEventListener("keyup", this.checkInput.bind(this));
    }

    hideLiveSearchButton() {
        if (this.$liveSearchButton) {
            this.$liveSearchButton.style.display = "none";
        }
    }

    checkInput() {
        this.searchTerm = this.$inputField.value;
        if (this.searchTerm === "") {
            this.$clearButton.classList.add("live-search__button--hidden");
        } else {
            this.$clearButton.classList.remove("live-search__button--hidden");
        }
    }

    highlightWord() {
        if (this.searchTerm) {
            this.$highlightElements.forEach(($element: HTMLElement): any => {
                let innerHTML = $element.innerHTML;
                const re = new RegExp(`(${this.searchTerm})`, "gi");
                $element.innerHTML = innerHTML.replace(
                    re,
                    `<strong>$1</strong>`
                );
            });
        }
    }
}

export default SearchPage;
