import pubsub from "./pubsub";

const LoadYoutubeIframeAPI = () => {
    if (window.YT) {
        return new Promise((resolve, reject) => {
            if (window.YT.loaded) {
                resolve();
            } else {
                pubsub.subscribe("youtubeiFrameAPILoaded", () => {
                    resolve();
                });
            }
        });
    } else {
        return new Promise((resolve, reject) => {
            window.YT = {};
            window.onYouTubeIframeAPIReady = () => {
                pubsub.publish("youtubeiFrameAPILoaded");
                resolve();
            };
            const tag = document.createElement("script");
            tag.src = "https://www.youtube.com/iframe_api";
            if (document.body) {
                document.body.appendChild(tag);
            }
        });
    }
};

export default LoadYoutubeIframeAPI;
