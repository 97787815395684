//@flow
import Lightbox from "@modules/Lightbox";
import LiveSearch from "@modules/LiveSearch";
import React from "react";
import ReactDOM from "react-dom";

class LiveSearchContainer {
    $el: HTMLElement;
    searchLightbox: any;
    $triggerTargets: any;
    $reactRoot: any;

    constructor(el: HTMLElement) {
        this.$el = el;
        this.$triggerTargets = document.querySelectorAll(
            `[data-live-search-trigger]`
        );
        this.$reactRoot = this.$el.querySelector(".live-search__react-root");
        // startup
        this.init();

        // events
        this.attachEvents();
    }

    init() {
        ReactDOM.render(
            <LiveSearch
                apiEndpoint={
                    this.$el.getAttribute("data-live-search-api-endpoint") || ""
                }
                targetPage={
                    this.$el.getAttribute("data-live-search-target-page") || ""
                }
            />,
            this.$reactRoot
        );
        //this.setupLightbox();
    }

    attachEvents() {
        this.$triggerTargets.forEach(($element: HTMLElement): any => {
            $element.addEventListener("click", (e: Event): any => {
                e.preventDefault();
                this.setupLightbox();
            });
        });
    }

    setupLightbox() {
        // create the ligthtbox
        this.searchLightbox = new Lightbox({
            contents: this.$el,
            additionalClass: "lightbox--live-search-container",
            onOpen: () => {
                const $inputField = document.querySelector(
                    ".lightbox .live-search__input"
                );
                if ($inputField) {
                    $inputField.focus();
                }
            },
        });
    }
}

export default LiveSearchContainer;
