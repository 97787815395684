// @flow

import { uniqueSvg } from "@utils/";

class InlineSVG {
    $el: HTMLElement;
    classList: ?string;
    src: ?string;
    svgMarkup: ?string;

    constructor(el: HTMLElement) {
        this.$el = el;
        this.src = this.$el.getAttribute("src");
        this.classList = this.$el.getAttribute("class");

        if (this.src) {
            this.getSvgMarkup(this.src);
        }
    }

    getSvgMarkup(src: string) {
        fetch(src)
            .then((response: any): any => {
                return response.text();
            })
            .then((response: any) => {
                this.svgMarkup = uniqueSvg(response);
                this.renderSVG();
            })
            .catch((err: any) => {
                console.log(err);
            });
    }

    createElementFromHTML(htmlString: string): any {
        var div = document.createElement("div");
        div.innerHTML = htmlString.trim();

        return div.querySelector("svg");
    }

    renderSVG() {
        if (this.$el && this.svgMarkup) {
            const $svg = this.createElementFromHTML(this.svgMarkup);

            $svg.setAttribute(
                "class",
                `${$svg.getAttribute("class") || ""} ${this.classList || ""}`
            );

            if (this.$el.parentNode) {
                this.$el.parentNode.insertBefore($svg, this.$el.nextSibling);
                this.$el.remove();
            }
        }
    }
}

export default InlineSVG;
